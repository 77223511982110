import React, { FC, RefObject, useEffect, useRef, useState } from 'react'
import {
	CheckboxList,
	RadioList,
	RequestFormPDFProps,
	RequestInputFiles,
	RequestInputs,
	UpdateRequestInputsArray,
	SelectList,
	Steps as DefaultSteps,
	StepsUrbanism
} from '@utils/request'
import requestForm from '@components/requestForms/request.form'
import { Collapse as ReactCollapse } from 'react-collapse'
import Collapse from '@components/ui/collapse'
import {
	REQUEST_CONFIGS_KEYS,
	REQUEST_TN_DETAILS_STEPS_NAME,
	Direction,
	requestCategories,
	documentStatusConfig,
	requestSubCategories
} from '@services/constants'
import * as pageUtils from './__forms.utils'
import { useAppSelector } from '@services/store'
import TextInput from './inputs/text.inputs'
import { joinClasses, makeClasses } from '@utils/styles'
import { Colors } from '@utils/css-variables'
import Breakpoints from '@utils/breakpoints'
import { AddressCoordinates, CustomDate } from '@services/models'
import { formatStrapiText, getFilesFromStore } from '@utils/methods'
import * as formUtils from '@pages/auth/__index.utils'
import {
	PHONE_NUMBER_REGEX,
	POSTAL_CODE_REGEX
} from '@pages/auth/profile/__index.utils'
import MaskedTextInput from '@components/requestForms/inputs/textWithValidation.inputs'
import Text from '@components/ui/text'
import RequestFiles from '@components/ui/requestFiles'
import DateFilterInput from './inputs/datefilter.input'
import RadioInput from './inputs/radio.input'
import AddressInfoSection from '@components/ui/addressInfosSection'
import Notification from '@components/ui/notification'
import TextAreaInput from './inputs/textarea.inputs'
import CheckboxInput from './inputs/checkbox.input'
import parse from 'html-react-parser'
import { formatHtmlForStrapiText } from '@utils/methods'
import SelectInput from './inputs/select.inputs'
import { getAddressByCoordinates } from '@components/ui/addressForm/__index.utils'
import AddressVoiePublicSection from '@components/ui/addressVoiePublicSection'
import MaskedThousandsSeparatorInput from './inputs/textWithThousandsSeparator.inputs'
import { formatNumberWithSpaces } from './__forms.utils'

const BuildingConstructionOrAdditionPermitForm: FC<RequestFormPDFProps> = ({
	inputs,
	errors,
	subSteps,
	inputFiles,
	isSummaryStep,
	steps,
	toPrint,
	filesInputs,
	formPosition,
	setDisableNextButton,
	onCustomError,
	onSetInputFiles,
	onSetSubSteps,
	onFixError,
	onSetInputs,
	setCurrentStep,
	onSetCurrentSubStep,
	setStepsList,
	setSteps
}) => {
	const {
		authUser,
		pageAssets,
		UpdateRequestInputsArray,
		UpdateRequestInputFilesArray,
		UpdateRequestInputsErrorsArray
	} = requestForm()

	type Classes = {
		button: string
		outlinedButton: string
		buttonContainer: string
		fileHelperSection: string
		fileContainer: string
		inputContainer: string
		checkbox2: string
		checkbox2Label: string
		checkbox2Container: string
		checkboxWrapper: string
		adresseBlock: string
		h5: string
		greyBackgroundBlock: string
		detailscontainer: string
		subContent: string
		reducedMarginBottom: string
		image: string
	}

	const classes: Classes = makeClasses({
		button: {
			display: 'inline-block',
			padding: '10px 20px',
			fontSize: '13px',
			fontWeight: '500',
			color: Colors.white,
			lineHeight: '1em',
			borderRadius: '23px',
			transition: 'all 0.35s ease',
			MsTransition: 'all 0.35s ease',
			background: Colors.secondary,
			border: 'none',
			cursor: 'pointer',
			'&:hover': {
				background: Colors.darkBlue2,
				borderColor: Colors.darkBlue2,
				color: Colors.white
			}
		},
		outlinedButton: {
			background: 'none',
			border: `1px solid ${Colors.secondary} !important`,
			color: Colors.secondary
		},

		buttonContainer: {
			display: 'flex',
			width: 'fit-content',
			columnGap: '4px'
		},
		fileHelperSection: {
			background: Colors.darkWhite,
			padding: '30px',
			marginBottom: '15px'
		},
		fileContainer: {
			'.blue-bullets-list': {
				ul: {
					padding: '0px 15px 0px 20px'
				}
			}
		},
		inputContainer: {
			marginBottom: '20px'
		},

		checkbox2: {
			width: 'fit-content',
			position: 'absolute',
			verticalAlign: 'baseline',
			clip: 'rect(1px, 1px, 1px, 1px)'
		},
		checkbox2Label: {
			fontSize: '16px',
			padding: '0',
			color: Colors.lightBlack,
			position: 'relative',
			paddingLeft: '27px',
			fontWeight: 'normal',
			cursor: 'pointer',
			lineHeight: '17px',
			':before': {
				content: "''",
				position: 'absolute',
				top: '0',
				left: '0',
				width: '16px',
				height: '16px',
				border: `1px solid ${Colors.lightGrey3}`,
				background: Colors.white,
				outline: 'none !important'
			},
			':after': {
				content: "''",
				position: 'absolute',
				top: '4px',
				left: '4px',
				width: '10px',
				height: '10px',
				background: Colors.secondary,
				outline: 'none',
				opacity: '0',
				transition: 'all .15s ease-out'
			}
		},
		checkbox2Container: {
			marginTop: '-15px',
			marginBottom: '15px',
			'& input[value=true] ~ label:after': {
				opacity: '.99'
			}
		},
		checkboxWrapper: {
			display: 'block',
			margin: '0 0 5px 0',
			background: Colors.ligthGrey2,
			border: `1px solid ${Colors.greyAlto}`,
			padding: '14px 12px'
		},
		adresseBlock: {
			border: `1px solid ${Colors.greyAlto}`,
			padding: '20px 25px',
			marginBottom: '10px',
			'& div': {
				alignItems: 'start !important'
			}
		},
		h5: {
			fontSize: '20px',
			fontWeight: '600',
			margin: '15px 0 10px 0'
		},
		greyBackgroundBlock: {
			background: Colors.darkWhite,
			padding: '15px 30px'
		},
		detailscontainer: {
			display: 'grid',
			alignItems: 'start',
			gridTemplateColumns: '50%43%',
			columnGap: '7%',
			[Breakpoints.maxWidth('lg')]: {
				gridTemplateColumns: '45%46%',
				columnGap: '12%'
			},
			[Breakpoints.maxWidth('md')]: {
				gridTemplateColumns: '100%',
				'& img': {
					marginTop: '15px !important',
					width: '80% !important'
				}
			},
			[Breakpoints.maxWidth('sm')]: {
				'& img': {
					width: '100% !important'
				}
			},
			lineHeight: '1.62em',
			// fontSize: '16px',
			color: Colors.lightBlack,
			'& img': {
				width: '100%',
				verticalAlign: 'middle',
				margin: 'auto'
			},
			'& ul': {
				paddingLeft: '18px',
				margin: 0,
				'& li::marker': {
					color: Colors.secondary
				}
			}
		},
		subContent: {
			display: 'grid',
			columnGap: '15px',
			rowGap: '10px',
			gridTemplateColumns: '2fr auto',
			alignItems: 'end',
			[Breakpoints.maxWidth('md')]: {
				gridTemplateColumns: '2fr auto'
			},
			[Breakpoints.maxWidth('sm')]: {
				gridTemplateColumns: '2fr auto'
			}
		},
		reducedMarginBottom: {
			marginBottom: '18px'
		},
		image: {
			[Breakpoints.maxWidth('md')]: {
				width: '35%'
			}
		}
	})

	const answers = useAppSelector((state) => state.request.configs)
	const location = useAppSelector((state) => state.request.location)

	const isNewConstruction = (value) =>
		value?.toString() ==
		REQUEST_CONFIGS_KEYS.whatToDoBuildingConstructionOrAdditionPermit
			.request_new_construction

	const isAddition = (value) =>
		value?.toString() ==
		REQUEST_CONFIGS_KEYS.whatToDoBuildingConstructionOrAdditionPermit
			.request_addition

	const [showAdresseBlock, setShowAdresseBlock] = useState<boolean>(
		answers?.buildingAddressSameHasApplicant?.toString() == 'false' ||
			isNewConstruction(answers?.whatToDo)
			? true
			: false
	)

	//----------------------------------------------------------------
	//--------------------- DETAILS 1 INPUTS -------------------------
	//----------------------------------------------------------------

	// ************* Type de demande ***********

	// Que souhaitez-vous faire?
	const [whatToDoInput, setWhatToDoInput] = useState<RequestInputs>({
		name: 'whatToDo',
		label: pageAssets?.request_form_what_would_you_like_to_do,
		labelKey: 'request_form_what_would_you_like_to_do',
		value: answers?.whatToDo?.toString() || '',
		required: true,
		ref: useRef<HTMLSelectElement>(null),
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_1
	})

	const radioListWhatToDo: RadioList[] = [
		{
			label:
				pageAssets?.request_form_buildingConstructionOrAdditionPermit_request_installation,
			value:
				REQUEST_CONFIGS_KEYS.whatToDoBuildingConstructionOrAdditionPermit
					.request_new_construction,
			key: 'request_form_buildingConstructionOrAdditionPermit_request_installation',
			extraText:
				pageAssets?.request_form_buildingConstructionOrAdditionPermit_request_installation_extra_text
		},
		{
			label:
				pageAssets?.request_form_buildingConstructionOrAdditionPermit_request_upgrading,
			value:
				REQUEST_CONFIGS_KEYS.whatToDoBuildingConstructionOrAdditionPermit
					.request_addition,
			key: 'request_form_buildingConstructionOrAdditionPermit_request_upgrading',
			extraText:
				pageAssets?.request_form_buildingConstructionOrAdditionPermit_request_addition_extra_text
		}
	]

	// Q2 Vous faites cette demande en tant que:
	const [requestAsInput, setRequestAsInput] = useState<RequestInputs>({
		name: 'requestAs',
		label: pageAssets?.request_form_request_as,
		labelKey: 'request_form_request_as',
		value: answers?.requestAs?.toString() || '',
		required: true,
		ref: useRef<HTMLSelectElement>(null),
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_1
	})

	const radioListRequestAs: RadioList[] = [
		{
			label: pageAssets?.request_form_individual,
			value: REQUEST_CONFIGS_KEYS.requestAs.individual,
			key: 'request_form_individual'
		},
		{
			label: pageAssets?.request_form_entreprise,
			value: REQUEST_CONFIGS_KEYS.requestAs.entreprise,
			key: 'request_form_entreprise'
		}, //Entrepreneur or manager
		{
			label: pageAssets?.request_form_entrepreneur_or_manager,
			value: REQUEST_CONFIGS_KEYS.requestAs.entrepreneur_or_manager,
			key: 'request_form_entrepreneur_or_manager'
		}
	]

	// ************* Informations relatives à l'entreprise ***********

	// Nom de l'entreprise
	const [companyNameInput, setCompanyNameInput] = useState<RequestInputs>({
		name: 'companyName',
		label:
			pageAssets?.request_form_annualRegistrationCertificateForContractors_companyName_label,
		labelKey:
			'request_form_annualRegistrationCertificateForContractors_companyName_label',
		value: answers.companyName || '',
		required:
			answers.requestAs?.toString() ==
			REQUEST_CONFIGS_KEYS.requestAs.entreprise,
		ref: useRef<HTMLSelectElement>(null),
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_1
	})

	// Nom et prénom du représentant
	const [representativeNameInput, setRepresentativeNameInput] =
		useState<RequestInputs>({
			name: 'representativeName',
			label:
				pageAssets?.request_form_swimmingPoolSpaPermit_representativeName_label,
			labelKey: 'request_form_swimmingPoolSpaPermit_representativeName_label',
			value: answers.representativeName || '',
			required:
				answers.requestAs?.toString() ==
				REQUEST_CONFIGS_KEYS.requestAs.entreprise,
			ref: useRef<HTMLSelectElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_1
		})

	const radioListYesNo: RadioList[] = [
		{
			label: pageAssets.request_form_select_yes,
			value: 'true',
			key: 'request_form_select_yes'
		},
		{
			label: pageAssets.request_form_select_no,
			value: 'false',
			key: 'request_form_select_no'
		}
	]

	// Plan d'implantation et d'intégration architecturale (PIIA)

	// Est-ce que les travaux sont assujettis à une approbation PIIA?
	// Is the work subject to PIIA approval?
	const [isSubjectToPiiaApprovalInput, setIsSubjectToPiiaApprovalInput] =
		useState<RequestInputs>({
			name: 'isSubjectToPiiaApproval',
			label:
				pageAssets?.request_form_buildingConstructionOrAdditionPermit_isSubjectToPiiaApproval_label,
			labelKey:
				'request_form_buildingConstructionOrAdditionPermit_isSubjectToPiiaApproval_label',
			description:
				pageAssets?.request_form_buildingConstructionOrAdditionPermit_isSubjectToPiiaApproval_description,
			value: answers.isSubjectToPiiaApproval?.toString() || '',
			required: true,
			ref: useRef<HTMLInputElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_1
		})

	// Est-ce que le projet a fait l'objet d'une approbation PIIA?
	// Has the project been subject to PIIA approval?
	const [hasBeenSubjectToApprovalInput, setHasBeenSubjectToApprovalInput] =
		useState<RequestInputs>({
			name: 'hasBeenSubjectToApproval',
			label:
				pageAssets?.request_form_buildingConstructionOrAdditionPermit_hasBeenSubjectToApproval_label,
			labelKey:
				'request_form_buildingConstructionOrAdditionPermit_hasBeenSubjectToApproval_label',
			value: answers.hasBeenSubjectToApproval?.toString() || '',
			required:
				answers.isSubjectToPiiaApproval?.toString() == radioListYesNo[0].value,
			ref: useRef<HTMLInputElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_1
		})

	// 	IA number (architectural integration)
	const [iaNumberInput, setIaNumberInput] = useState<RequestInputs>({
		name: 'iaNumber',
		label:
			pageAssets?.request_form_buildingConstructionOrAdditionPermit_iaNumber_label,
		labelKey:
			'request_form_buildingConstructionOrAdditionPermit_iaNumber_label',
		value: answers.iaNumber || '',
		required:
			answers.hasBeenSubjectToApproval?.toString() == radioListYesNo[0].value &&
			answers.isSubjectToPiiaApproval?.toString() == radioListYesNo[0].value,
		ref: useRef<HTMLInputElement>(null),
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_1
	})

	// Executive Committee (EC) resolution number
	const [ecResolutionNumberInput, setEcResolutionNumberInput] =
		useState<RequestInputs>({
			name: 'ecResolutionNumber',
			label:
				pageAssets?.request_form_buildingConstructionOrAdditionPermit_ecResolutionNumber_label,
			labelKey:
				'request_form_buildingConstructionOrAdditionPermit_ecResolutionNumber_label',
			value: answers.ecResolutionNumber || '',
			required: false,
			ref: useRef<HTMLInputElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_1
		})

	// Support for promoters

	// Is the project currently the subject of a request for a “Real estate project served by municipal infrastructure”?
	const [isRealEstateProjectInput, setIsRealEstateProjectInput] =
		useState<RequestInputs>({
			name: 'isRealEstateProject',
			label:
				pageAssets?.request_form_buildingConstructionOrAdditionPermit_isRealEstateProject_label,
			labelKey:
				'request_form_buildingConstructionOrAdditionPermit_isRealEstateProject_label',
			description:
				pageAssets?.request_form_buildingConstructionOrAdditionPermit_isRealEstateProject_description,
			value: answers.isRealEstateProject?.toString() || '',
			required: true,
			ref: useRef<HTMLInputElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_1
		})

	// Project number served (PD)
	const [projectNumberInput, setProjectNumberInput] = useState<RequestInputs>({
		name: 'projectNumber',
		label:
			pageAssets?.request_form_buildingConstructionOrAdditionPermit_projectNumber_label,
		labelKey:
			'request_form_buildingConstructionOrAdditionPermit_projectNumber_label',
		value: answers.projectNumber || '',
		required:
			answers.isRealEstateProject?.toString() == radioListYesNo[0].value,
		ref: useRef<HTMLInputElement>(null),
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_1
	})

	//----------------------------------------------------------------
	//-------------------- SubStep DETAILS_2 INPUTS ------------------
	//----------------------------------------------------------------

	const [isAddressOutsideLaval, setIsAddressOutsideLaval] =
		useState<boolean>(false)

	// hidden input cadastre
	const [buildingBatchNumberInput, setBuildingBatchNumberInput] =
		useState<RequestInputs>({
			name: 'buildingBatchNumber',
			label: '',
			labelKey: '',
			value: answers.buildingBatchNumber || '',
			required: false,
			ref: useRef<HTMLSelectElement>(null)
		})

	const [isSameLocationInput, setIsSameLocationInput] = useState<RequestInputs>(
		{
			name: 'buildingAddressSameHasApplicant',
			label:
				pageAssets?.request_form_buildingConstructionOrAdditionPermit_isSameLocation,
			labelKey:
				'request_form_buildingConstructionOrAdditionPermit_isSameLocation',
			value: answers?.buildingAddressSameHasApplicant?.toString() || '',
			required: isAddition(answers?.whatToDo),
			ref: useRef<HTMLSelectElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_2
		}
	)

	const radioListAddressOption: RadioList[] = [
		{
			label:
				pageAssets.request_form_addressInfoSection_addressOption_secondOption_label,
			value: REQUEST_CONFIGS_KEYS.researchType.cadastralNumber,
			key: 'request_form_addressInfoSection_addressOption_secondOption_label'
		},
		{
			label:
				pageAssets.request_form_addressInfoSection_addressOption_firstOption_label,
			value: REQUEST_CONFIGS_KEYS.researchType.address,
			key: 'request_form_addressInfoSection_addressOption_firstOption_label'
		}
	]

	const [isLocationOwnedInput, setIsLocationOwnedInput] =
		useState<RequestInputs>({
			name: 'isLocationOwned',
			label: pageAssets?.request_form_swimmingPoolSpaPermit_isLocationOwned,
			labelKey: 'request_form_swimmingPoolSpaPermit_isLocationOwned',
			value: answers?.isLocationOwned?.toString() || '',
			required: true,
			ref: useRef<HTMLSelectElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_2
		})

	//****** Owner inputs ******

	const [ownerFirstNameInput, setOwnerFirstNameInput] = useState<RequestInputs>(
		{
			name: 'ownerFirstName',
			label: pageAssets?.label_firstName,
			labelKey: 'label_firstName',
			value: answers.ownerFirstName || '',
			required: answers?.isLocationOwned?.toString() == radioListYesNo[1].value,
			ref: useRef<HTMLInputElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_2
		}
	)
	const [ownerLastNameInput, setOwnerLastNameInput] = useState<RequestInputs>({
		name: 'ownerLastName',
		label: pageAssets?.label_lastName,
		labelKey: 'label_lastName',
		value: answers.ownerLastName || '',
		required: answers?.isLocationOwned?.toString() == radioListYesNo[1].value,
		ref: useRef<HTMLInputElement>(null),
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_2
	})

	const [ownerEmailInput, setOwnerEmailInput] = useState<RequestInputs>({
		name: 'ownerEmail',
		label: pageAssets?.label_email,
		labelKey: 'label_email',
		value: answers.ownerEmail || '',
		required: answers?.isLocationOwned?.toString() == radioListYesNo[1].value,
		ref: useRef<HTMLInputElement>(null),
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_2
	})

	const [ownerPhoneNumberInput, setOwnerPhoneNumberInput] =
		useState<RequestInputs>({
			name: 'ownerPhoneNumber',
			label: pageAssets?.label_telephone,
			labelKey: 'label_telephone',
			value: answers.ownerPhoneNumber || '',
			required: answers?.isLocationOwned?.toString() == radioListYesNo[1].value,
			ref: useRef<HTMLInputElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_2
		})

	// Procuration du propriétaire
	// Owner's power of attorney
	const [firstQuestion, setFirstQuestion] = useState<RequestInputFiles>({
		name: 'firstInput',
		label: pageAssets?.request_form_ownerPowerOfAttorney,
		labelKey: 'request_form_ownerPowerOfAttorney',
		files: new Set<File>(
			filesInputs?.find((el) => el.name == 'firstInput')?.files || []
		),
		description: '',
		required: answers?.isLocationOwned?.toString() == radioListYesNo[1].value,
		numberMaxFiles: 1,
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DOCUMENTS,
		shouldValidateIn: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_2,
		category: requestCategories.proxy,
		subCategory: requestSubCategories.subCateg2
	})

	////////////////////////////////////////////////////////////////
	//----------------------------------------------------------------
	//------------------- SubStep DETAILS_3 INPUTS -------------------
	//---------------------------------------------------------------

	// Quel est l'usage principal du nouveau bâtiment?
	const [mainUseOfNewBuildingInput, setMainUseOfNewBuildingInput] =
		useState<RequestInputs>({
			name: 'mainUseOfBuilding',
			label:
				pageAssets?.request_form_buildingConstructionOrAdditionPermit_mainUseOfNewBuilding,
			labelKey:
				'request_form_buildingConstructionOrAdditionPermit_mainUseOfNewBuilding',
			value: answers?.mainUseOfBuilding?.toString() || '',
			required: isNewConstruction(answers?.whatToDo),
			ref: useRef<HTMLSelectElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_3
		})

	const radioListMainUseOfNewBuilding: RadioList[] =
		pageUtils.radioListMainUseOfNewBuilding(pageAssets)

	// Le nouveau bâtiment est-il...
	const [isTheNewBuildingInput, setIsTheNewBuildingInput] =
		useState<RequestInputs>({
			name: 'isTheNewBuilding',
			label:
				pageAssets?.request_form_buildingConstructionOrAdditionPermit_isTheNewBuilding,
			labelKey:
				'request_form_buildingConstructionOrAdditionPermit_isTheNewBuilding',
			value: answers?.isTheNewBuilding?.toString() || '',
			required: isNewConstruction(answers?.whatToDo),
			ref: useRef<HTMLSelectElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_3
		})

	const radioListIsTheNewBuilding: RadioList[] = [
		{
			label:
				pageAssets?.request_form_buildingConstructionOrAdditionPermit_isTheNewBuilding_option1,
			value: REQUEST_CONFIGS_KEYS.isTheNewBuilding.firstOption,
			key: 'request_form_buildingConstructionOrAdditionPermit_isTheNewBuilding_option1'
		},
		{
			label:
				pageAssets?.request_form_buildingConstructionOrAdditionPermit_isTheNewBuilding_option2,
			value: REQUEST_CONFIGS_KEYS.isTheNewBuilding.secondOption,
			key: 'request_form_buildingConstructionOrAdditionPermit_isTheNewBuilding_option2'
		},
		{
			label:
				pageAssets?.request_form_buildingConstructionOrAdditionPermit_isTheNewBuilding_option3,
			value: REQUEST_CONFIGS_KEYS.isTheNewBuilding.thirdOption,
			key: 'request_form_buildingConstructionOrAdditionPermit_isTheNewBuilding_option3'
		}
	]

	// Le nouveau bâtiment est-il ou fait-il partie d'une copropriété?
	const [
		isTheNewBuildingPartOfAPropertyInput,
		setIsTheNewBuildingPartOfAPropertyInput
	] = useState<RequestInputs>({
		name: 'isTheNewBuildingPartOfAProperty',
		label:
			pageAssets?.request_form_buildingConstructionOrAdditionPermit_isTheNewBuildingPartOfAProperty,
		labelKey:
			'request_form_buildingConstructionOrAdditionPermit_isTheNewBuildingPartOfAProperty',
		value: answers?.isTheNewBuildingPartOfAProperty?.toString() || '',
		required: isNewConstruction(answers?.whatToDo),
		ref: useRef<HTMLSelectElement>(null),
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_3
	})

	////////////////////////////////////////////////////////////////
	//----------------------------------------------------------------
	//------------------- SubStep DETAILS_4 INPUTS -------------------
	//---------------------------------------------------------------

	//-------------------- Informations sur le bâtiment INPUTS ------------------------

	// Quel est le type de structure?
	const [structureTypeInput, setStructureTypeInput] = useState<RequestInputs>({
		name: 'structureType',
		label:
			pageAssets?.request_form_buildingConstructionOrAdditionPermit_structureType,
		labelKey: 'request_form_buildingConstructionOrAdditionPermit_structureType',
		value: answers?.structureType?.toString() || '',
		required: isNewConstruction(answers?.whatToDo),
		ref: useRef<HTMLSelectElement>(null),
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_4
	})

	const radioListStructureType: RadioList[] =
		pageUtils.radioListStructureType(pageAssets)

	// shoud display questions
	const shouldDisplayQuestions = (usageType: string | undefined) => {
		switch (usageType?.toString()) {
			case REQUEST_CONFIGS_KEYS.mainUseOfNewBuilding.firstOption:
			case REQUEST_CONFIGS_KEYS.mainUseOfNewBuilding.secondOption:
			case REQUEST_CONFIGS_KEYS.mainUseOfNewBuilding.thirdOption:
				return {
					Q14: true,
					Q15: true,
					Q16_1: false,
					Q17: true,
					Q18: true
				}

			case REQUEST_CONFIGS_KEYS.mainUseOfNewBuilding.fourthOption:
				return {
					Q14: true,
					Q15: true,
					Q16_1: true,
					Q17: true,
					Q18: true
				}

			case REQUEST_CONFIGS_KEYS.mainUseOfNewBuilding.fifthOption:
			case REQUEST_CONFIGS_KEYS.mainUseOfNewBuilding.sixthOption:
			case REQUEST_CONFIGS_KEYS.mainUseOfNewBuilding.eighthOption:
				return {
					Q14: true,
					Q15: true,
					Q17: true,
					Q16_1: false,
					Q18: false
				}

			case REQUEST_CONFIGS_KEYS.mainUseOfNewBuilding.seventhOption:
				return {
					Q14: true,
					Q15: false,
					Q17: false,
					Q16_1: false,
					Q18: false
				}

			default:
				return {
					Q14: false,
					Q15: false,
					Q17: false,
					Q16_1: false,
					Q18: false
				}
		}
	}

	// Measurement unit m² or ft²
	const radioListMeasurement2Unit: RadioList[] = [
		{
			label: pageAssets?.request_form_meters2,
			value: REQUEST_CONFIGS_KEYS.measurement_unit.meters_unit,
			key: 'request_form_meters2'
		},
		{
			label: pageAssets?.request_form_foot2,
			value: REQUEST_CONFIGS_KEYS.measurement_unit.foot_unit,
			key: 'request_form_foot2'
		}
	]

	// Aire au sol du bâtiment (m2)
	const [Q14BuildingAreaInput, setQ14BuildingAreaInput] =
		useState<RequestInputs>({
			name: 'buildingArea',
			label:
				pageAssets?.request_form_buildingConstructionOrAdditionPermit_buildingArea,
			labelKey:
				'request_form_buildingConstructionOrAdditionPermit_buildingArea',
			value: answers?.buildingArea?.toString() || '',
			required:
				isNewConstruction(answers?.whatToDo) &&
				shouldDisplayQuestions(answers?.mainUseOfBuilding)?.Q14,
			ref: useRef<HTMLInputElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_4
		})

	const [Q14BuildingAreaUnitInput, setQ14BuildingAreaUnitInput] =
		useState<RequestInputs>({
			name: 'buildingAreaUnit',
			label:
				pageAssets?.request_form_buildingConstructionOrAdditionPermit_buildingAreaUnit,
			labelKey:
				'request_form_buildingConstructionOrAdditionPermit_buildingAreaUnit',
			value:
				answers?.buildingAreaUnit?.toString() ||
				REQUEST_CONFIGS_KEYS.measurement_unit.meters_unit,
			required: shouldDisplayQuestions(answers?.mainUseOfBuilding)?.Q14,
			ref: useRef<HTMLSelectElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_4
		})

	// Superficie brute totale des planchers (m2)
	const [Q15TotalGrossFloorAreaInput, setQ15TotalGrossFloorAreaInput] =
		useState<RequestInputs>({
			name: 'totalGrossFloorArea',
			label:
				pageAssets?.request_form_buildingConstructionOrAdditionPermit_totalGrossFloorArea,
			labelKey:
				'request_form_buildingConstructionOrAdditionPermit_totalGrossFloorArea',
			value: answers?.totalGrossFloorArea?.toString() || '',
			required:
				isNewConstruction(answers?.whatToDo) &&
				shouldDisplayQuestions(answers?.mainUseOfBuilding)?.Q15,
			ref: useRef<HTMLInputElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_4
		})

	const [Q15TotalGrossFloorAreaUnitInput, setQ15TotalGrossFloorAreaUnitInput] =
		useState<RequestInputs>({
			name: 'totalGrossFloorAreaUnit',
			label:
				pageAssets?.request_form_buildingConstructionOrAdditionPermit_totalGrossFloorAreaUnit,
			labelKey:
				'request_form_buildingConstructionOrAdditionPermit_totalGrossFloorAreaUnit',
			value:
				answers?.totalGrossFloorAreaUnit?.toString() ||
				REQUEST_CONFIGS_KEYS.measurement_unit.meters_unit,
			required:
				isNewConstruction(answers?.whatToDo) &&
				shouldDisplayQuestions(answers?.mainUseOfBuilding)?.Q15,
			ref: useRef<HTMLSelectElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_4
		})

	// Est-ce que l'ensemble de la superficie déclarée est destinée à l'habitation?
	const [
		Q16_1IsDeclaredAreaForResidentialUseInput,
		setQ16_1IsDeclaredAreaForResidentialUseInput
	] = useState<RequestInputs>({
		name: 'isDeclaredAreaForResidentialUse',
		label:
			pageAssets?.request_form_buildingConstructionOrAdditionPermit_isDeclaredAreaForResidentialUse,
		labelKey:
			'request_form_buildingConstructionOrAdditionPermit_isDeclaredAreaForResidentialUse',
		value: answers?.isDeclaredAreaForResidentialUse?.toString() || '',
		required:
			isNewConstruction(answers?.whatToDo) &&
			shouldDisplayQuestions(answers?.mainUseOfBuilding)?.Q16_1,
		ref: useRef<HTMLSelectElement>(null),
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_4
	})

	// Superficie des zones autres que résidentielles
	// Area of non-residential areas
	const [Q16_2NonResidentialAreasInput, setQ16_2NonResidentialAreasInput] =
		useState<RequestInputs>({
			name: 'nonResidentialAreas',
			label:
				pageAssets?.request_form_buildingConstructionOrAdditionPermit_nonResidentialAreas,
			labelKey:
				'request_form_buildingConstructionOrAdditionPermit_nonResidentialAreas',
			value: answers?.nonResidentialAreas?.toString() || '',
			required:
				isNewConstruction(answers?.whatToDo) &&
				shouldDisplayQuestions(answers?.mainUseOfBuilding)?.Q16_1 &&
				answers?.isDeclaredAreaForResidentialUse?.toString() ==
					radioListYesNo[1].value,
			ref: useRef<HTMLInputElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_4
		})

	const [
		Q16_2NonResidentialAreasUnitInput,
		setQ16_2NonResidentialAreasUnitInput
	] = useState<RequestInputs>({
		name: 'nonResidentialAreasUnit',
		label:
			pageAssets?.request_form_buildingConstructionOrAdditionPermit_nonResidentialAreasUnit,
		labelKey:
			'request_form_buildingConstructionOrAdditionPermit_nonResidentialAreasUnit',
		value:
			answers?.nonResidentialAreasUnit?.toString() ||
			REQUEST_CONFIGS_KEYS.measurement_unit.meters_unit,
		required:
			isNewConstruction(answers?.whatToDo) &&
			shouldDisplayQuestions(answers?.mainUseOfBuilding)?.Q16_1 &&
			answers?.isDeclaredAreaForResidentialUse?.toString() ==
				radioListYesNo[1].value,
		ref: useRef<HTMLSelectElement>(null),
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_4
	})

	// Nombre d'étages
	const [Q17NumberOfFloorsInput, setQ17NumberOfFloorsInput] =
		useState<RequestInputs>({
			name: 'numberOfFloors',
			label:
				pageAssets?.request_form_buildingConstructionOrAdditionPermit_numberOfFloors,
			labelKey:
				'request_form_buildingConstructionOrAdditionPermit_numberOfFloors',
			value: answers?.numberOfFloors?.toString() || '',
			required:
				isNewConstruction(answers?.whatToDo) &&
				shouldDisplayQuestions(answers?.mainUseOfBuilding)?.Q17,
			ref: useRef<HTMLInputElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_4
		})

	// Nombre de logements
	const [
		Q18NumberOfResidentialUnitsInput,
		setQ18NumberOfResidentialUnitsInput
	] = useState<RequestInputs>({
		name: 'numberOfResidentialUnits',
		label:
			pageAssets?.request_form_buildingConstructionOrAdditionPermit_numberOfResidentialUnits,
		labelKey:
			'request_form_buildingConstructionOrAdditionPermit_numberOfResidentialUnits',
		value: answers?.numberOfResidentialUnits?.toString() || '',
		required:
			isNewConstruction(answers?.whatToDo) &&
			shouldDisplayQuestions(answers?.mainUseOfBuilding)?.Q18,
		ref: useRef<HTMLInputElement>(null),
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_4
	})

	// ----------------------------------------------------------------

	// Le nouveau bâtiment comporte-t-il un garage?
	const [Q19HasGarageInput, setQ19HasGarageInput] = useState<RequestInputs>({
		name: 'hasGarage',
		label:
			pageAssets?.request_form_buildingConstructionOrAdditionPermit_hasGarage,
		labelKey: 'request_form_buildingConstructionOrAdditionPermit_hasGarage',
		value: answers?.hasGarage?.toString() || '',
		required: isNewConstruction(answers?.whatToDo),
		ref: useRef<HTMLSelectElement>(null),
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_4
	})

	// Le garage est-il situé...
	const [Q19_1GarageLocationInput, setQ19_1GarageLocationInput] =
		useState<RequestInputs>({
			name: 'garageLocation',
			label:
				pageAssets?.request_form_buildingConstructionOrAdditionPermit_garageLocation,
			labelKey:
				'request_form_buildingConstructionOrAdditionPermit_garageLocation',
			value: answers?.garageLocation?.toString() || '',
			required:
				isNewConstruction(answers?.whatToDo) &&
				Q19HasGarageInput.value == radioListYesNo[0].value,
			ref: useRef<HTMLSelectElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_4
		})

	// Garage location
	const radioListGarageLocation: RadioList[] = [
		{
			label:
				pageAssets?.request_form_buildingConstructionOrAdditionPermit_garageLocation_firstOption,
			value: REQUEST_CONFIGS_KEYS.garageLocation.firstOption,
			key: 'request_form_buildingConstructionOrAdditionPermit_garageLocation_firstOption'
		},
		{
			label:
				pageAssets?.request_form_buildingConstructionOrAdditionPermit_garageLocation_secondOption,
			value: REQUEST_CONFIGS_KEYS.garageLocation.secondOption,
			key: 'request_form_buildingConstructionOrAdditionPermit_garageLocation_secondOption'
		}
	]

	// Nombre de cases de stationnement extérieures à aménager
	const [Q20NbrOfParkingSpacesExtInput, setQ20NbrOfParkingSpacesExtInput] =
		useState<RequestInputs>({
			name: 'nbrOfParkingSpacesExt',
			label:
				pageAssets?.request_form_buildingConstructionOrAdditionPermit_numberOfParkingSpacesExt,

			labelKey:
				'request_form_buildingConstructionOrAdditionPermit_numberOfParkingSpacesExt',
			value: answers?.nbrOfParkingSpacesExt?.toString() || '',
			required: false,
			ref: useRef<HTMLInputElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_4
		})

	// Nombre de cases de stationnement intérieures à aménager
	const [Q21NbrOfParkingSpacesIntInput, setQ21NbrOfParkingSpacesIntInput] =
		useState<RequestInputs>({
			name: 'nbrOfParkingSpacesInt',
			label:
				pageAssets?.request_form_buildingConstructionOrAdditionPermit_numberOfParkingSpacesInt,
			labelKey:
				'request_form_buildingConstructionOrAdditionPermit_numberOfParkingSpacesInt',
			value: answers?.nbrOfParkingSpacesInt?.toString() || '',
			required: false,
			ref: useRef<HTMLInputElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_4
		})

	// Nombre de cases de stationnement pour personnes visiteuses à aménager
	const [Q22NbrOfParkingSpacesVisInput, setQ22NbrOfParkingSpacesVisInput] =
		useState<RequestInputs>({
			name: 'nbrOfParkingSpacesVis',
			label:
				pageAssets?.request_form_buildingConstructionOrAdditionPermit_numberOfParkingSpacesVis,
			labelKey:
				'request_form_buildingConstructionOrAdditionPermit_numberOfParkingSpacesVis',
			value: answers?.nbrOfParkingSpacesVis?.toString() || '',
			required: false,
			ref: useRef<HTMLInputElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_4
		})

	// Nombre d'unités pour vélos à aménager
	const [Q23NbrOfBikeSpacesInput, setQ23NbrOfBikeSpacesInput] =
		useState<RequestInputs>({
			name: 'nbrOfBikeSpaces',
			label:
				pageAssets?.request_form_buildingConstructionOrAdditionPermit_numberOfBikeSpaces,
			labelKey:
				'request_form_buildingConstructionOrAdditionPermit_numberOfBikeSpaces',
			value: answers?.nbrOfBikeSpaces?.toString() || '',
			required: false,
			ref: useRef<HTMLInputElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_4
		})

	// ----------------------------------------------------------------
	//-------------------- Informations sur les travaux INPUTS ------------------------

	// -------------------  DETAILS_5 -------------------

	// Effectuez-vous ces travaux à la suite d'une catastrophe?
	const [Q24IsWorkDueToDisasterInput, setQ24IsWorkDueToDisasterInput] =
		useState<RequestInputs>({
			name: 'isWorkDueToDisaster',
			label:
				pageAssets?.request_form_buildingConstructionOrAdditionPermit_isWorkDueToDisaster,
			labelKey:
				'request_form_buildingConstructionOrAdditionPermit_isWorkDueToDisaster',
			value: answers?.isWorkDueToDisaster?.toString() || '',
			required: true,
			ref: useRef<HTMLSelectElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_5
		})

	// Veuillez préciser WorkDueToDisasterPrecision
	const [
		Q24_1WorkDueToDisasterPrecisionInput,
		setQ24_1WorkDueToDisasterPrecisionInput
	] = useState<RequestInputs>({
		name: 'workDueToDisasterPrecision',
		label:
			pageAssets?.request_form_buildingConstructionOrAdditionPermit_workDueToDisasterPrecision,
		labelKey:
			'request_form_buildingConstructionOrAdditionPermit_workDueToDisasterPrecision',
		value: answers?.workDueToDisasterPrecision?.toString() || '',
		required: Q24IsWorkDueToDisasterInput.value == radioListYesNo[0].value,
		ref: useRef<HTMLSelectElement>(null),
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_5
	})

	// Coût approximatif des travaux (pour la construction du bâtiment)
	const [Q25ApproximateCostOfWorkInput, setQ25ApproximateCostOfWorkInput] =
		useState<RequestInputs>({
			name: 'approximateCostOfWork',
			label:
				pageAssets?.request_form_buildingConstructionOrAdditionPermit_approximateCostOfWork,
			labelKey:
				'request_form_buildingConstructionOrAdditionPermit_approximateCostOfWork',
			value: answers?.approximateCostOfWork?.toString() || '',
			required: true,
			ref: useRef<HTMLInputElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_5
		})

	// Valeur totale du projet (terrain, bâtiment et infrastructures)
	const [Q26TotalProjectValueInput, setQ26TotalProjectValueInput] =
		useState<RequestInputs>({
			name: 'totalProjectValue',
			label:
				pageAssets?.request_form_buildingConstructionOrAdditionPermit_totalProjectValue,
			labelKey:
				'request_form_buildingConstructionOrAdditionPermit_totalProjectValue',
			value: answers?.totalProjectValue?.toString() || '',
			required: isNewConstruction(answers?.whatToDo),
			ref: useRef<HTMLInputElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_5
		})

	// Est-ce qu'un abattage d'arbres est requis?
	const [Q27IsTreeCuttingRequiredInput, setQ27IsTreeCuttingRequiredInput] =
		useState<RequestInputs>({
			name: 'isTreeCuttingRequired',
			label:
				pageAssets?.request_form_buildingConstructionOrAdditionPermit_isTreeCuttingRequired,
			labelKey:
				'request_form_buildingConstructionOrAdditionPermit_isTreeCuttingRequired',
			description:
				pageAssets?.request_form_buildingConstructionOrAdditionPermit_isTreeCuttingRequired_description,
			value: answers?.isTreeCuttingRequired?.toString() || '',
			required: true,
			ref: useRef<HTMLSelectElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_5
		})

	// Renseignements supplémentaires
	const [Q28AdditionalInfoInput, setQ28AdditionalInfoInput] =
		useState<RequestInputs>({
			name: 'additionalInfoQ28',
			label:
				pageAssets?.request_form_buildingConstructionOrAdditionPermit_additionalInfoQ28,
			labelKey:
				'request_form_buildingConstructionOrAdditionPermit_additionalInfoQ28',
			description:
				pageAssets?.request_form_buildingConstructionOrAdditionPermit_additionalInfoQ28_description,
			value: answers?.additionalInfoQ28?.toString() || '',
			required: true,
			ref: useRef<HTMLTextAreaElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_5
		})

	// Date de début (projetée)
	const [Q29StartDateInput, setQ29StartDateInput] = useState<RequestInputs>({
		name: 'workStartDate',
		label:
			pageAssets?.request_form_buildingConstructionOrAdditionPermit_startDate,
		labelKey: 'request_form_buildingConstructionOrAdditionPermit_startDate',
		value: answers.workStartDate || '',
		required: false,
		ref: useRef<HTMLInputElement>(null),
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_5
	})

	// Date de fin (projetée)
	const [Q30EndDateInput, setQ30EndDateInput] = useState<RequestInputs>({
		name: 'workEndDate',
		label:
			pageAssets?.request_form_buildingConstructionOrAdditionPermit_endDate,
		labelKey: 'request_form_buildingConstructionOrAdditionPermit_endDate',
		value: answers.workEndDate || '',
		required: false,
		ref: useRef<HTMLInputElement>(null),
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_5
	})

	// Entrepreneur exécutant les travaux de construction.

	// Qui sera en charge des travaux?
	const [Q31WhoWillBeInChargeOfWorkInput, setQ31WhoWillBeInChargeOfWorkInput] =
		useState<RequestInputs>({
			name: 'whoWillBeInChargeOfWork',
			label:
				pageAssets?.request_form_buildingConstructionOrAdditionPermit_whoWillBeInChargeOfWork,
			labelKey:
				'request_form_buildingConstructionOrAdditionPermit_whoWillBeInChargeOfWork',
			value: answers?.whoWillBeInChargeOfWork?.toString() || '',
			required: true,
			ref: useRef<HTMLSelectElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_5
		})

	const radioListWhoWillBeInChargeOfWork: RadioList[] =
		pageUtils.radioListWhoWillBeInChargeOfWork(pageAssets)

	// Je ne connais pas encore l'entrepreneur
	const [Q31_1EntrepreneurUnknownInput, setQ31_1EntrepreneurUnknownInput] =
		useState<RequestInputs>({
			name: 'entrepreneurUnknown1',
			label:
				pageAssets?.request_form_buildingConstructionOrAdditionPermit_entrepreneurUnknown,
			labelKey:
				'request_form_buildingConstructionOrAdditionPermit_entrepreneurUnknown',
			value: answers.entrepreneurUnknown1?.toString() || String(false),
			required: true,
			ref: useRef<HTMLInputElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_6
		})

	// Nom de l'entreprise exécutant les travaux de construction.
	const [
		Q32CompanyNameExecutingWorkInput,
		setQ32CompanyNameExecutingWorkInput
	] = useState<RequestInputs>({
		name: 'companyNameExecutingWork',
		label:
			pageAssets?.request_form_buildingConstructionOrAdditionPermit_companyNameExecutingWork,
		labelKey:
			'request_form_buildingConstructionOrAdditionPermit_companyNameExecutingWork',
		value: answers.companyNameExecutingWork || '',
		required:
			answers?.whoWillBeInChargeOfWork ==
				radioListWhoWillBeInChargeOfWork[0].value ||
			answers.entrepreneurUnknown1 == true
				? false
				: true,
		ref: useRef<HTMLInputElement>(null),
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_5
	})

	// Numéro d'entreprise du Québec (NEQ)
	const [Q33CompanyNEQNumberInput, setQ33CompanyNEQNumberInput] =
		useState<RequestInputs>({
			name: 'companyNEQNumber',
			label:
				pageAssets?.request_form_buildingConstructionOrAdditionPermit_companyNEQNumber,
			labelKey:
				'request_form_buildingConstructionOrAdditionPermit_companyNEQNumber',
			value: answers.companyNEQNumber || '',
			required:
				answers?.whoWillBeInChargeOfWork ==
					radioListWhoWillBeInChargeOfWork[0].value ||
				answers.entrepreneurUnknown1 == true
					? false
					: true,
			ref: useRef<HTMLInputElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_5
		})

	// Numéro de licence RBQ
	const [Q34CompanyRBQNumberInput, setQ34CompanyRBQNumberInput] =
		useState<RequestInputs>({
			name: 'companyRBQNumber',
			label:
				pageAssets?.request_form_buildingConstructionOrAdditionPermit_companyRBQNumber,
			labelKey:
				'request_form_buildingConstructionOrAdditionPermit_companyRBQNumber',
			value: answers.companyRBQNumber || '',
			required:
				answers?.whoWillBeInChargeOfWork ==
					radioListWhoWillBeInChargeOfWork[0].value ||
				answers.entrepreneurUnknown1 == true
					? false
					: true,
			ref: useRef<HTMLInputElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_5
		})

	// Nom complet du (de la) représentant(e) ou de la personne contact
	const [
		Q35CompanyRepresentativeNameInput,
		setQ35CompanyRepresentativeNameInput
	] = useState<RequestInputs>({
		name: 'companyRepresentativeName',
		label:
			pageAssets?.request_form_buildingConstructionOrAdditionPermit_companyRepresentativeName,
		labelKey:
			'request_form_buildingConstructionOrAdditionPermit_companyRepresentativeName',
		value: answers.companyRepresentativeName || '',
		required:
			answers?.whoWillBeInChargeOfWork ==
				radioListWhoWillBeInChargeOfWork[0].value ||
			answers.entrepreneurUnknown1 == true
				? false
				: true,
		ref: useRef<HTMLInputElement>(null),
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_5
	})

	// Téléphone
	const [
		Q36CompanyRepresentativePhoneNumberInput,
		setQ36CompanyRepresentativePhoneNumberInput
	] = useState<RequestInputs>({
		name: 'companyRepresentativePhoneNumber',
		label: pageAssets?.label_telephone,
		labelKey: 'label_telephone',
		value: answers.companyRepresentativePhoneNumber || '',
		required:
			answers?.whoWillBeInChargeOfWork ==
				radioListWhoWillBeInChargeOfWork[0].value ||
			answers.entrepreneurUnknown1 == true
				? false
				: true,
		ref: useRef<HTMLInputElement>(null),
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_5
	})

	// Courriel
	const [
		Q37CompanyRepresentativeEmailInput,
		setQ37CompanyRepresentativeEmailInput
	] = useState<RequestInputs>({
		name: 'companyRepresentativeEmail',
		label: pageAssets?.label_email,
		labelKey: 'label_email',
		value: answers.companyRepresentativeEmail || '',
		required:
			answers?.whoWillBeInChargeOfWork ==
				radioListWhoWillBeInChargeOfWork[0].value ||
			answers.entrepreneurUnknown1 == true
				? false
				: true,
		ref: useRef<HTMLInputElement>(null),
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_5
	})

	// Adresse de l'entreprise
	const [Q38CompanyAddressInput, setQ38CompanyAddressInput] =
		useState<RequestInputs>({
			name: 'companyAddress',
			label: pageAssets?.label_address,
			labelKey: 'label_address',
			value: answers.companyAddress || '',
			required:
				answers?.whoWillBeInChargeOfWork ==
					radioListWhoWillBeInChargeOfWork[0].value ||
				answers.entrepreneurUnknown1 == true
					? false
					: true,
			ref: useRef<HTMLInputElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_5
		})

	// Ville
	const [Q39CompanyCityInput, setQ39CompanyCityInput] = useState<RequestInputs>(
		{
			name: 'companyCity',
			label: pageAssets?.label_city,
			labelKey: 'label_city',
			value: answers.companyCity || '',
			required:
				answers?.whoWillBeInChargeOfWork ==
					radioListWhoWillBeInChargeOfWork[0].value ||
				answers.entrepreneurUnknown1 == true
					? false
					: true,
			ref: useRef<HTMLInputElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_5
		}
	)

	// Code postal
	const [Q40CompanyPostalCodeInput, setQ40CompanyPostalCodeInput] =
		useState<RequestInputs>({
			name: 'companyPostalCode',
			label: pageAssets?.label_postalCode,
			labelKey: 'label_postalCode',
			value: answers.companyPostalCode || '',
			required:
				answers?.whoWillBeInChargeOfWork ==
					radioListWhoWillBeInChargeOfWork[0].value ||
				answers.entrepreneurUnknown1 == true
					? false
					: true,
			ref: useRef<HTMLInputElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_5
		})

	//----------------------------------------------------------------
	//----------------------- DETAILS_6 INPUTS -----------------------
	//----------------------------------------------------------------

	// Est-ce que des travaux de branchement d'aquéduc et d'égouts sont nécessaires à vos travaux ?
	const [
		Q41IsWaterAndSewerConnectionRequiredInput,
		setQ41IsWaterAndSewerConnectionRequiredInput
	] = useState<RequestInputs>({
		name: 'isWaterAndSewerConnectionRequired',
		label:
			pageAssets?.request_form_buildingConstructionOrAdditionPermit_isWaterAndSewerConnectionRequired,
		labelKey:
			'request_form_buildingConstructionOrAdditionPermit_isWaterAndSewerConnectionRequired',
		value: answers.isWaterAndSewerConnectionRequired?.toString() || '',
		required: true,
		ref: useRef<HTMLSelectElement>(null),
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_6
	})

	// Votre lot est-il branché aux conduites principales municipales suivantes?
	const [
		Q41IsPropertyConnectedToMunicipalConduitsInput,
		setQ41IsPropertyConnectedToMunicipalConduitsInput
	] = useState<RequestInputs>({
		name: 'isPropertyConnectedToMunicipalConduits',
		label:
			pageAssets?.request_form_buildingConstructionOrAdditionPermit_isPropertyConnectedToMunicipalConduits,
		labelKey:
			'request_form_buildingConstructionOrAdditionPermit_isPropertyConnectedToMunicipalConduits',
		value: answers.isPropertyConnectedToMunicipalConduits || '',
		required: false,
		ref: useRef<HTMLSelectElement>(null),
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_6
	})

	const radioListIsPropertyConnectedToMunicipalConduits: CheckboxList[] =
		pageUtils.radioListIsPropertyConnectedToMunicipalConduits(pageAssets)

	// Veuillez ajouter des précisions, si nécessaire
	const [Q42AdditionalInfoInput, setQ42AdditionalInfoInput] =
		useState<RequestInputs>({
			name: 'additionalInfoQ42',
			label:
				pageAssets?.request_form_buildingConstructionOrAdditionPermit_additionalInfoQ42,
			labelKey:
				'request_form_buildingConstructionOrAdditionPermit_additionalInfoQ42',
			value: answers.additionalInfoQ42 || '',
			required: false,
			ref: useRef<HTMLInputElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_6
		})

	// Dans le cadre de vos travaux, prévoyez vous
	// As part of your work, you plan to
	const [Q43PlannedWorkInput, setQ43PlannedWorkInput] = useState<RequestInputs>(
		{
			name: 'plannedWork',
			label:
				pageAssets?.request_form_buildingConstructionOrAdditionPermit_plannedWork,
			labelKey: 'request_form_buildingConstructionOrAdditionPermit_plannedWork',
			value: answers.plannedWork || '',
			required: false,
			ref: useRef<HTMLInputElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_6
		}
	)

	const listPlannedWork: CheckboxList[] = pageUtils.listPlannedWork(pageAssets)

	// Veuillez ajouter des précisions sur les travaux
	const [Q44AdditionalInfoInput, setQ44AdditionalInfoInput] =
		useState<RequestInputs>({
			name: 'additionalInfoQ44',
			label:
				pageAssets?.request_form_buildingConstructionOrAdditionPermit_additionalInfoQ44,
			labelKey:
				'request_form_buildingConstructionOrAdditionPermit_additionalInfoQ44',
			value: answers.additionalInfoQ44 || '',
			required: false,
			ref: useRef<HTMLInputElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_6
		})

	// Je ne connais pas encore l'entrepreneur
	const [Q45EntrepreneurUnknownInput, setQ45EntrepreneurUnknownInput] =
		useState<RequestInputs>({
			name: 'entrepreneurUnknown',
			label:
				pageAssets?.request_form_buildingConstructionOrAdditionPermit_entrepreneurUnknown,
			labelKey:
				'request_form_buildingConstructionOrAdditionPermit_entrepreneurUnknown',
			value: answers.entrepreneurUnknown?.toString() || String(false),
			required: true,
			ref: useRef<HTMLInputElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_6
		})

	const isEntrepreneurFieldsRequired = (value) => {
		return [
			listPlannedWork[0].value,
			listPlannedWork[1].value,
			listPlannedWork[2].value,
			listPlannedWork[5].value,
			listPlannedWork[6].value
		].some((option) => value?.toString()?.includes(option))
	}

	// Nom de l'entreprise
	const [Q46EntrepreneurNameInput, setQ46EntrepreneurNameInput] =
		useState<RequestInputs>({
			name: 'entrepreneurName',
			label:
				pageAssets?.request_form_buildingConstructionOrAdditionPermit_companyNameExecutingWork,
			labelKey:
				'request_form_buildingConstructionOrAdditionPermit_companyNameExecutingWork',
			value: answers.entrepreneurName || '',
			required:
				isEntrepreneurFieldsRequired(answers?.plannedWork) &&
				answers?.entrepreneurUnknown?.toString() == 'false',
			ref: useRef<HTMLInputElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_6
		})

	// Numéro de licence RBQ
	const [Q47EntrepreneurRBQNumberInput, setQ47EntrepreneurRBQNumberInput] =
		useState<RequestInputs>({
			name: 'entrepreneurRBQNumber',
			label:
				pageAssets?.request_form_buildingConstructionOrAdditionPermit_companyRBQNumber,
			labelKey:
				'request_form_buildingConstructionOrAdditionPermit_companyRBQNumber',
			value: answers.entrepreneurRBQNumber || '',
			required:
				isEntrepreneurFieldsRequired(answers?.plannedWork) &&
				answers?.entrepreneurUnknown?.toString() == 'false',
			ref: useRef<HTMLInputElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_6
		})

	// Nom complet du (de la) représentant(e) ou de la personne contact
	const [
		Q48EntrepreneurRepresentativeNameInput,
		setQ48EntrepreneurRepresentativeNameInput
	] = useState<RequestInputs>({
		name: 'entrepreneurRepresentativeName',
		label:
			pageAssets?.request_form_buildingConstructionOrAdditionPermit_companyRepresentativeName,
		labelKey:
			'request_form_buildingConstructionOrAdditionPermit_companyRepresentativeName',
		value: answers.entrepreneurRepresentativeName || '',
		required:
			isEntrepreneurFieldsRequired(answers?.plannedWork) &&
			answers?.entrepreneurUnknown?.toString() == 'false',
		ref: useRef<HTMLInputElement>(null),
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_6
	})

	// Téléphone
	const [
		Q49EntrepreneurRepresentativePhoneNumberInput,
		setQ49EntrepreneurRepresentativePhoneNumberInput
	] = useState<RequestInputs>({
		name: 'entrepreneurRepresentativePhoneNumber',
		label: pageAssets?.label_telephone,
		labelKey: 'label_telephone',
		value: answers.entrepreneurRepresentativePhoneNumber || '',
		required:
			isEntrepreneurFieldsRequired(answers?.plannedWork) &&
			answers?.entrepreneurUnknown?.toString() == 'false',
		ref: useRef<HTMLInputElement>(null),
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_6
	})

	// Courriel
	const [
		Q50EntrepreneurRepresentativeEmailInput,
		setQ50EntrepreneurRepresentativeEmailInput
	] = useState<RequestInputs>({
		name: 'entrepreneurRepresentativeEmail',
		label: pageAssets?.label_email,
		labelKey: 'label_email',
		value: answers.entrepreneurRepresentativeEmail || '',
		required:
			isEntrepreneurFieldsRequired(answers?.plannedWork) &&
			answers?.entrepreneurUnknown?.toString() == 'false',
		ref: useRef<HTMLInputElement>(null),
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_6
	})

	// Adresse
	const [
		Q51EntrepreneurRepresentativeAddressInput,
		setQ51EntrepreneurRepresentativeAddressInput
	] = useState<RequestInputs>({
		name: 'entrepreneurRepresentativeAddress',
		label: pageAssets?.label_address,
		labelKey: 'label_address',
		value: answers.entrepreneurRepresentativeAddress || '',
		required:
			isEntrepreneurFieldsRequired(answers?.plannedWork) &&
			answers?.entrepreneurUnknown?.toString() == 'false',
		ref: useRef<HTMLInputElement>(null),
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_6
	})

	// Ville
	const [
		Q52EntrepreneurRepresentativeCityInput,
		setQ52EntrepreneurRepresentativeCityInput
	] = useState<RequestInputs>({
		name: 'entrepreneurRepresentativeCity',
		label: pageAssets?.label_city,
		labelKey: 'label_city',
		value: answers.entrepreneurRepresentativeCity || '',
		required:
			isEntrepreneurFieldsRequired(answers?.plannedWork) &&
			answers?.entrepreneurUnknown?.toString() == 'false',
		ref: useRef<HTMLInputElement>(null),
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_6
	})

	// Code postal
	const [
		Q53EntrepreneurRepresentativePostalCodeInput,
		setQ53EntrepreneurRepresentativePostalCodeInput
	] = useState<RequestInputs>({
		name: 'entrepreneurRepresentativePostalCode',
		label: pageAssets?.label_postalCode,
		labelKey: 'label_postalCode',
		value: answers.entrepreneurRepresentativePostalCode || '',
		required:
			isEntrepreneurFieldsRequired(answers?.plannedWork) &&
			answers?.entrepreneurUnknown?.toString() == 'false',
		ref: useRef<HTMLInputElement>(null),
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_6
	})

	// ----------------------------------------------------------------
	//////////////////////////////////////////////////////////////
	// --------------------- PA PERMIT INPUTS -----------------------------
	////////////////////////////////////////////////////////////////////

	//  "Quel est l'usage du bâtiment principal existant?"
	const [
		Q10PAMainUseOfExistingBuildingInput,
		setQ10PAMainUseOfExistingBuildingInput
	] = useState<RequestInputs>({
		name: 'mainUseOfExistingBuilding',
		label:
			pageAssets?.request_form_buildingConstructionOrAdditionPermit_mainUseOfExistingBuilding,
		labelKey:
			'request_form_buildingConstructionOrAdditionPermit_mainUseOfExistingBuilding',
		value: answers?.mainUseOfExistingBuilding?.toString() || '',
		required: isAddition(answers?.whatToDo),
		ref: useRef<HTMLInputElement>(null),
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_3
	})

	const radioListMainUseOfExistingBuilding: RadioList[] =
		pageUtils.radioListMainUseOfExistingBuilding(pageAssets)

	// Le bâtiment principal existant comporte-t-il de l'habitation ?
	const [
		Q10_1PAExistingMainBuildingHasHousingInput,
		setQ10_1PAExistingMainBuildingHasHousingInput
	] = useState<RequestInputs>({
		name: 'existingMainBuildingHasHousing',
		label:
			pageAssets?.request_form_buildingConstructionOrAdditionPermit_existingMainBuildingHasHousing,
		labelKey:
			'request_form_buildingConstructionOrAdditionPermit_existingMainBuildingHasHousing',
		value: answers?.existingMainBuildingHasHousing?.toString() || '',
		required:
			isAddition(answers?.whatToDo) &&
			Q10PAMainUseOfExistingBuildingInput.value ==
				REQUEST_CONFIGS_KEYS.mainUseOfExistingBuilding.secondOption,
		ref: useRef<HTMLSelectElement>(null),
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_3
	})

	// Le nouveau bâtiment est-il ou fait-il partie d'une copropriété?
	const [
		Q11PAIsTheNewBuildingPartOfAPropertyInput,
		setQ11PAIsTheNewBuildingPartOfAPropertyInput
	] = useState<RequestInputs>({
		name: 'existingMainBuildingIsShared',
		label:
			pageAssets?.request_form_buildingConstructionOrAdditionPermit_isTheNewBuildingPartOfAProperty,
		labelKey:
			'request_form_buildingConstructionOrAdditionPermit_isTheNewBuildingPartOfAProperty',
		value: answers?.existingMainBuildingIsShared?.toString() || '',
		required: isAddition(answers?.whatToDo),
		ref: useRef<HTMLSelectElement>(null),
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_3
	})

	// ----------------------------------------------------------------

	// Libellé "Que souhaitez-vous construire ou aménager ? "
	const [Q12PAWhatToBuildOrImproveInput, setQ12PAWhatToBuildOrImproveInput] =
		useState<RequestInputs>({
			name: 'whatToBuildOrImprove',
			label:
				pageAssets?.request_form_buildingConstructionOrAdditionPermit_whatToBuildOrImprove,
			labelKey:
				'request_form_buildingConstructionOrAdditionPermit_whatToBuildOrImprove',
			value: answers?.whatToBuildOrImprove?.toString() || '',
			required: isAddition(answers?.whatToDo),
			ref: useRef<HTMLInputElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_4
		})

	const radioListWhatToBuildOrImprove: RadioList[] =
		pageUtils.radioListWhatToBuildOrImprove(pageAssets)

	const getOptionsWhatToBuildOrImprove = (Q10: string, Q10_1: string) => {
		return radioListWhatToBuildOrImprove.filter((option) =>
			pageUtils
				.getListWhatToBuildOrImproveByMainUseOfExistingBuilding(Q10, Q10_1)
				?.includes(option.value)
		)
	}

	// shoud display questions
	const isPAQuestionsRequired = (whatToBuildOrImprove: string | undefined) => {
		switch (whatToBuildOrImprove?.toString()) {
			case REQUEST_CONFIGS_KEYS.whatToBuildOrImprove.firstOption:
			case REQUEST_CONFIGS_KEYS.whatToBuildOrImprove.secondOption:
				return {
					Q13: true,
					Q14: false,
					Q15: true,
					Q16: true,
					Q17: true,
					Q18: true,
					Q19: true,
					Q20: true,
					Q21: true,
					Q22: true,
					Q23: true
				}

			case REQUEST_CONFIGS_KEYS.whatToBuildOrImprove.thirdOption:
				return {
					Q13: false,
					Q14: true,
					Q15: true,
					Q16: true,
					Q17: true,
					Q18: true,
					Q19: true,
					Q20: true,
					Q21: true,
					Q22: true,
					Q23: true
				}

			case REQUEST_CONFIGS_KEYS.whatToBuildOrImprove.fourthOption:
				return {
					Q13: false,
					Q14: false,
					Q15: false,
					Q16: true,
					Q17: true,
					Q18: true,
					Q19: true,
					Q20: true,
					Q21: false,
					Q22: false,
					Q23: false
				}
			// case REQUEST_CONFIGS_KEYS.whatToBuildOrImprove.fifthOption:
			case REQUEST_CONFIGS_KEYS.whatToBuildOrImprove.sixthOption:
				return {
					Q13: false,
					Q14: false,
					Q15: false,
					Q16: true,
					Q17: true,
					Q18: true,
					Q19: true,
					Q20: true,
					Q21: true,
					Q22: true,
					Q23: true
				}
			// case REQUEST_CONFIGS_KEYS.whatToBuildOrImprove.seventhOption:
		}
	}

	const shouldDisplayPAQuestion = (
		value: string | undefined,
		question: string
	) => {
		if (
			!value ||
			value == REQUEST_CONFIGS_KEYS.whatToBuildOrImprove.fifthOption
		) {
			return false
		}

		if (value == REQUEST_CONFIGS_KEYS.whatToBuildOrImprove.seventhOption) {
			return question !== 'Q13'
		}

		return isPAQuestionsRequired(value)?.[question]
	}

	const [Q13PAConstructionTypeInput, setQ13PAConstructionTypeInput] =
		useState<RequestInputs>({
			name: 'constructionType',
			label:
				pageAssets?.request_form_buildingConstructionOrAdditionPermit_constructionType,
			labelKey:
				'request_form_buildingConstructionOrAdditionPermit_constructionType',
			value: answers?.constructionType?.toString() || '',
			required:
				isAddition(answers?.whatToDo) &&
				isPAQuestionsRequired(answers?.whatToBuildOrImprove)?.Q13 == true,
			ref: useRef<HTMLInputElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_4
		})

	const radioListConstructionType: SelectList[] =
		pageUtils.radioListConstructionType(pageAssets)

	// Question 14
	// Libellé "Veuillez préciser le type de construction"
	const [Q14PAConstructionTypeOtherInput, setQ14PAConstructionTypeOtherInput] =
		useState<RequestInputs>({
			name: 'constructionTypeOther',
			label:
				pageAssets?.request_form_buildingConstructionOrAdditionPermit_constructionTypeOther,
			labelKey:
				'request_form_buildingConstructionOrAdditionPermit_constructionTypeOther',
			value: answers?.constructionTypeOther?.toString() || '',
			required:
				isAddition(answers?.whatToDo) &&
				isPAQuestionsRequired(answers?.whatToBuildOrImprove)?.Q14 == true,
			ref: useRef<HTMLInputElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_4
		})

	// 	Question 15
	// Libellé "Type de fondation"
	const [Q15PAFoundationTypeInput, setQ15PAFoundationTypeInput] =
		useState<RequestInputs>({
			name: 'foundationType',
			label:
				pageAssets?.request_form_buildingConstructionOrAdditionPermit_foundationType,
			labelKey:
				'request_form_buildingConstructionOrAdditionPermit_foundationType',
			value: answers?.foundationType?.toString() || '',
			required:
				isAddition(answers?.whatToDo) &&
				isPAQuestionsRequired(answers?.whatToBuildOrImprove)?.Q15 == true,
			ref: useRef<HTMLInputElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_4
		})

	const radioListFoundationType: SelectList[] =
		pageUtils.radioListFoundationType(pageAssets)

	// Question 16
	// Libellé "Emplacement sur le terrain"
	const [Q16PAPlaceOnTheTerrainInput, setQ16PAPlaceOnTheTerrainInput] =
		useState<RequestInputs>({
			name: 'placeOnTheTerrain',
			label:
				pageAssets?.request_form_buildingConstructionOrAdditionPermit_placeOnTheTerrain,
			labelKey:
				'request_form_buildingConstructionOrAdditionPermit_placeOnTheTerrain',
			value: answers?.placeOnTheTerrain?.toString() || '',
			required:
				isAddition(answers?.whatToDo) &&
				isPAQuestionsRequired(answers?.whatToBuildOrImprove)?.Q16 == true,
			ref: useRef<HTMLInputElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_4
		})

	const radioListPlaceOnTheTerrain: SelectList[] =
		pageUtils.radioListPlaceOnTheTerrain(pageAssets)

	// Measurement unit m or ft
	const radioListMeasurementUnit: RadioList[] = [
		{
			label: pageAssets?.request_form_meters,
			value: REQUEST_CONFIGS_KEYS.measurement_unit.meters_unit,
			key: 'request_form_meters'
		},
		{
			label: pageAssets?.request_form_foot,
			value: REQUEST_CONFIGS_KEYS.measurement_unit.foot_unit,
			key: 'request_form_foot'
		}
	]

	// 		Question 17
	// Libellé "Largeur
	const [Q17PABuildingWidthInput, setQ17PABuildingWidthInput] =
		useState<RequestInputs>({
			name: 'buildingWidth',
			label: pageAssets?.request_form_width,
			labelKey: 'request_form_width',
			value: answers.buildingWidth?.toString() || '',
			required:
				isAddition(answers?.whatToDo) &&
				isPAQuestionsRequired(answers?.whatToBuildOrImprove)?.Q17 == true,
			ref: useRef<HTMLInputElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_4
		})

	const [Q17PABuildingWidthUnitInput, setQ17PABuildingWidthUnitInput] =
		useState<RequestInputs>({
			name: 'buildingWidthUnit',
			label:
				pageAssets?.request_form_buildingConstructionOrAdditionPermit_buildingWidthUnit,
			labelKey:
				'request_form_buildingConstructionOrAdditionPermit_buildingWidthUnit',
			value:
				answers.buildingWidthUnit?.toString() ||
				REQUEST_CONFIGS_KEYS.measurement_unit.meters_unit,
			required:
				isAddition(answers?.whatToDo) &&
				isPAQuestionsRequired(answers?.whatToBuildOrImprove)?.Q17 == true,
			ref: useRef<HTMLSelectElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_4
		})

	// Question 18
	// Libellé "Longueur"

	const [Q18PABuildingLengthInput, setQ18PABuildingLengthInput] =
		useState<RequestInputs>({
			name: 'buildingLength',
			label: pageAssets?.request_form_length,
			labelKey: 'request_form_length',
			value: answers.buildingLength?.toString() || '',
			required:
				isAddition(answers?.whatToDo) &&
				isPAQuestionsRequired(answers?.whatToBuildOrImprove)?.Q18 == true,
			ref: useRef<HTMLInputElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_4
		})

	const [Q18PABuildingLengthUnitInput, setQ18PABuildingLengthUnitInput] =
		useState<RequestInputs>({
			name: 'buildingLengthUnit',
			label:
				pageAssets?.request_form_buildingConstructionOrAdditionPermit_buildingLengthUnit,
			labelKey:
				'request_form_buildingConstructionOrAdditionPermit_buildingLengthUnit',
			value:
				answers.buildingLengthUnit?.toString() ||
				REQUEST_CONFIGS_KEYS.measurement_unit.meters_unit,
			required:
				isAddition(answers?.whatToDo) &&
				isPAQuestionsRequired(answers?.whatToBuildOrImprove)?.Q18 == true,
			ref: useRef<HTMLSelectElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_4
		})

	// Question 19
	// Libellé "Hauteur"
	const [Q19PABuildingHeightInput, setQ19PABuildingHeightInput] =
		useState<RequestInputs>({
			name: 'buildingHeight',
			label: pageAssets?.request_form_height,
			labelKey: 'request_form_height',
			value: answers.buildingHeight?.toString() || '',
			required:
				isAddition(answers?.whatToDo) &&
				isPAQuestionsRequired(answers?.whatToBuildOrImprove)?.Q19 == true,
			ref: useRef<HTMLInputElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_4
		})

	// unit
	const [Q19PABuildingHeightUnitInput, setQ19PABuildingHeightUnitInput] =
		useState<RequestInputs>({
			name: 'buildingHeightUnit',
			label:
				pageAssets?.request_form_buildingConstructionOrAdditionPermit_buildingHeightUnit,
			labelKey:
				'request_form_buildingConstructionOrAdditionPermit_buildingHeightUnit',
			value:
				answers.buildingHeightUnit?.toString() ||
				REQUEST_CONFIGS_KEYS.measurement_unit.meters_unit,
			required:
				isAddition(answers?.whatToDo) &&
				isPAQuestionsRequired(answers?.whatToBuildOrImprove)?.Q19 == true,
			ref: useRef<HTMLSelectElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_4
		})

	// Question 20
	// Libellé "Superficie"
	const [Q20PABuildingSurfaceAreaInput, setQ20PABuildingSurfaceAreaInput] =
		useState<RequestInputs>({
			name: 'buildingSurfaceArea',
			label: pageAssets?.request_form_surfaceArea,
			labelKey: 'request_form_surfaceArea',
			value: answers.buildingSurfaceArea?.toString() || '',
			required:
				isAddition(answers?.whatToDo) &&
				isPAQuestionsRequired(answers?.whatToBuildOrImprove)?.Q20 == true,
			ref: useRef<HTMLInputElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_4
		})

	const [
		Q20PABuildingSurfaceAreaUnitInput,
		setQ20PABuildingSurfaceAreaUnitInput
	] = useState<RequestInputs>({
		name: 'buildingSurfaceAreaUnit',
		label:
			pageAssets?.request_form_buildingConstructionOrAdditionPermit_buildingSurfaceAreaUnit,
		labelKey:
			'request_form_buildingConstructionOrAdditionPermit_buildingSurfaceAreaUnit',
		value:
			answers.buildingSurfaceAreaUnit?.toString() ||
			REQUEST_CONFIGS_KEYS.measurement_unit.meters_unit,
		required:
			isAddition(answers?.whatToDo) &&
			isPAQuestionsRequired(answers?.whatToBuildOrImprove)?.Q20 == true,
		ref: useRef<HTMLSelectElement>(null),
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_4
	})

	// 	Question 21
	// Libellé "Matériaux pour le revêtement extérieur"
	const [
		Q21PABuildingExternalCoveringMaterialsInput,
		setQ21PABuildingExternalCoveringMaterialsInput
	] = useState<RequestInputs>({
		name: 'buildingExternalCoveringMaterials',
		label:
			pageAssets?.request_form_buildingConstructionOrAdditionPermit_buildingExternalCoveringMaterials,
		labelKey:
			'request_form_buildingConstructionOrAdditionPermit_buildingExternalCoveringMaterials',
		value: answers.buildingExternalCoveringMaterials || '',
		required:
			isAddition(answers?.whatToDo) &&
			isPAQuestionsRequired(answers?.whatToBuildOrImprove)?.Q21 == true,
		ref: useRef<HTMLInputElement>(null),
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_4
	})

	// Question 22
	// Libellé "Matériaux pour le toit "
	const [Q22PABuildingRoofMaterialsInput, setQ22PABuildingRoofMaterialsInput] =
		useState<RequestInputs>({
			name: 'buildingRoofMaterials',
			label:
				pageAssets?.request_form_buildingConstructionOrAdditionPermit_buildingRoofMaterials,
			labelKey:
				'request_form_buildingConstructionOrAdditionPermit_buildingRoofMaterials',
			value: answers.buildingRoofMaterials || '',
			required:
				isAddition(answers?.whatToDo) &&
				isPAQuestionsRequired(answers?.whatToBuildOrImprove)?.Q22 == true,
			ref: useRef<HTMLInputElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_4
		})

	// Question 23
	// Libellé "Matériaux pour la structure
	const [
		Q23PABuildingStructureMaterialsInput,
		setQ23PABuildingStructureMaterialsInput
	] = useState<RequestInputs>({
		name: 'buildingStructureMaterials',
		label:
			pageAssets?.request_form_buildingConstructionOrAdditionPermit_buildingStructureMaterials,
		labelKey:
			'request_form_buildingConstructionOrAdditionPermit_buildingStructureMaterials',
		value: answers.buildingStructureMaterials || '',
		required:
			isAddition(answers?.whatToDo) &&
			isPAQuestionsRequired(answers?.whatToBuildOrImprove)?.Q23 == true,
		ref: useRef<HTMLInputElement>(null),
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_4
	})

	// ----------------------------------------------------------

	const [dummyOptionalInput, setDummyOptionalInput] = useState<RequestInputs>({
		name: 'dummyOptional',
		label: '',
		labelKey: '',
		value: '',
		required: false,
		ref: useRef<HTMLSelectElement>(null),
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_6
	})
	//----------------------------------------------------------------
	//----------------------- DOCUMENTS INPUTS -----------------------
	//----------------------------------------------------------------

	// check if isIA
	// to use value from summary answers or from inputForm.value
	const isIACategPermitDocument = (isformInputs?: boolean) => {
		if (isformInputs == true) {
			return isSubjectToPiiaApprovalInput.value == 'true' &&
				hasBeenSubjectToApprovalInput.value == 'true'
				? 'isIA'
				: 'isNotIA'
		}

		return answers?.isSubjectToPiiaApproval?.toString() == 'true' &&
			answers?.hasBeenSubjectToApproval?.toString() == 'true'
			? 'isIA'
			: 'isNotIA'
	}

	const getDocumentCategory = (
		type: string,
		category: string,
		isformInputs?: boolean
	) => {
		if (category == '') {
			console.log('category is empty')

			return []
		}

		if (isIACategPermitDocument(isformInputs) == 'isIA') {
			return documentStatusConfig.buildingConstructionOrAdditionPermit[type][
				'isIA'
			]
		}

		return documentStatusConfig.buildingConstructionOrAdditionPermit[type][
			'isNotIA'
		][category]
	}

	const getDocumentStatus = (
		type: string,
		category: string | undefined,
		document: string,
		isformInputs?: boolean
	): string => {
		if (!category) {
			return 'not_required'
		}

		const categoryConfig = getDocumentCategory(type, category, isformInputs)

		if (categoryConfig) {
			return categoryConfig[document] || 'not_required'
		}
		return 'not_required'
	}

	const isDocumentToShow = (
		type: string,
		category: string | undefined,
		document: string,
		status: string,
		isformInputs?: boolean
	) => {
		return getDocumentStatus(type, category, document, isformInputs) == status
	}

	// Plan of implantation by a land surveyor
	const [secondQuestion, setSecondQuestion] = useState<RequestInputFiles>({
		name: 'secondInput',
		label:
			pageAssets?.request_form_buildingConstructionOrAdditionPermit_landSurveyorPlan,
		labelKey:
			'request_form_buildingConstructionOrAdditionPermit_landSurveyorPlan',
		description:
			pageAssets?.request_form_buildingConstructionOrAdditionPermit_landSurveyorPlan_description,
		files: new Set<File>(
			filesInputs?.find((el) => el.name == 'secondInput')?.files || []
		),
		required:
			getDocumentStatus('PN', answers?.mainUseOfBuilding, 'secondInput') ==
			'required',
		numberMaxFiles: 1,
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DOCUMENTS,
		shouldValidateIn: REQUEST_TN_DETAILS_STEPS_NAME.DOCUMENTS,
		category: requestCategories.other,
		subCategory: requestSubCategories.subCateg1
	})

	// Plan d'architecture
	const [thirdQuestion, setThirdQuestion] = useState<RequestInputFiles>({
		name: 'thirdInput',
		label:
			pageAssets?.request_form_buildingConstructionOrAdditionPermit_architecturePlan,
		labelKey:
			'request_form_buildingConstructionOrAdditionPermit_architecturePlan',
		description:
			pageAssets?.request_form_buildingConstructionOrAdditionPermit_architecturePlan_description,
		files: new Set<File>(
			filesInputs?.find((el) => el.name == 'thirdInput')?.files || []
		),
		required:
			getDocumentStatus('PN', answers?.mainUseOfBuilding, 'thirdInput') ==
			'required',
		numberMaxFiles: 1,
		category: requestCategories.other,
		subCategory: requestSubCategories.subCateg1,
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DOCUMENTS,
		shouldValidateIn: REQUEST_TN_DETAILS_STEPS_NAME.DOCUMENTS
	})

	// Plan d'aménagement
	const [fourthQuestion, setFourthQuestion] = useState<RequestInputFiles>({
		name: 'fourthInput',
		label:
			pageAssets?.request_form_buildingConstructionOrAdditionPermit_developmentPlan,
		labelKey:
			'request_form_buildingConstructionOrAdditionPermit_developmentPlan',
		description:
			pageAssets?.request_form_buildingConstructionOrAdditionPermit_developmentPlan_description,
		files: new Set<File>(
			filesInputs?.find((el) => el.name == 'fourthInput')?.files || []
		),
		required:
			getDocumentStatus('PN', answers?.mainUseOfBuilding, 'fourthInput') ==
			'required',
		numberMaxFiles: 1,
		category: requestCategories.other,
		subCategory: requestSubCategories.subCateg1,
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DOCUMENTS,
		shouldValidateIn: REQUEST_TN_DETAILS_STEPS_NAME.DOCUMENTS
	})

	// Plan mécanique
	const [fifthQuestion, setFifthQuestion] = useState<RequestInputFiles>({
		name: 'fifthInput',
		label:
			pageAssets?.request_form_buildingConstructionOrAdditionPermit_mechanicalPlan,
		labelKey:
			'request_form_buildingConstructionOrAdditionPermit_mechanicalPlan',
		description: '',
		files: new Set<File>(
			filesInputs?.find((el) => el.name == 'fifthInput')?.files || []
		),
		required:
			getDocumentStatus('PN', answers?.mainUseOfBuilding, 'fifthInput') ==
			'required',
		numberMaxFiles: 1,
		category: requestCategories.other,
		subCategory: requestSubCategories.subCateg1,
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DOCUMENTS,
		shouldValidateIn: REQUEST_TN_DETAILS_STEPS_NAME.DOCUMENTS
	})

	// Plan électrique
	const [sixthQuestion, setSixthQuestion] = useState<RequestInputFiles>({
		name: 'sixthInput',
		label:
			pageAssets?.request_form_buildingConstructionOrAdditionPermit_electricalPlan,
		labelKey:
			'request_form_buildingConstructionOrAdditionPermit_electricalPlan',
		description: '',
		files: new Set<File>(
			filesInputs?.find((el) => el.name == 'sixthInput')?.files || []
		),
		required:
			getDocumentStatus('PN', answers?.mainUseOfBuilding, 'sixthInput') ==
			'required',
		numberMaxFiles: 1,
		category: requestCategories.other,
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DOCUMENTS,
		shouldValidateIn: REQUEST_TN_DETAILS_STEPS_NAME.DOCUMENTS
	})

	// Plan de ventilation
	const [seventhQuestion, setSeventhQuestion] = useState<RequestInputFiles>({
		name: 'seventhInput',
		label:
			pageAssets?.request_form_buildingConstructionOrAdditionPermit_ventilationPlan,
		labelKey:
			'request_form_buildingConstructionOrAdditionPermit_ventilationPlan',
		description: '',
		files: new Set<File>(
			filesInputs?.find((el) => el.name == 'seventhInput')?.files || []
		),
		required:
			getDocumentStatus('PN', answers?.mainUseOfBuilding, 'seventhInput') ==
			'required',
		numberMaxFiles: 1,
		category: requestCategories.other,
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DOCUMENTS,
		shouldValidateIn: REQUEST_TN_DETAILS_STEPS_NAME.DOCUMENTS
	})

	// Plan civil
	const [eighthQuestion, setEighthQuestion] = useState<RequestInputFiles>({
		name: 'eighthInput',
		label:
			pageAssets?.request_form_buildingConstructionOrAdditionPermit_civilPlan,
		labelKey: 'request_form_buildingConstructionOrAdditionPermit_civilPlan',
		description: '',
		files: new Set<File>(
			filesInputs?.find((el) => el.name == 'eighthInput')?.files || []
		),
		required:
			getDocumentStatus('PN', answers?.mainUseOfBuilding, 'eighthInput') ==
			'required',
		numberMaxFiles: 1,
		category: requestCategories.other,
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DOCUMENTS,
		shouldValidateIn: REQUEST_TN_DETAILS_STEPS_NAME.DOCUMENTS
	})

	// Plan de structure
	const [ninthQuestion, setNinthQuestion] = useState<RequestInputFiles>({
		name: 'ninthInput',
		label:
			pageAssets?.request_form_buildingConstructionOrAdditionPermit_structuralPlan,
		labelKey:
			'request_form_buildingConstructionOrAdditionPermit_structuralPlan',
		description: '',
		files: new Set<File>(
			filesInputs?.find((el) => el.name == 'ninthInput')?.files || []
		),
		required:
			getDocumentStatus('PN', answers?.mainUseOfBuilding, 'ninthInput') ==
			'required',
		numberMaxFiles: 1,
		category: requestCategories.other,
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DOCUMENTS,
		shouldValidateIn: REQUEST_TN_DETAILS_STEPS_NAME.DOCUMENTS
	})

	// Fiche Bâtiment/Fiche Technique*
	const [tenthQuestion, setTenthQuestion] = useState<RequestInputFiles>({
		name: 'tenthInput',
		label:
			pageAssets?.request_form_buildingConstructionOrAdditionPermit_buildingSheet,
		labelKey: 'request_form_buildingConstructionOrAdditionPermit_buildingSheet',
		description: '',
		files: new Set<File>(
			filesInputs?.find((el) => el.name == 'tenthInput')?.files || []
		),
		required:
			getDocumentStatus('PN', answers?.mainUseOfBuilding, 'tenthInput') ==
			'required',
		numberMaxFiles: 1,
		category: requestCategories.other,
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DOCUMENTS,
		shouldValidateIn: REQUEST_TN_DETAILS_STEPS_NAME.DOCUMENTS
	})

	// Autres documents
	const [eleventhQuestion, setEleventhQuestion] = useState<RequestInputFiles>({
		name: 'eleventhInput',
		label:
			pageAssets?.request_form_buildingConstructionOrAdditionPermit_otherDocuments,
		labelKey:
			'request_form_buildingConstructionOrAdditionPermit_otherDocuments',
		description:
			pageAssets?.request_form_buildingConstructionOrAdditionPermit_otherDocuments_description,
		files: new Set<File>(
			filesInputs?.find((el) => el.name == 'eleventhInput')?.files || []
		),
		required:
			getDocumentStatus('PN', answers?.mainUseOfBuilding, 'eleventhInput') ==
			'required',
		numberMaxFiles: 10,
		category: requestCategories.other,
		subCategory: requestSubCategories.subCateg1,
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DOCUMENTS,
		shouldValidateIn: REQUEST_TN_DETAILS_STEPS_NAME.DOCUMENTS
	})

	//----------------------------------------------------------------
	// PA INPUTS
	//----------------------------------------------------------------
	// Plan d'implantation
	const [secondPAQuestion, setSecondPAQuestion] = useState<RequestInputFiles>({
		name: 'secondPAInput',
		label:
			pageAssets?.request_form_buildingConstructionOrAdditionPermit_implementationPlan,
		labelKey:
			'request_form_buildingConstructionOrAdditionPermit_implementationPlan',
		description:
			pageAssets?.request_form_buildingConstructionOrAdditionPermit_implementationPlan_description,
		files: new Set<File>(
			filesInputs?.find((el) => el.name == 'secondPAInput')?.files || []
		),
		required:
			getDocumentStatus('PA', answers?.whatToBuildOrImprove, 'secondPAInput') ==
			'required',
		numberMaxFiles: 1,
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DOCUMENTS,
		shouldValidateIn: REQUEST_TN_DETAILS_STEPS_NAME.DOCUMENTS,
		category: requestCategories.other
	})

	// Implementation plan
	const [thirdPAQuestion, setThirdPAQuestion] = useState<RequestInputFiles>({
		name: 'thirdPAInput',
		label:
			pageAssets?.request_form_buildingConstructionOrAdditionPermit_implementationPlan,
		labelKey:
			'request_form_buildingConstructionOrAdditionPermit_implementationPlan',
		description:
			pageAssets?.request_form_buildingConstructionOrAdditionPermit_implementationPlan2_description,
		files: new Set<File>(
			filesInputs?.find((el) => el.name == 'thirdPAInput')?.files || []
		),
		required:
			getDocumentStatus('PA', answers?.whatToBuildOrImprove, 'thirdPAInput') ==
			'required',
		numberMaxFiles: 1,
		category: requestCategories.other,
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DOCUMENTS,
		shouldValidateIn: REQUEST_TN_DETAILS_STEPS_NAME.DOCUMENTS
	})

	// Plan d'architecture
	const [fourthPAQuestion, setFourthPAQuestion] = useState<RequestInputFiles>({
		name: 'fourthPAInput',
		label:
			pageAssets?.request_form_buildingConstructionOrAdditionPermit_architecturePlan,
		labelKey:
			'request_form_buildingConstructionOrAdditionPermit_architecturePlan',
		description:
			pageAssets?.request_form_buildingConstructionOrAdditionPermit_architecturePlan_PA_description,
		files: new Set<File>(
			filesInputs?.find((el) => el.name == 'fourthPAInput')?.files || []
		),
		required:
			getDocumentStatus('PA', answers?.whatToBuildOrImprove, 'fourthPAInput') ==
			'required',
		numberMaxFiles: 1,
		category: requestCategories.other,
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DOCUMENTS,
		shouldValidateIn: REQUEST_TN_DETAILS_STEPS_NAME.DOCUMENTS
	})

	// Certificat de localisation
	const [fifthPAQuestion, setFifthPAQuestion] = useState<RequestInputFiles>({
		name: 'fifthPAInput',
		label:
			pageAssets?.request_form_buildingConstructionOrAdditionPermit_locationCertificate,
		labelKey:
			'request_form_buildingConstructionOrAdditionPermit_locationCertificate',
		description: '',
		files: new Set<File>(
			filesInputs?.find((el) => el.name == 'fifthPAInput')?.files || []
		),
		required:
			getDocumentStatus('PA', answers?.whatToBuildOrImprove, 'fifthPAInput') ==
			'required',
		numberMaxFiles: 1,
		category: requestCategories.other,
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DOCUMENTS,
		shouldValidateIn: REQUEST_TN_DETAILS_STEPS_NAME.DOCUMENTS
	})

	// Plan à l'échelle (par la personne requérante ou propriétaire)
	const [sixthPAQuestion, setSixthPAQuestion] = useState<RequestInputFiles>({
		name: 'sixthPAInput',
		label:
			pageAssets?.request_form_buildingConstructionOrAdditionPermit_scalePlan,
		labelKey: 'request_form_buildingConstructionOrAdditionPermit_scalePlan',
		description: '',
		files: new Set<File>(
			filesInputs?.find((el) => el.name == 'sixthPAInput')?.files || []
		),
		required:
			getDocumentStatus('PA', answers?.whatToBuildOrImprove, 'sixthPAInput') ==
			'required',
		numberMaxFiles: 1,
		category: requestCategories.other,
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DOCUMENTS,
		shouldValidateIn: REQUEST_TN_DETAILS_STEPS_NAME.DOCUMENTS
	})

	// Photographe
	const [seventhPAQuestion, setSeventhPAQuestion] = useState<RequestInputFiles>(
		{
			name: 'seventhPAInput',
			label:
				pageAssets?.request_form_buildingConstructionOrAdditionPermit_photographerPlan,
			labelKey:
				'request_form_buildingConstructionOrAdditionPermit_photographerPlan',
			description: '',
			files: new Set<File>(
				filesInputs?.find((el) => el.name == 'seventhPAInput')?.files || []
			),
			required:
				getDocumentStatus(
					'PA',
					answers?.whatToBuildOrImprove,
					'seventhPAInput'
				) == 'required',
			numberMaxFiles: 1,
			category: requestCategories.other,
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DOCUMENTS,
			shouldValidateIn: REQUEST_TN_DETAILS_STEPS_NAME.DOCUMENTS
		}
	)

	// Plan d'implantation fait par un arpenteur-géomètre pour une mouvelle tour de télécommunication
	const [eighthPAQuestion, setEighthPAQuestion] = useState<RequestInputFiles>({
		name: 'eighthPAInput',
		label:
			pageAssets?.request_form_buildingConstructionOrAdditionPermit_implantationPlanbyGeometer,
		labelKey:
			'request_form_buildingConstructionOrAdditionPermit_implantationPlanbyGeometer',
		description: '',
		files: new Set<File>(
			filesInputs?.find((el) => el.name == 'eighthPAInput')?.files || []
		),
		required:
			getDocumentStatus('PA', answers?.whatToBuildOrImprove, 'eighthPAInput') ==
			'required',
		numberMaxFiles: 1,
		category: requestCategories.other,
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DOCUMENTS,
		shouldValidateIn: REQUEST_TN_DETAILS_STEPS_NAME.DOCUMENTS
	})

	// Plan de structure
	const [ninthPAQuestion, setNinthPAQuestion] = useState<RequestInputFiles>({
		name: 'ninthPAInput',
		label:
			pageAssets?.request_form_buildingConstructionOrAdditionPermit_structuralPlan,
		labelKey:
			'request_form_buildingConstructionOrAdditionPermit_structuralPlan',
		description: '',
		files: new Set<File>(
			filesInputs?.find((el) => el.name == 'ninthPAInput')?.files || []
		),
		required:
			getDocumentStatus('PA', answers?.whatToBuildOrImprove, 'ninthPAInput') ==
			'required',
		numberMaxFiles: 1,
		category: requestCategories.other,
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DOCUMENTS,
		shouldValidateIn: REQUEST_TN_DETAILS_STEPS_NAME.DOCUMENTS
	})

	// Plan civil
	const [tenthPAQuestion, setTenthPAQuestion] = useState<RequestInputFiles>({
		name: 'tenthPAInput',
		label:
			pageAssets?.request_form_buildingConstructionOrAdditionPermit_civilPlan,
		labelKey: 'request_form_buildingConstructionOrAdditionPermit_civilPlan',
		description: '',
		files: new Set<File>(
			filesInputs?.find((el) => el.name == 'tenthPAInput')?.files || []
		),
		required:
			getDocumentStatus('PA', answers?.whatToBuildOrImprove, 'tenthPAInput') ==
			'required',
		numberMaxFiles: 1,
		category: requestCategories.other,
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DOCUMENTS,
		shouldValidateIn: REQUEST_TN_DETAILS_STEPS_NAME.DOCUMENTS
	})

	// Autres documents
	const [eleventhPAQuestion, setEleventhPAQuestion] =
		useState<RequestInputFiles>({
			name: 'eleventhPAInput',
			label:
				pageAssets?.request_form_buildingConstructionOrAdditionPermit_otherDocuments,
			labelKey:
				'request_form_buildingConstructionOrAdditionPermit_otherDocuments',
			description:
				pageAssets?.request_form_buildingConstructionOrAdditionPermit_otherDocuments_description,
			files: new Set<File>(
				filesInputs?.find((el) => el.name == 'eleventhPAInput')?.files || []
			),
			required:
				getDocumentStatus(
					'PA',
					answers?.whatToBuildOrImprove,
					'eleventhPAInput'
				) == 'required',
			numberMaxFiles: 10,
			category: requestCategories.other,
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DOCUMENTS,
			shouldValidateIn: REQUEST_TN_DETAILS_STEPS_NAME.DOCUMENTS
		})

	//Plan d'architecture préliminaire
	const [twelfthQuestion, setTwelfthQuestion] = useState<RequestInputFiles>({
		name: 'twelfthInput',
		label:
			pageAssets?.request_form_buildingConstructionOrAdditionPermit_preliminaryArchitecturePlan,
		labelKey:
			'request_form_buildingConstructionOrAdditionPermit_preliminaryArchitecturePlan',
		description:
			pageAssets?.request_form_buildingConstructionOrAdditionPermit_preliminaryArchitecturePlan_description,
		files: new Set<File>(
			filesInputs?.find((el) => el.name == 'twelfthInput')?.files || []
		),
		required:
			getDocumentStatus('PN', answers?.whatToBuildOrImprove, 'twelfthInput') ==
				'required' ||
			getDocumentStatus('PA', answers?.whatToBuildOrImprove, 'twelfthInput') ==
				'required',
		numberMaxFiles: 10,
		category: requestCategories.other,
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DOCUMENTS,
		shouldValidateIn: REQUEST_TN_DETAILS_STEPS_NAME.DOCUMENTS
	})

	// Étude acoustique
	const [thirteenthQuestion, setThirteenthQuestion] =
		useState<RequestInputFiles>({
			name: 'thirteenthInput',
			label:
				pageAssets?.request_form_buildingConstructionOrAdditionPermit_acousticStudy,
			labelKey:
				'request_form_buildingConstructionOrAdditionPermit_acousticStudy',
			description: '',
			files: new Set<File>(
				filesInputs?.find((el) => el.name == 'thirteenthInput')?.files || []
			),
			required:
				getDocumentStatus(
					'PN',
					answers?.whatToBuildOrImprove,
					'thirteenthInput'
				) == 'required' ||
				getDocumentStatus(
					'PA',
					answers?.whatToBuildOrImprove,
					'thirteenthInput'
				) == 'required',
			numberMaxFiles: 10,
			category: requestCategories.other,
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DOCUMENTS,
			shouldValidateIn: REQUEST_TN_DETAILS_STEPS_NAME.DOCUMENTS
		})

	// Plan d'aménagement paysager (préliminaire)
	const [fourteenthQuestion, setFourteenthQuestion] =
		useState<RequestInputFiles>({
			name: 'fourteenthInput',
			label:
				pageAssets?.request_form_buildingConstructionOrAdditionPermit_landscapePlan,
			labelKey:
				'request_form_buildingConstructionOrAdditionPermit_landscapePlan',
			description:
				pageAssets?.request_form_buildingConstructionOrAdditionPermit_landscapePlan_description,
			files: new Set<File>(
				filesInputs?.find((el) => el.name == 'fourteenthInput')?.files || []
			),
			required:
				getDocumentStatus(
					'PN',
					answers?.whatToBuildOrImprove,
					'fourteenthInput'
				) == 'required' ||
				getDocumentStatus(
					'PA',
					answers?.whatToBuildOrImprove,
					'fourteenthInput'
				) == 'required',
			numberMaxFiles: 10,
			category: requestCategories.other,
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DOCUMENTS,
			shouldValidateIn: REQUEST_TN_DETAILS_STEPS_NAME.DOCUMENTS
		})

	// ------------------- DETAILS INPUTS SETTERS --------------------

	// Reusable function to set input and handle errors
	const handleInputChange = (setInput, input, value, inputName) => {
		setInput({
			...input,
			value
		})

		onFixError(UpdateRequestInputsErrorsArray(errors!, inputName))
	}

	// Reusable hook to update inputs
	const useUpdateInputs = (input) => {
		useEffect(() => {
			onSetInputs(UpdateRequestInputsArray(inputs!, input))
		}, [input])
	}

	const updateInputsRequired = (inputs: any[], value: boolean) => {
		inputs.forEach((input) =>
			input.setState({ ...input.state, required: value })
		)
	}

	const setRequiredNewConstructionInputs = (value: boolean) => {
		updateInputsRequired(
			[
				{
					setState: setMainUseOfNewBuildingInput,
					state: mainUseOfNewBuildingInput
				},
				{ setState: setIsTheNewBuildingInput, state: isTheNewBuildingInput },
				{
					setState: setIsTheNewBuildingPartOfAPropertyInput,
					state: isTheNewBuildingPartOfAPropertyInput
				},
				{ setState: setStructureTypeInput, state: structureTypeInput },
				{ setState: setQ14BuildingAreaInput, state: Q14BuildingAreaInput },
				{
					setState: setQ15TotalGrossFloorAreaInput,
					state: Q15TotalGrossFloorAreaInput
				},
				{
					setState: setQ16_1IsDeclaredAreaForResidentialUseInput,
					state: Q16_1IsDeclaredAreaForResidentialUseInput
				},
				{
					setState: setQ16_2NonResidentialAreasInput,
					state: Q16_2NonResidentialAreasInput
				},
				{
					setState: setQ17NumberOfFloorsInput,
					state: Q17NumberOfFloorsInput
				},
				{
					setState: setQ18NumberOfResidentialUnitsInput,
					state: Q18NumberOfResidentialUnitsInput
				},
				{
					setState: setQ19HasGarageInput,
					state: Q19HasGarageInput
				},
				{
					setState: setQ19_1GarageLocationInput,
					state: Q19_1GarageLocationInput
				},
				{
					setState: setQ26TotalProjectValueInput,
					state: Q26TotalProjectValueInput
				}
			],
			value
		)
	}

	const setRequiredAdditionInputs = (value: boolean) => {
		updateInputsRequired(
			[
				{
					setState: setIsSameLocationInput,
					state: isSameLocationInput
				},
				{
					setState: setQ10PAMainUseOfExistingBuildingInput,
					state: Q10PAMainUseOfExistingBuildingInput
				},
				{
					setState: setQ11PAIsTheNewBuildingPartOfAPropertyInput,
					state: Q11PAIsTheNewBuildingPartOfAPropertyInput
				},
				{
					setState: setQ12PAWhatToBuildOrImproveInput,
					state: Q12PAWhatToBuildOrImproveInput
				},
				{
					setState: setQ13PAConstructionTypeInput,
					state: Q13PAConstructionTypeInput
				},
				{
					setState: setQ14PAConstructionTypeOtherInput,
					state: Q14PAConstructionTypeOtherInput
				},
				{
					setState: setQ15PAFoundationTypeInput,
					state: Q15PAFoundationTypeInput
				},
				{
					setState: setQ16PAPlaceOnTheTerrainInput,
					state: Q16PAPlaceOnTheTerrainInput
				},
				{
					setState: setQ17PABuildingWidthInput,
					state: Q17PABuildingWidthInput
				},
				{
					setState: setQ18PABuildingLengthInput,
					state: Q18PABuildingLengthInput
				},
				{
					setState: setQ19PABuildingHeightInput,
					state: Q19PABuildingHeightInput
				},
				{
					setState: setQ20PABuildingSurfaceAreaInput,
					state: Q20PABuildingSurfaceAreaInput
				},
				{
					setState: setQ21PABuildingExternalCoveringMaterialsInput,
					state: Q21PABuildingExternalCoveringMaterialsInput
				},
				{
					setState: setQ22PABuildingRoofMaterialsInput,
					state: Q22PABuildingRoofMaterialsInput
				},
				{
					setState: setQ23PABuildingStructureMaterialsInput,
					state: Q23PABuildingStructureMaterialsInput
				}
			],
			value
		)
	}

	const onSetWhatToDoInput = (value: string) => {
		setWhatToDoInput({ ...whatToDoInput, value })

		if (
			value ==
			REQUEST_CONFIGS_KEYS.whatToDoBuildingConstructionOrAdditionPermit
				.request_new_construction
		) {
			// ! new construction PN
			// ? required inputs
			setRequiredNewConstructionInputs(true)
			setRequiredAdditionInputs(false)

			// ? required documents
			updateRequiredDocumentsPA('')
			setTimeout(() => {
				updateRequiredDocuments(mainUseOfNewBuildingInput.value, true)
			}, 100)

			setQ10_1PAExistingMainBuildingHasHousingInput({
				...Q10_1PAExistingMainBuildingHasHousingInput,
				value: '',
				required: false
			})

			// ? show adresse block
			setIsSameLocationInput({
				...isSameLocationInput,
				value: '',
				required: false
			})
			setShowAdresseBlock(true)
		} else {
			// ! addition PA
			// ? required inputs
			setRequiredNewConstructionInputs(false)
			setRequiredAdditionInputs(true)

			setQ10_1PAExistingMainBuildingHasHousingInput({
				...Q10_1PAExistingMainBuildingHasHousingInput,
				value: '',
				required:
					Q10PAMainUseOfExistingBuildingInput.value ==
					REQUEST_CONFIGS_KEYS.mainUseOfExistingBuilding.secondOption
			})

			// ? required documents
			updateRequiredDocuments('')
			setTimeout(() => {
				updateRequiredDocumentsPA(Q12PAWhatToBuildOrImproveInput.value, true)
			}, 100)

			// ? show adresse block
			isSameLocationInput.value?.toString() != 'false' &&
				setShowAdresseBlock(false)
		}

		// ? required nbr of parking spaces
		updateNbrOfParkingSpacesInputsRequired(
			value,
			Q20NbrOfParkingSpacesExtInput.value,
			Q21NbrOfParkingSpacesIntInput.value,
			Q22NbrOfParkingSpacesVisInput.value,
			Q23NbrOfBikeSpacesInput.value
		)

		onFixError(
			errors.filter(
				(error) =>
					![
						whatToDoInput.name,
						isSameLocationInput.name,
						mainUseOfNewBuildingInput.name,
						isTheNewBuildingInput.name,
						structureTypeInput.name,
						Q10PAMainUseOfExistingBuildingInput.name,
						Q10_1PAExistingMainBuildingHasHousingInput.name,
						Q11PAIsTheNewBuildingPartOfAPropertyInput.name,
						Q12PAWhatToBuildOrImproveInput.name,
						Q13PAConstructionTypeInput.name,
						Q14PAConstructionTypeOtherInput.name,
						Q15PAFoundationTypeInput.name,
						Q16PAPlaceOnTheTerrainInput.name,
						Q17PABuildingWidthInput.name,
						Q18PABuildingLengthInput.name,
						Q19PABuildingHeightInput.name,
						Q20PABuildingSurfaceAreaInput.name
					].includes(error)
			)
		)
	}

	useUpdateInputs(whatToDoInput)
	//------------------------------------------------------------

	const onSetRequestAsInput = (value: string) => {
		setRequestAsInput({ ...requestAsInput, value })

		if (value == REQUEST_CONFIGS_KEYS.requestAs.entreprise) {
			setCompanyNameInput({ ...companyNameInput, required: true })
			setRepresentativeNameInput({ ...representativeNameInput, required: true })
		} else {
			setCompanyNameInput({ ...companyNameInput, required: false })
			setRepresentativeNameInput({
				...representativeNameInput,
				required: false
			})
		}

		if (
			value == REQUEST_CONFIGS_KEYS.requestAs.individual &&
			Q31WhoWillBeInChargeOfWorkInput.value ==
				REQUEST_CONFIGS_KEYS.whoWillBeInChargeOfWork.firstOption
		) {
			setQ31WhoWillBeInChargeOfWorkInput({
				...Q31WhoWillBeInChargeOfWorkInput,
				value: ''
			})
		}

		onFixError(
			errors.filter(
				(error) =>
					![
						requestAsInput.name,
						companyNameInput.name,
						representativeNameInput.name,
						Q32CompanyNameExecutingWorkInput.name,
						Q33CompanyNEQNumberInput.name,
						Q34CompanyRBQNumberInput.name,
						Q35CompanyRepresentativeNameInput.name,
						Q36CompanyRepresentativePhoneNumberInput.name,
						Q37CompanyRepresentativeEmailInput.name,
						Q38CompanyAddressInput.name,
						Q39CompanyCityInput.name,
						Q40CompanyPostalCodeInput.name
					].includes(error)
			)
		)
	}

	useUpdateInputs(requestAsInput)

	//------------------------------------------------------------

	const onSetCompanyNameInput = (value: string) => {
		handleInputChange(
			setCompanyNameInput,
			companyNameInput,
			value,
			companyNameInput.name
		)
	}

	useUpdateInputs(companyNameInput)

	//------------------------------------------------------------
	const onSetRepresentativeNameInput = (value: string) => {
		handleInputChange(
			setRepresentativeNameInput,
			representativeNameInput,
			value,
			representativeNameInput.name
		)
	}

	useUpdateInputs(representativeNameInput)

	//------------------------------------------------------------

	const onSetIsSubjectToPiiaApprovalInput = (value: string) => {
		setIsSubjectToPiiaApprovalInput({ ...isSubjectToPiiaApprovalInput, value })

		if (value == radioListYesNo[0].value) {
			setHasBeenSubjectToApprovalInput({
				...hasBeenSubjectToApprovalInput,
				required: true
			})
			setIaNumberInput({ ...iaNumberInput, required: true })
		} else {
			setHasBeenSubjectToApprovalInput({
				...hasBeenSubjectToApprovalInput,
				required: false
			})
			setIaNumberInput({ ...iaNumberInput, required: false })
		}

		onFixError(
			errors.filter(
				(error) =>
					![
						isSubjectToPiiaApprovalInput.name,
						hasBeenSubjectToApprovalInput.name,
						iaNumberInput.name
					].includes(error)
			)
		)
	}

	useUpdateInputs(isSubjectToPiiaApprovalInput)

	//------------------------------------------------------------

	const onSetHasBeenSubjectToApprovalInput = (value: string) => {
		setHasBeenSubjectToApprovalInput({
			...hasBeenSubjectToApprovalInput,
			value
		})

		if (value == radioListYesNo[0].value) {
			setIaNumberInput({ ...iaNumberInput, required: true })
		} else {
			setIaNumberInput({ ...iaNumberInput, required: false })
		}

		onFixError(
			errors.filter(
				(error) =>
					![hasBeenSubjectToApprovalInput.name, iaNumberInput.name].includes(
						error
					)
			)
		)
	}

	useUpdateInputs(hasBeenSubjectToApprovalInput)

	//------------------------------------------------------------

	useEffect(() => {
		if (
			whatToDoInput.value ==
				REQUEST_CONFIGS_KEYS.whatToDoBuildingConstructionOrAdditionPermit
					.request_new_construction &&
			isTheNewBuildingInput.value
		) {
			updateRequiredDocumentsPA('')
			setTimeout(() => {
				updateRequiredDocuments(mainUseOfNewBuildingInput.value, true)
			}, 100)
		}

		if (
			whatToDoInput.value ==
				REQUEST_CONFIGS_KEYS.whatToDoBuildingConstructionOrAdditionPermit
					.request_addition &&
			Q12PAWhatToBuildOrImproveInput.value
		) {
			updateRequiredDocuments('')
			setTimeout(() => {
				updateRequiredDocumentsPA(Q12PAWhatToBuildOrImproveInput.value, true)
			}, 100)
		}
	}, [isSubjectToPiiaApprovalInput, hasBeenSubjectToApprovalInput])

	//------------------------------------------------------------

	const onSetIaNumberInput = (value: string) => {
		handleInputChange(
			setIaNumberInput,
			iaNumberInput,
			value.toLocaleUpperCase(),
			iaNumberInput.name
		)
	}

	useUpdateInputs(iaNumberInput)

	//------------------------------------------------------------

	const onSetEcResolutionNumberInput = (value: string) => {
		handleInputChange(
			setEcResolutionNumberInput,
			ecResolutionNumberInput,
			value.toLocaleUpperCase(),
			ecResolutionNumberInput.name
		)
	}

	useUpdateInputs(ecResolutionNumberInput)

	//------------------------------------------------------------

	const onSetIsRealEstateProjectInput = (value: string) => {
		setIsRealEstateProjectInput({ ...isRealEstateProjectInput, value })

		if (value == radioListYesNo[0].value) {
			setProjectNumberInput({ ...projectNumberInput, required: true })
		} else {
			setProjectNumberInput({ ...projectNumberInput, required: false })
		}

		onFixError(
			errors.filter(
				(error) =>
					![isRealEstateProjectInput.name, projectNumberInput.name].includes(
						error
					)
			)
		)
	}

	useUpdateInputs(isRealEstateProjectInput)

	//------------------------------------------------------------

	const onSetProjectNumberInput = (value: string) => {
		handleInputChange(
			setProjectNumberInput,
			projectNumberInput,
			value.toLocaleUpperCase(),
			projectNumberInput.name
		)
	}

	useUpdateInputs(projectNumberInput)

	//------------------------------------------------------------

	const getCadastralNumber = (coordinates: AddressCoordinates) => {
		getAddressByCoordinates(coordinates).then((res) => {
			setBuildingBatchNumberInput({
				...buildingBatchNumberInput,
				value: res?.data?.features?.[0].properties.cadastre
			})
		})
	}

	useEffect(() => {
		const coordinates: AddressCoordinates = new AddressCoordinates()
		coordinates.update(authUser?.profile?.address.coordinates!)

		if (coordinates.validate()) {
			setIsAddressOutsideLaval(false)
			getCadastralNumber(coordinates)
		} else {
			setIsAddressOutsideLaval(true)
		}
	}, [])

	// const isValidAddress = () => {
	// 	const coordinates: AddressCoordinates = new AddressCoordinates()
	// 	coordinates.update(authUser?.profile?.address.coordinates!)

	// 	return coordinates.validate()
	// }

	const onSetIsSameLocationInput = (value: string) => {
		setIsSameLocationInput({ ...isSameLocationInput, value })

		setDisableNextButton && setDisableNextButton(true)

		if (value == radioListYesNo[0].value) {
			if (isAddressOutsideLaval) {
				setDisableNextButton && setDisableNextButton(false)
			}
			setTimeout(() => {
				setShowAdresseBlock(false)
			}, 100)
		} else {
			setTimeout(() => {
				setShowAdresseBlock(true)
			}, 100)
		}

		onFixError(
			UpdateRequestInputsErrorsArray(errors!, isSameLocationInput.name)
		)
	}

	useUpdateInputs(isSameLocationInput)

	useEffect(() => {
		if (
			subSteps?.position === REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_2 &&
			isAddressOutsideLaval &&
			isSameLocationInput.value == radioListYesNo[0].value
		) {
			setDisableNextButton && setDisableNextButton(false)
		}
	}, [subSteps?.position])
	//------------------------------------------------------------

	const setRequiredOwnerInfoInputs = (value: boolean) => {
		setOwnerFirstNameInput({ ...ownerFirstNameInput, required: value })
		setOwnerLastNameInput({ ...ownerLastNameInput, required: value })
		setOwnerEmailInput({ ...ownerEmailInput, required: value })
		setOwnerPhoneNumberInput({ ...ownerPhoneNumberInput, required: value })
		setFirstQuestion({ ...firstQuestion, required: value })
	}

	const onSetIsLocationOwnedInput = (value: string) => {
		setIsLocationOwnedInput({ ...isLocationOwnedInput, value })

		if (value == radioListYesNo[1].value) {
			setRequiredOwnerInfoInputs(true)
		} else {
			setRequiredOwnerInfoInputs(false)
		}

		onFixError(
			errors.filter(
				(error) =>
					![
						isLocationOwnedInput.name,
						ownerFirstNameInput.name,
						ownerLastNameInput.name,
						ownerEmailInput.name,
						ownerPhoneNumberInput.name,
						firstQuestion.name
					].includes(error)
			)
		)
	}

	useUpdateInputs(isLocationOwnedInput)
	//------------------------------------------------------------

	const onSetOwnerFirstNameInput = (value: string) => {
		handleInputChange(
			setOwnerFirstNameInput,
			ownerFirstNameInput,
			value,
			ownerFirstNameInput.name
		)
	}

	useUpdateInputs(ownerFirstNameInput)
	//------------------------------------------------------------

	const onSetOwnerLastNameInput = (value: string) => {
		handleInputChange(
			setOwnerLastNameInput,
			ownerLastNameInput,
			value,
			ownerLastNameInput.name
		)
	}

	useUpdateInputs(ownerLastNameInput)

	//------------------------------------------------------------

	const onSetOwnerEmailInput = (value: string) => {
		handleInputChange(
			setOwnerEmailInput,
			ownerEmailInput,
			value,
			ownerEmailInput.name
		)
	}

	useUpdateInputs(ownerEmailInput)
	//------------------------------------------------------------

	const onSetOwnerPhoneNumberInput = (value: string) => {
		handleInputChange(
			setOwnerPhoneNumberInput,
			ownerPhoneNumberInput,
			value,
			ownerPhoneNumberInput.name
		)
	}

	useUpdateInputs(ownerPhoneNumberInput)
	//------------------------------------------------------------

	// ----------------------------------------------------------------
	// -------------------------  Substep 3 ---------------------------
	// ----------------------------------------------------------------
	const updateRequiredDocuments = (
		mainUseOfNewBuilding: string | undefined,
		isformInputs?: boolean
	) => {
		const category = getDocumentCategory(
			'PN',
			mainUseOfNewBuilding ?? '',
			isformInputs
		)

		setSecondQuestion({
			...secondQuestion,
			required: category?.secondInput == 'required'
		})

		setThirdPAQuestion({
			...thirdPAQuestion,
			required: category?.thirdPAInput == 'required'
		})

		setTwelfthQuestion({
			...twelfthQuestion,
			required: category?.twelfthInput == 'required'
		})

		setThirteenthQuestion({
			...thirteenthQuestion,
			required: category?.thirteenthInput == 'required'
		})

		setFourteenthQuestion({
			...fourteenthQuestion,
			required: category?.fourteenthInput == 'required'
		})

		setThirdQuestion({
			...thirdQuestion,
			required: category?.thirdInput == 'required'
		})
		setFourthQuestion({
			...fourthQuestion,
			required: category?.fourthInput == 'required'
		})
		setFifthQuestion({
			...fifthQuestion,
			required: category?.fifthInput == 'required'
		})
		setSixthQuestion({
			...sixthQuestion,
			required: category?.sixthInput == 'required'
		})
		setSeventhQuestion({
			...seventhQuestion,
			required: category?.seventhInput == 'required'
		})
		setEighthQuestion({
			...eighthQuestion,
			required: category?.eighthInput == 'required'
		})
		setNinthQuestion({
			...ninthQuestion,
			required: category?.ninthInput == 'required'
		})
		setTenthQuestion({
			...tenthQuestion,
			required: category?.tenthInput == 'required'
		})
		setEleventhQuestion({
			...eleventhQuestion,
			required: category?.eleventhInput == 'required'
		})
	}

	const onSetMainUseOfNewBuildingInput = (value: string) => {
		setMainUseOfNewBuildingInput({
			...mainUseOfNewBuildingInput,
			value
		})

		var questionsStatus = shouldDisplayQuestions(value)

		setQ14BuildingAreaInput({
			...Q14BuildingAreaInput,
			required: questionsStatus.Q14
		})

		setQ15TotalGrossFloorAreaInput({
			...Q15TotalGrossFloorAreaInput,
			required: questionsStatus.Q15
		})

		setQ16_1IsDeclaredAreaForResidentialUseInput({
			...Q16_1IsDeclaredAreaForResidentialUseInput,
			required: questionsStatus.Q16_1
		})

		setQ16_2NonResidentialAreasInput({
			...Q16_2NonResidentialAreasInput,
			required:
				questionsStatus.Q16_1 &&
				Q16_1IsDeclaredAreaForResidentialUseInput.value ==
					radioListYesNo[1].value
		})

		setQ17NumberOfFloorsInput({
			...Q17NumberOfFloorsInput,
			required: questionsStatus.Q17
		})

		setQ18NumberOfResidentialUnitsInput({
			...Q18NumberOfResidentialUnitsInput,
			required: questionsStatus.Q18
		})

		// update required documents !!!
		updateRequiredDocuments(value, true)

		onFixError(
			errors.filter(
				(error) =>
					![
						mainUseOfNewBuildingInput.name,
						Q14BuildingAreaInput.name,
						Q15TotalGrossFloorAreaInput.name,
						Q16_1IsDeclaredAreaForResidentialUseInput.name,
						Q16_2NonResidentialAreasInput.name,
						Q17NumberOfFloorsInput.name,
						Q18NumberOfResidentialUnitsInput.name,
						secondQuestion.name,
						thirdQuestion.name,
						fourthQuestion.name,
						fifthQuestion.name,
						sixthQuestion.name,
						seventhQuestion.name,
						eighthQuestion.name,
						ninthQuestion.name,
						tenthQuestion.name,
						eleventhQuestion.name
					].includes(error)
			)
		)
	}

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs!, mainUseOfNewBuildingInput))
	}, [mainUseOfNewBuildingInput])
	//------------------------------------------------------------

	const onSetIsTheNewBuildingInput = (value: string) => {
		handleInputChange(
			setIsTheNewBuildingInput,
			isTheNewBuildingInput,
			value,
			isTheNewBuildingInput.name
		)
	}

	useUpdateInputs(isTheNewBuildingInput)

	//------------------------------------------------------------

	const onSetIsTheNewBuildingPartOfAPropertyInput = (value: string) => {
		handleInputChange(
			setIsTheNewBuildingPartOfAPropertyInput,
			isTheNewBuildingPartOfAPropertyInput,
			value,
			isTheNewBuildingPartOfAPropertyInput.name
		)
	}

	useUpdateInputs(isTheNewBuildingPartOfAPropertyInput)

	//------------------------------------------------------------

	////////////////////////////////////////////////////////////////

	//------------------------------------------------------------

	// ----------------------------------------------------------------
	// -------------------------  Substep 4 ---------------------------
	// ----------------------------------------------------------------

	const onSetStructureTypeInput = (value: string) => {
		handleInputChange(
			setStructureTypeInput,
			structureTypeInput,
			value,
			structureTypeInput.name
		)
	}

	useUpdateInputs(structureTypeInput)

	//------------------------------------------------------------

	const onSetQ14BuildingAreaInput = (value: string) => {
		handleInputChange(
			setQ14BuildingAreaInput,
			Q14BuildingAreaInput,
			value,
			Q14BuildingAreaInput.name
		)
	}

	useUpdateInputs(Q14BuildingAreaInput)

	//------------------------------------------------------------
	const onSetQ14BuildingAreaUnitInput = (value: string) => {
		handleInputChange(
			setQ14BuildingAreaUnitInput,
			Q14BuildingAreaUnitInput,
			value,
			Q14BuildingAreaUnitInput.name
		)
	}

	useUpdateInputs(Q14BuildingAreaUnitInput)

	//------------------------------------------------------------

	const onSetQ15TotalGrossFloorAreaInput = (value: string) => {
		handleInputChange(
			setQ15TotalGrossFloorAreaInput,
			Q15TotalGrossFloorAreaInput,
			value,
			Q15TotalGrossFloorAreaInput.name
		)
	}

	useUpdateInputs(Q15TotalGrossFloorAreaInput)

	//------------------------------------------------------------

	const onSetQ15TotalGrossFloorAreaUnitInput = (value: string) => {
		handleInputChange(
			setQ15TotalGrossFloorAreaUnitInput,
			Q15TotalGrossFloorAreaUnitInput,
			value,
			Q15TotalGrossFloorAreaUnitInput.name
		)
	}

	useUpdateInputs(Q15TotalGrossFloorAreaUnitInput)

	//------------------------------------------------------------

	const onSetQ16_1IsDeclaredAreaForResidentialUseInput = (value: string) => {
		setQ16_1IsDeclaredAreaForResidentialUseInput({
			...Q16_1IsDeclaredAreaForResidentialUseInput,
			value
		})

		if (value == radioListYesNo[1].value) {
			setQ16_2NonResidentialAreasInput({
				...Q16_2NonResidentialAreasInput,
				required: true
			})
		} else {
			setQ16_2NonResidentialAreasInput({
				...Q16_2NonResidentialAreasInput,
				required: false
			})
		}

		onFixError(
			UpdateRequestInputsErrorsArray(
				errors!,
				Q16_1IsDeclaredAreaForResidentialUseInput.name
			)
		)
	}

	useUpdateInputs(Q16_1IsDeclaredAreaForResidentialUseInput)

	//------------------------------------------------------------

	const onSetQ16_2NonResidentialAreasInput = (value: string) => {
		handleInputChange(
			setQ16_2NonResidentialAreasInput,
			Q16_2NonResidentialAreasInput,
			value,
			Q16_2NonResidentialAreasInput.name
		)
	}

	useUpdateInputs(Q16_2NonResidentialAreasInput)

	//------------------------------------------------------------

	const onSetQ16_2NonResidentialAreasUnitInput = (value: string) => {
		handleInputChange(
			setQ16_2NonResidentialAreasUnitInput,
			Q16_2NonResidentialAreasUnitInput,
			value,
			Q16_2NonResidentialAreasUnitInput.name
		)
	}

	useUpdateInputs(Q16_2NonResidentialAreasUnitInput)

	//------------------------------------------------------------

	const onSetQ17NumberOfFloorsInput = (value: string) => {
		if (value?.toString()?.replace(/[.,]/g, '')?.length > 3) {
			return
		}
		handleInputChange(
			setQ17NumberOfFloorsInput,
			Q17NumberOfFloorsInput,
			value,
			Q17NumberOfFloorsInput.name
		)
	}

	useUpdateInputs(Q17NumberOfFloorsInput)

	//------------------------------------------------------------

	const onSetQ18NumberOfResidentialUnitsInput = (value: string) => {
		if (value?.toString()?.replace(/[.,]/g, '')?.length > 5) {
			return
		}
		handleInputChange(
			setQ18NumberOfResidentialUnitsInput,
			Q18NumberOfResidentialUnitsInput,
			value,
			Q18NumberOfResidentialUnitsInput.name
		)
	}

	useUpdateInputs(Q18NumberOfResidentialUnitsInput)

	//------------------------------------------------------------

	const onSetQ19HasGarageInput = (value: string) => {
		setQ19HasGarageInput({
			...Q19HasGarageInput,
			value
		})

		if (value == radioListYesNo[0].value) {
			setQ19_1GarageLocationInput({
				...Q19_1GarageLocationInput,
				required: true
			})
		} else {
			setQ19_1GarageLocationInput({
				...Q19_1GarageLocationInput,
				required: false
			})
		}

		onFixError(
			errors.filter(
				(error) =>
					![Q19HasGarageInput.name, Q19_1GarageLocationInput.name].includes(
						error
					)
			)
		)
	}

	useUpdateInputs(Q19HasGarageInput)

	//------------------------------------------------------------

	const onSetQ19_1GarageLocationInput = (value: string) => {
		handleInputChange(
			setQ19_1GarageLocationInput,
			Q19_1GarageLocationInput,
			value,
			Q19_1GarageLocationInput.name
		)
	}

	useUpdateInputs(Q19_1GarageLocationInput)

	//------------------------------------------------------------

	const toggleNbrOfParkingSpacesInputsRequired = (value: boolean) => {
		setQ20NbrOfParkingSpacesExtInput({
			...Q20NbrOfParkingSpacesExtInput,
			required: value
		})
		setQ21NbrOfParkingSpacesIntInput({
			...Q21NbrOfParkingSpacesIntInput,
			required: value
		})
		setQ22NbrOfParkingSpacesVisInput({
			...Q22NbrOfParkingSpacesVisInput,
			required: value
		})
		setQ23NbrOfBikeSpacesInput({
			...Q23NbrOfBikeSpacesInput,
			required: value
		})
	}

	const updateNbrOfParkingSpacesInputsRequired = (
		whatToDoValue: string | undefined,
		Q20value?: string | number,
		Q21value?: string | number,
		Q22value?: string | number,
		Q23value?: string | number
	) => {
		if (isNewConstruction(whatToDoValue)) {
			if (
				Q20value != '' ||
				Q21value != '' ||
				Q22value != '' ||
				Q23value != ''
			) {
				if (Q20NbrOfParkingSpacesExtInput.required) {
					toggleNbrOfParkingSpacesInputsRequired(false)
				}
			} else {
				if (!Q20NbrOfParkingSpacesExtInput.required) {
					toggleNbrOfParkingSpacesInputsRequired(true)
				}
			}
		} else {
			if (Q20NbrOfParkingSpacesExtInput.required) {
				toggleNbrOfParkingSpacesInputsRequired(false)
			}
		}
	}

	useEffect(() => {
		updateNbrOfParkingSpacesInputsRequired(
			whatToDoInput.value,
			Q20NbrOfParkingSpacesExtInput.value,
			Q21NbrOfParkingSpacesIntInput.value,
			Q22NbrOfParkingSpacesVisInput.value,
			Q23NbrOfBikeSpacesInput.value
		)

		onFixError(
			errors.filter(
				(error) =>
					![
						Q20NbrOfParkingSpacesExtInput.name,
						Q21NbrOfParkingSpacesIntInput.name,
						Q22NbrOfParkingSpacesVisInput.name,
						Q23NbrOfBikeSpacesInput.name
					].includes(error)
			)
		)
	}, [
		Q20NbrOfParkingSpacesExtInput,
		Q21NbrOfParkingSpacesIntInput,
		Q22NbrOfParkingSpacesVisInput,
		Q23NbrOfBikeSpacesInput
	])

	const onSetQ20NbrOfParkingSpacesExtInput = (value: string) => {
		if (value?.toString()?.replace(/[.,]/g, '')?.length > 4) {
			return
		}
		handleInputChange(
			setQ20NbrOfParkingSpacesExtInput,
			Q20NbrOfParkingSpacesExtInput,
			value,
			Q20NbrOfParkingSpacesExtInput.name
		)
	}

	useUpdateInputs(Q20NbrOfParkingSpacesExtInput)

	//------------------------------------------------------------

	const onSetQ21NbrOfParkingSpacesIntInput = (value: string) => {
		if (value?.toString()?.replace(/[.,]/g, '')?.length > 4) {
			return
		}
		handleInputChange(
			setQ21NbrOfParkingSpacesIntInput,
			Q21NbrOfParkingSpacesIntInput,
			value,
			Q21NbrOfParkingSpacesIntInput.name
		)
	}

	useUpdateInputs(Q21NbrOfParkingSpacesIntInput)

	//------------------------------------------------------------

	const onSetQ22NbrOfParkingSpacesVisInput = (value: string) => {
		if (value?.toString()?.replace(/[.,]/g, '')?.length > 4) {
			return
		}
		handleInputChange(
			setQ22NbrOfParkingSpacesVisInput,
			Q22NbrOfParkingSpacesVisInput,
			value,
			Q22NbrOfParkingSpacesVisInput.name
		)
	}

	useUpdateInputs(Q22NbrOfParkingSpacesVisInput)

	//------------------------------------------------------------

	const onSetQ23NbrOfBikeSpacesInput = (value: string) => {
		if (value?.toString()?.replace(/[.,]/g, '')?.length > 4) {
			return
		}
		handleInputChange(
			setQ23NbrOfBikeSpacesInput,
			Q23NbrOfBikeSpacesInput,
			value,
			Q23NbrOfBikeSpacesInput.name
		)
	}

	useUpdateInputs(Q23NbrOfBikeSpacesInput)

	//------------------------------------------------------------

	const onSetQ24IsWorkDueToDisasterInput = (value: string) => {
		setQ24IsWorkDueToDisasterInput({
			...Q24IsWorkDueToDisasterInput,
			value
		})

		if (value == radioListYesNo[0].value) {
			setQ24_1WorkDueToDisasterPrecisionInput({
				...Q24_1WorkDueToDisasterPrecisionInput,
				required: true
			})
		} else {
			setQ24_1WorkDueToDisasterPrecisionInput({
				...Q24_1WorkDueToDisasterPrecisionInput,
				required: false,
				value: ''
			})
		}

		onFixError(
			errors.filter(
				(error) =>
					![
						Q24IsWorkDueToDisasterInput.name,
						Q24_1WorkDueToDisasterPrecisionInput.name
					].includes(error)
			)
		)
	}

	useUpdateInputs(Q24IsWorkDueToDisasterInput)

	//------------------------------------------------------------

	const onSetQ24_1WorkDueToDisasterPrecisionInput = (value: string) => {
		handleInputChange(
			setQ24_1WorkDueToDisasterPrecisionInput,
			Q24_1WorkDueToDisasterPrecisionInput,
			value,
			Q24_1WorkDueToDisasterPrecisionInput.name
		)
	}

	useUpdateInputs(Q24_1WorkDueToDisasterPrecisionInput)

	//------------------------------------------------------------

	const onSetQ25ApproximateCostOfWorkInput = (value) => {
		console.log('onSetQ25ApproximateCostOfWorkInput value', value)

		// const rawValue = value?.toString().replace(/ /g, '')
		handleInputChange(
			setQ25ApproximateCostOfWorkInput,
			Q25ApproximateCostOfWorkInput,
			value,
			Q25ApproximateCostOfWorkInput.name
		)
	}

	useUpdateInputs(Q25ApproximateCostOfWorkInput)

	//------------------------------------------------------------

	const onSetQ26TotalProjectValueInput = (value: string) => {
		console.log('onSetQ26TotalProjectValueInput value', value)
		handleInputChange(
			setQ26TotalProjectValueInput,
			Q26TotalProjectValueInput,
			value,
			Q26TotalProjectValueInput.name
		)
	}

	useUpdateInputs(Q26TotalProjectValueInput)

	//------------------------------------------------------------

	const onSetQ27IsTreeCuttingRequiredInput = (value: string) => {
		handleInputChange(
			setQ27IsTreeCuttingRequiredInput,
			Q27IsTreeCuttingRequiredInput,
			value,
			Q27IsTreeCuttingRequiredInput.name
		)
	}

	useUpdateInputs(Q27IsTreeCuttingRequiredInput)

	//------------------------------------------------------------

	const onSetQ28AdditionalInfoInput = (value: string) => {
		handleInputChange(
			setQ28AdditionalInfoInput,
			Q28AdditionalInfoInput,
			value,
			Q28AdditionalInfoInput.name
		)
	}

	useUpdateInputs(Q28AdditionalInfoInput)

	//------------------------------------------------------------

	const onSetQ29StartDateInput = (value: string) => {
		handleInputChange(
			setQ29StartDateInput,
			Q29StartDateInput,
			value,
			Q29StartDateInput.name
		)
	}

	useUpdateInputs(Q29StartDateInput)

	//------------------------------------------------------------

	const onSetQ30EndDateInput = (value: string) => {
		handleInputChange(
			setQ30EndDateInput,
			Q30EndDateInput,
			value,
			Q30EndDateInput.name
		)
	}

	useUpdateInputs(Q30EndDateInput)

	//------------------------------------------------------------

	const setCompanyInputsRequired = (value: boolean) => {
		setQ32CompanyNameExecutingWorkInput({
			...Q32CompanyNameExecutingWorkInput,
			required: value
		})
		setQ33CompanyNEQNumberInput({
			...Q33CompanyNEQNumberInput,
			required: value
		})
		setQ34CompanyRBQNumberInput({
			...Q34CompanyRBQNumberInput,
			required: value
		})
		setQ35CompanyRepresentativeNameInput({
			...Q35CompanyRepresentativeNameInput,
			required: value
		})
		setQ36CompanyRepresentativePhoneNumberInput({
			...Q36CompanyRepresentativePhoneNumberInput,
			required: value
		})
		setQ37CompanyRepresentativeEmailInput({
			...Q37CompanyRepresentativeEmailInput,
			required: value
		})
		setQ38CompanyAddressInput({
			...Q38CompanyAddressInput,
			required: value
		})
		setQ39CompanyCityInput({
			...Q39CompanyCityInput,
			required: value
		})
		setQ40CompanyPostalCodeInput({
			...Q40CompanyPostalCodeInput,
			required: value
		})
	}

	const onSetQ31WhoWillBeInChargeOfWorkInput = (value: string) => {
		setQ31WhoWillBeInChargeOfWorkInput({
			...Q31WhoWillBeInChargeOfWorkInput,
			value
		})

		setQ31_1EntrepreneurUnknownInput({
			...Q31_1EntrepreneurUnknownInput,
			value: 'false'
		})

		if (value == radioListWhoWillBeInChargeOfWork[0].value) {
			setCompanyInputsRequired(false)
		} else {
			setCompanyInputsRequired(true)
		}

		onFixError(
			errors.filter(
				(error) =>
					![
						Q31WhoWillBeInChargeOfWorkInput.name,
						Q32CompanyNameExecutingWorkInput.name,
						Q33CompanyNEQNumberInput.name,
						Q34CompanyRBQNumberInput.name,
						Q35CompanyRepresentativeNameInput.name,
						Q36CompanyRepresentativePhoneNumberInput.name,
						Q37CompanyRepresentativeEmailInput.name,
						Q38CompanyAddressInput.name,
						Q39CompanyCityInput.name,
						Q40CompanyPostalCodeInput.name
					].includes(error)
			)
		)
	}

	useUpdateInputs(Q31WhoWillBeInChargeOfWorkInput)

	// --------------------------------------------------------------------------

	const onSetQ31_1EntrepreneurUnknownInput = (value: string) => {
		setQ31_1EntrepreneurUnknownInput({
			...Q31_1EntrepreneurUnknownInput,
			value
		})

		if (value?.toString() == 'true') {
			setCompanyInputsRequired(false)
		} else {
			setCompanyInputsRequired(true)
		}

		onFixError(
			errors.filter(
				(error) =>
					![
						Q32CompanyNameExecutingWorkInput.name,
						Q33CompanyNEQNumberInput.name,
						Q34CompanyRBQNumberInput.name,
						Q35CompanyRepresentativeNameInput.name,
						Q36CompanyRepresentativePhoneNumberInput.name,
						Q37CompanyRepresentativeEmailInput.name,
						Q38CompanyAddressInput.name,
						Q39CompanyCityInput.name,
						Q40CompanyPostalCodeInput.name
					].includes(error)
			)
		)
	}

	useUpdateInputs(Q31_1EntrepreneurUnknownInput)

	//------------------------------------------------------------

	const onSetQ32CompanyNameExecutingWorkInput = (value: string) => {
		handleInputChange(
			setQ32CompanyNameExecutingWorkInput,
			Q32CompanyNameExecutingWorkInput,
			value,
			Q32CompanyNameExecutingWorkInput.name
		)
	}

	useUpdateInputs(Q32CompanyNameExecutingWorkInput)

	//------------------------------------------------------------

	const onSetQ33CompanyNEQNumberInput = (value: string) => {
		handleInputChange(
			setQ33CompanyNEQNumberInput,
			Q33CompanyNEQNumberInput,
			value,
			Q33CompanyNEQNumberInput.name
		)
	}

	useUpdateInputs(Q33CompanyNEQNumberInput)
	//------------------------------------------------------------

	const onSetQ34CompanyRBQNumberInput = (value: string) => {
		handleInputChange(
			setQ34CompanyRBQNumberInput,
			Q34CompanyRBQNumberInput,
			value,
			Q34CompanyRBQNumberInput.name
		)
	}

	useUpdateInputs(Q34CompanyRBQNumberInput)
	//------------------------------------------------------------

	const onSetQ35CompanyRepresentativeNameInput = (value: string) => {
		handleInputChange(
			setQ35CompanyRepresentativeNameInput,
			Q35CompanyRepresentativeNameInput,
			value,
			Q35CompanyRepresentativeNameInput.name
		)
	}

	useUpdateInputs(Q35CompanyRepresentativeNameInput)
	//------------------------------------------------------------
	const onSetQ36CompanyRepresentativePhoneNumberInput = (value: string) => {
		handleInputChange(
			setQ36CompanyRepresentativePhoneNumberInput,
			Q36CompanyRepresentativePhoneNumberInput,
			value,
			Q36CompanyRepresentativePhoneNumberInput.name
		)
	}

	useUpdateInputs(Q36CompanyRepresentativePhoneNumberInput)
	//------------------------------------------------------------

	const onSetQ37CompanyRepresentativeEmailInput = (value: string) => {
		handleInputChange(
			setQ37CompanyRepresentativeEmailInput,
			Q37CompanyRepresentativeEmailInput,
			value,
			Q37CompanyRepresentativeEmailInput.name
		)
	}

	useUpdateInputs(Q37CompanyRepresentativeEmailInput)
	//------------------------------------------------------------

	const onSetQ38CompanyAddressInput = (value: string) => {
		handleInputChange(
			setQ38CompanyAddressInput,
			Q38CompanyAddressInput,
			value,
			Q38CompanyAddressInput.name
		)
	}

	useUpdateInputs(Q38CompanyAddressInput)
	//------------------------------------------------------------

	const onSetQ39CompanyCityInput = (value: string) => {
		handleInputChange(
			setQ39CompanyCityInput,
			Q39CompanyCityInput,
			value,
			Q39CompanyCityInput.name
		)
	}

	useUpdateInputs(Q39CompanyCityInput)
	//------------------------------------------------------------
	const onSetQ40CompanyPostalCodeInput = (value: string) => {
		handleInputChange(
			setQ40CompanyPostalCodeInput,
			Q40CompanyPostalCodeInput,
			value?.toUpperCase(),
			Q40CompanyPostalCodeInput.name
		)
	}

	useUpdateInputs(Q40CompanyPostalCodeInput)
	//------------------------------------------------------------

	const onSetQ41IsWaterAndSewerConnectionRequiredInput = (value: string) => {
		setQ41IsWaterAndSewerConnectionRequiredInput({
			...Q41IsWaterAndSewerConnectionRequiredInput,
			value: value
		})

		if (value == radioListYesNo[1].value) {
			setQ41IsPropertyConnectedToMunicipalConduitsInput({
				...Q41IsPropertyConnectedToMunicipalConduitsInput,
				value: ''
			})
			setQ43PlannedWorkInput({
				...Q43PlannedWorkInput,
				value: ''
			})
			setQ44AdditionalInfoInput({
				...Q44AdditionalInfoInput,
				required: false
			})
			setQ45EntrepreneurUnknownInput({
				...Q45EntrepreneurUnknownInput,
				value: 'false'
			})
			setEntrepreneurFieldsRequired(false)

			setDisabledMunicipalConduits([])
		}

		onFixError(
			errors.filter(
				(error) =>
					![
						Q41IsWaterAndSewerConnectionRequiredInput.name,
						Q42AdditionalInfoInput.name,
						Q44AdditionalInfoInput.name,
						Q45EntrepreneurUnknownInput.name,
						Q46EntrepreneurNameInput.name,
						Q47EntrepreneurRBQNumberInput.name,
						Q48EntrepreneurRepresentativeNameInput.name,
						Q49EntrepreneurRepresentativePhoneNumberInput.name,
						Q50EntrepreneurRepresentativeEmailInput.name,
						Q51EntrepreneurRepresentativeAddressInput.name,
						Q52EntrepreneurRepresentativeCityInput.name,
						Q53EntrepreneurRepresentativePostalCodeInput.name
					].includes(error)
			)
		)
	}

	useUpdateInputs(Q41IsWaterAndSewerConnectionRequiredInput)

	//------------------------------------------------------------

	const [disabledMunicipalConduits, setDisabledMunicipalConduits] = useState<
		string[]
	>([])

	const onSetQ41IsPropertyConnectedToMunicipalConduitsInput = (
		value: string
	) => {
		let newValue
		if (Q41IsPropertyConnectedToMunicipalConduitsInput.value?.includes(value)) {
			newValue = pageUtils.removeStrFromStrArray(
				value,
				Q41IsPropertyConnectedToMunicipalConduitsInput.value
			)
		} else {
			newValue =
				Q41IsPropertyConnectedToMunicipalConduitsInput.value == ''
					? value
					: Q41IsPropertyConnectedToMunicipalConduitsInput.value + `, ${value}`
		}

		setQ41IsPropertyConnectedToMunicipalConduitsInput({
			...Q41IsPropertyConnectedToMunicipalConduitsInput,
			value: newValue
		})

		// Disable the value of the radio button that is not selected
		if (
			radioListIsPropertyConnectedToMunicipalConduits.slice(-1)[0].value !==
			value
		) {
			setDisabledMunicipalConduits([
				radioListIsPropertyConnectedToMunicipalConduits.slice(-1)[0].value
			])
		}
		// Disable the value of the radio button that is selected
		if (
			radioListIsPropertyConnectedToMunicipalConduits.slice(-1)[0].value ==
			value
		) {
			setDisabledMunicipalConduits(
				radioListIsPropertyConnectedToMunicipalConduits
					.slice(0, 4)
					.map((item) => item.value)
			)
		}
		// Enable the value of the radio button if the value is empty
		if (newValue == '') {
			setDisabledMunicipalConduits([])
		}

		onFixError(
			UpdateRequestInputsErrorsArray(
				errors!,
				Q41IsPropertyConnectedToMunicipalConduitsInput.name
			)
		)
	}

	useUpdateInputs(Q41IsPropertyConnectedToMunicipalConduitsInput)

	//-------------------------------------------------------------

	const onSetQ42AdditionalInfoInput = (value: string) => {
		handleInputChange(
			setQ42AdditionalInfoInput,
			Q42AdditionalInfoInput,
			value,
			Q42AdditionalInfoInput.name
		)
	}

	useUpdateInputs(Q42AdditionalInfoInput)

	//------------------------------------------------------------

	const onSetQ43PlannedWorkInput = (value: string) => {
		let newValue
		if (Q43PlannedWorkInput.value?.includes(value)) {
			newValue = pageUtils.removeStrFromStrArray(
				value,
				Q43PlannedWorkInput.value
			)
		} else {
			newValue =
				Q43PlannedWorkInput.value == ''
					? value
					: Q43PlannedWorkInput.value + `, ${value}`
		}

		setQ43PlannedWorkInput({
			...Q43PlannedWorkInput,
			value: newValue
		})

		if (newValue.includes(listPlannedWork.slice(-1)[0].value)) {
			setQ44AdditionalInfoInput({
				...Q44AdditionalInfoInput,
				required: true
			})
		} else {
			setQ44AdditionalInfoInput({
				...Q44AdditionalInfoInput,
				required: false
			})
		}

		if (isEntrepreneurFieldsRequired(newValue)) {
			if (Q45EntrepreneurUnknownInput.value?.toString() == 'true') {
				setEntrepreneurFieldsRequired(false)
			} else {
				setEntrepreneurFieldsRequired(true)
			}
		} else {
			setEntrepreneurFieldsRequired(false)
		}

		onFixError(
			errors.filter(
				(error) =>
					![
						Q43PlannedWorkInput.name,
						Q44AdditionalInfoInput.name,
						Q45EntrepreneurUnknownInput.name,
						Q46EntrepreneurNameInput.name,
						Q47EntrepreneurRBQNumberInput.name,
						Q48EntrepreneurRepresentativeNameInput.name,
						Q49EntrepreneurRepresentativePhoneNumberInput.name,
						Q50EntrepreneurRepresentativeEmailInput.name,
						Q51EntrepreneurRepresentativeAddressInput.name,
						Q52EntrepreneurRepresentativeCityInput.name,
						Q53EntrepreneurRepresentativePostalCodeInput.name
					].includes(error)
			)
		)
	}

	useUpdateInputs(Q43PlannedWorkInput)
	// --------------------------------------------------------------------------

	const onSetQ44AdditionalInfoInput = (value: string) => {
		handleInputChange(
			setQ44AdditionalInfoInput,
			Q44AdditionalInfoInput,
			value,
			Q44AdditionalInfoInput.name
		)
	}

	useUpdateInputs(Q44AdditionalInfoInput)

	// --------------------------------------------------------------------------

	const setEntrepreneurFieldsRequired = (value) => {
		setQ46EntrepreneurNameInput({
			...Q46EntrepreneurNameInput,
			required: value
		})
		setQ47EntrepreneurRBQNumberInput({
			...Q47EntrepreneurRBQNumberInput,
			required: value
		})
		setQ48EntrepreneurRepresentativeNameInput({
			...Q48EntrepreneurRepresentativeNameInput,
			required: value
		})
		setQ49EntrepreneurRepresentativePhoneNumberInput({
			...Q49EntrepreneurRepresentativePhoneNumberInput,
			required: value
		})
		setQ50EntrepreneurRepresentativeEmailInput({
			...Q50EntrepreneurRepresentativeEmailInput,
			required: value
		})
		setQ51EntrepreneurRepresentativeAddressInput({
			...Q51EntrepreneurRepresentativeAddressInput,
			required: value
		})
		setQ52EntrepreneurRepresentativeCityInput({
			...Q52EntrepreneurRepresentativeCityInput,
			required: value
		})
		setQ53EntrepreneurRepresentativePostalCodeInput({
			...Q53EntrepreneurRepresentativePostalCodeInput,
			required: value
		})
	}

	const onSetQ45EntrepreneurUnknownInput = (value: string) => {
		setQ45EntrepreneurUnknownInput({
			...Q45EntrepreneurUnknownInput,
			value
		})

		if (
			isEntrepreneurFieldsRequired(Q43PlannedWorkInput.value) &&
			value.toString() == 'true'
		) {
			setEntrepreneurFieldsRequired(false)
		} else {
			setEntrepreneurFieldsRequired(true)
		}

		onFixError(
			errors.filter(
				(error) =>
					![
						Q45EntrepreneurUnknownInput.name,
						Q46EntrepreneurNameInput.name,
						Q47EntrepreneurRBQNumberInput.name,
						Q48EntrepreneurRepresentativeNameInput.name,
						Q49EntrepreneurRepresentativePhoneNumberInput.name,
						Q50EntrepreneurRepresentativeEmailInput.name,
						Q51EntrepreneurRepresentativeAddressInput.name,
						Q52EntrepreneurRepresentativeCityInput.name,
						Q53EntrepreneurRepresentativePostalCodeInput.name
					].includes(error)
			)
		)
	}

	useUpdateInputs(Q45EntrepreneurUnknownInput)
	// --------------------------------------------------------------------------

	const onSetQ46EntrepreneurNameInput = (value: string) => {
		handleInputChange(
			setQ46EntrepreneurNameInput,
			Q46EntrepreneurNameInput,
			value,
			Q46EntrepreneurNameInput.name
		)
	}

	useUpdateInputs(Q46EntrepreneurNameInput)

	// --------------------------------------------------------------------------

	const onSetQ47EntrepreneurRBQNumberInput = (value: string) => {
		handleInputChange(
			setQ47EntrepreneurRBQNumberInput,
			Q47EntrepreneurRBQNumberInput,
			value,
			Q47EntrepreneurRBQNumberInput.name
		)
	}

	useUpdateInputs(Q47EntrepreneurRBQNumberInput)

	// --------------------------------------------------------------------------

	const onSetQ48EntrepreneurRepresentativeNameInput = (value: string) => {
		handleInputChange(
			setQ48EntrepreneurRepresentativeNameInput,
			Q48EntrepreneurRepresentativeNameInput,
			value,
			Q48EntrepreneurRepresentativeNameInput.name
		)
	}

	useUpdateInputs(Q48EntrepreneurRepresentativeNameInput)

	// --------------------------------------------------------------------------

	const onSetQ49EntrepreneurRepresentativePhoneNumberInput = (
		value: string
	) => {
		handleInputChange(
			setQ49EntrepreneurRepresentativePhoneNumberInput,
			Q49EntrepreneurRepresentativePhoneNumberInput,
			value,
			Q49EntrepreneurRepresentativePhoneNumberInput.name
		)
	}

	useUpdateInputs(Q49EntrepreneurRepresentativePhoneNumberInput)

	// --------------------------------------------------------------------------

	const onSetQ50EntrepreneurRepresentativeEmailInput = (value: string) => {
		handleInputChange(
			setQ50EntrepreneurRepresentativeEmailInput,
			Q50EntrepreneurRepresentativeEmailInput,
			value,
			Q50EntrepreneurRepresentativeEmailInput.name
		)
	}

	useUpdateInputs(Q50EntrepreneurRepresentativeEmailInput)

	// --------------------------------------------------------------------------

	const onSetQ51EntrepreneurRepresentativeAddressInput = (value: string) => {
		handleInputChange(
			setQ51EntrepreneurRepresentativeAddressInput,
			Q51EntrepreneurRepresentativeAddressInput,
			value,
			Q51EntrepreneurRepresentativeAddressInput.name
		)
	}

	useUpdateInputs(Q51EntrepreneurRepresentativeAddressInput)

	// --------------------------------------------------------------------------

	const onSetQ52EntrepreneurRepresentativeCityInput = (value: string) => {
		handleInputChange(
			setQ52EntrepreneurRepresentativeCityInput,
			Q52EntrepreneurRepresentativeCityInput,
			value,
			Q52EntrepreneurRepresentativeCityInput.name
		)
	}

	useUpdateInputs(Q52EntrepreneurRepresentativeCityInput)

	// --------------------------------------------------------------------------

	const onSetQ53EntrepreneurRepresentativePostalCodeInput = (value: string) => {
		handleInputChange(
			setQ53EntrepreneurRepresentativePostalCodeInput,
			Q53EntrepreneurRepresentativePostalCodeInput,
			value?.toUpperCase(),
			Q53EntrepreneurRepresentativePostalCodeInput.name
		)
	}

	useUpdateInputs(Q53EntrepreneurRepresentativePostalCodeInput)

	// --------------------------------------------------------------------------
	// --------------------------------------------------------------------------

	const onSetQ10PAMainUseOfExistingBuildingInput = (value: string) => {
		if (value != Q10PAMainUseOfExistingBuildingInput.value) {
			// reset the value of Q12PAWhatToBuildOrImproveInput
			setQ12PAWhatToBuildOrImproveInput({
				...Q12PAWhatToBuildOrImproveInput,
				value: ''
			})
		}

		setQ10PAMainUseOfExistingBuildingInput({
			...Q10PAMainUseOfExistingBuildingInput,
			value
		})

		if (value == REQUEST_CONFIGS_KEYS.mainUseOfExistingBuilding.secondOption) {
			setQ10_1PAExistingMainBuildingHasHousingInput({
				...Q10_1PAExistingMainBuildingHasHousingInput,
				required: true
			})
		} else {
			setQ10_1PAExistingMainBuildingHasHousingInput({
				...Q10_1PAExistingMainBuildingHasHousingInput,
				required: false
			})
		}

		onFixError(
			errors.filter(
				(error) =>
					![
						Q10PAMainUseOfExistingBuildingInput.name,
						Q10_1PAExistingMainBuildingHasHousingInput.name
					].includes(error)
			)
		)
	}

	useUpdateInputs(Q10PAMainUseOfExistingBuildingInput)

	//------------------------------------------------------------

	const onSetQ10_1PAExistingMainBuildingHasHousingInput = (value: string) => {
		if (value != Q10_1PAExistingMainBuildingHasHousingInput.value) {
			setQ12PAWhatToBuildOrImproveInput({
				...Q12PAWhatToBuildOrImproveInput,
				value: ''
			})
		}

		handleInputChange(
			setQ10_1PAExistingMainBuildingHasHousingInput,
			Q10_1PAExistingMainBuildingHasHousingInput,
			value,
			Q10_1PAExistingMainBuildingHasHousingInput.name
		)
	}

	useUpdateInputs(Q10_1PAExistingMainBuildingHasHousingInput)

	// --------------------------------------------------------------------------

	const onSetQ11PAIsTheNewBuildingPartOfAPropertyInput = (value: string) => {
		handleInputChange(
			setQ11PAIsTheNewBuildingPartOfAPropertyInput,
			Q11PAIsTheNewBuildingPartOfAPropertyInput,
			value,
			Q11PAIsTheNewBuildingPartOfAPropertyInput.name
		)
	}

	useUpdateInputs(Q11PAIsTheNewBuildingPartOfAPropertyInput)

	// --------------------------------------------------------------------------

	const updateRequiredDocumentsPA = (
		whatToBuildOrImprove: string | undefined,
		isformInputs?: boolean
	) => {
		const category = getDocumentCategory(
			'PA',
			whatToBuildOrImprove ?? '',
			isformInputs
		)

		setSecondPAQuestion({
			...secondPAQuestion,
			required: category?.secondPAInput == 'required'
		})

		setTwelfthQuestion({
			...twelfthQuestion,
			required: category?.twelfthInput == 'required'
		})

		setThirteenthQuestion({
			...thirteenthQuestion,
			required: category?.thirteenthInput == 'required'
		})

		setFourteenthQuestion({
			...fourteenthQuestion,
			required: category?.fourteenthInput == 'required'
		})

		setFourthQuestion({
			...fourthQuestion,
			required: category?.fourthInput == 'required'
		})

		setTenthQuestion({
			...tenthQuestion,
			required: category?.tenthInput == 'required'
		})

		setThirdPAQuestion({
			...thirdPAQuestion,
			required: category?.thirdPAInput == 'required'
		})
		setFourthPAQuestion({
			...fourthPAQuestion,
			required: category?.fourthPAInput == 'required'
		})
		setFifthPAQuestion({
			...fifthPAQuestion,
			required: category?.fifthPAInput == 'required'
		})
		setSixthPAQuestion({
			...sixthPAQuestion,
			required: category?.sixthPAInput == 'required'
		})
		setSeventhPAQuestion({
			...seventhPAQuestion,
			required: category?.seventhPAInput == 'required'
		})
		setEighthPAQuestion({
			...eighthPAQuestion,
			required: category?.eighthPAInput == 'required'
		})
		setNinthPAQuestion({
			...ninthPAQuestion,
			required: category?.ninthPAInput == 'required'
		})
		setTenthPAQuestion({
			...tenthPAQuestion,
			required: category?.tenthPAInput == 'required'
		})
		setEleventhPAQuestion({
			...eleventhPAQuestion,
			required: category?.eleventhPAInput == 'required'
		})
	}

	const onSetQ12PAWhatToBuildOrImproveInput = (value: string) => {
		setQ12PAWhatToBuildOrImproveInput({
			...Q12PAWhatToBuildOrImproveInput,
			value
		})

		setQ13PAConstructionTypeInput({
			...Q13PAConstructionTypeInput,
			required: isPAQuestionsRequired(value)?.Q13 || false
		})

		setQ14PAConstructionTypeOtherInput({
			...Q14PAConstructionTypeOtherInput,
			required: isPAQuestionsRequired(value)?.Q14 || false
		})

		setQ15PAFoundationTypeInput({
			...Q15PAFoundationTypeInput,
			required: isPAQuestionsRequired(value)?.Q15 || false
		})

		setQ16PAPlaceOnTheTerrainInput({
			...Q16PAPlaceOnTheTerrainInput,
			required: isPAQuestionsRequired(value)?.Q16 || false
		})

		setQ17PABuildingWidthInput({
			...Q17PABuildingWidthInput,
			required: isPAQuestionsRequired(value)?.Q17 || false
		})

		setQ18PABuildingLengthInput({
			...Q18PABuildingLengthInput,
			required: isPAQuestionsRequired(value)?.Q18 || false
		})

		setQ19PABuildingHeightInput({
			...Q19PABuildingHeightInput,
			required: isPAQuestionsRequired(value)?.Q19 || false
		})

		setQ20PABuildingSurfaceAreaInput({
			...Q20PABuildingSurfaceAreaInput,
			required: isPAQuestionsRequired(value)?.Q20 || false
		})

		setQ21PABuildingExternalCoveringMaterialsInput({
			...Q21PABuildingExternalCoveringMaterialsInput,
			required: isPAQuestionsRequired(value)?.Q21 || false
		})

		setQ22PABuildingRoofMaterialsInput({
			...Q22PABuildingRoofMaterialsInput,
			required: isPAQuestionsRequired(value)?.Q22 || false
		})

		setQ23PABuildingStructureMaterialsInput({
			...Q23PABuildingStructureMaterialsInput,
			required: isPAQuestionsRequired(value)?.Q23 || false
		})

		updateRequiredDocumentsPA(value, true)

		onFixError(
			errors.filter(
				(error) =>
					![
						Q12PAWhatToBuildOrImproveInput.name,
						Q13PAConstructionTypeInput.name,
						Q14PAConstructionTypeOtherInput.name,
						Q15PAFoundationTypeInput.name,
						Q16PAPlaceOnTheTerrainInput.name,
						Q17PABuildingWidthInput.name,
						Q18PABuildingLengthInput.name,
						Q19PABuildingHeightInput.name,
						Q20PABuildingSurfaceAreaInput.name,
						Q21PABuildingExternalCoveringMaterialsInput.name,
						Q22PABuildingRoofMaterialsInput.name,
						Q23PABuildingStructureMaterialsInput.name,
						secondPAQuestion.name,
						thirdPAQuestion.name,
						fourthPAQuestion.name,
						fifthPAQuestion.name,
						sixthPAQuestion.name,
						seventhPAQuestion.name,
						eighthPAQuestion.name,
						ninthPAQuestion.name,
						tenthPAQuestion.name,
						eleventhPAQuestion.name
					].includes(error)
			)
		)
	}

	useUpdateInputs(Q12PAWhatToBuildOrImproveInput)

	// --------------------------------------------------------------------------

	// --------------------------------------------------------------------------
	// Usage for Q13PAConstructionTypeInput
	const onSetQ13PAConstructionTypeInput = (value: string) => {
		handleInputChange(
			setQ13PAConstructionTypeInput,
			Q13PAConstructionTypeInput,
			value,
			Q13PAConstructionTypeInput.name
		)
	}

	useUpdateInputs(Q13PAConstructionTypeInput)

	// --------------------------------------------------------------------------
	// Usage for Q14PAConstructionTypeOtherInput
	const onSetQ14PAConstructionTypeOtherInput = (value: string) => {
		handleInputChange(
			setQ14PAConstructionTypeOtherInput,
			Q14PAConstructionTypeOtherInput,
			value,
			Q14PAConstructionTypeOtherInput.name
		)
	}

	useUpdateInputs(Q14PAConstructionTypeOtherInput)

	// --------------------------------------------------------------------------

	// Usage for Q15PAFoundationTypeInput
	const onSetQ15PAFoundationTypeInput = (value: string) => {
		handleInputChange(
			setQ15PAFoundationTypeInput,
			Q15PAFoundationTypeInput,
			value,
			Q15PAFoundationTypeInput.name
		)
	}

	useUpdateInputs(Q15PAFoundationTypeInput)

	// --------------------------------------------------------------------------

	// Usage for Q16PAPlaceOnTheTerrainInput
	const onSetQ16PAPlaceOnTheTerrainInput = (value: string) => {
		handleInputChange(
			setQ16PAPlaceOnTheTerrainInput,
			Q16PAPlaceOnTheTerrainInput,
			value,
			Q16PAPlaceOnTheTerrainInput.name
		)
	}

	useUpdateInputs(Q16PAPlaceOnTheTerrainInput)

	// --------------------------------------------------------------------------

	const onSetQ17PABuildingWidthInput = (value: string) => {
		handleInputChange(
			setQ17PABuildingWidthInput,
			Q17PABuildingWidthInput,
			value,
			Q17PABuildingWidthInput.name
		)
	}

	useUpdateInputs(Q17PABuildingWidthInput)

	// --------------------------------------------------------------------------

	const onSetQ17PABuildingWidthUnitInput = (value: string) => {
		handleInputChange(
			setQ17PABuildingWidthUnitInput,
			Q17PABuildingWidthUnitInput,
			value,
			Q17PABuildingWidthUnitInput.name
		)
	}

	useUpdateInputs(Q17PABuildingWidthUnitInput)

	// --------------------------------------------------------------------------

	const onSetQ18PABuildingLengthInput = (value: string) => {
		handleInputChange(
			setQ18PABuildingLengthInput,
			Q18PABuildingLengthInput,
			value,
			Q18PABuildingLengthInput.name
		)
	}

	useUpdateInputs(Q18PABuildingLengthInput)

	// --------------------------------------------------------------------------

	const onSetQ18PABuildingLengthUnitInput = (value: string) => {
		handleInputChange(
			setQ18PABuildingLengthUnitInput,
			Q18PABuildingLengthUnitInput,
			value,
			Q18PABuildingLengthUnitInput.name
		)
	}

	useUpdateInputs(Q18PABuildingLengthUnitInput)

	// --------------------------------------------------------------------------

	const onSetQ19PABuildingHeightInput = (value: string) => {
		handleInputChange(
			setQ19PABuildingHeightInput,
			Q19PABuildingHeightInput,
			value,
			Q19PABuildingHeightInput.name
		)
	}

	useUpdateInputs(Q19PABuildingHeightInput)

	// --------------------------------------------------------------------------

	const onSetQ19PABuildingHeightUnitInput = (value: string) => {
		handleInputChange(
			setQ19PABuildingHeightUnitInput,
			Q19PABuildingHeightUnitInput,
			value,
			Q19PABuildingHeightUnitInput.name
		)
	}

	useUpdateInputs(Q19PABuildingHeightUnitInput)

	// --------------------------------------------------------------------------

	const onSetQ20PABuildingSurfaceAreaInput = (value: string) => {
		handleInputChange(
			setQ20PABuildingSurfaceAreaInput,
			Q20PABuildingSurfaceAreaInput,
			value,
			Q20PABuildingSurfaceAreaInput.name
		)
	}

	useUpdateInputs(Q20PABuildingSurfaceAreaInput)

	// --------------------------------------------------------------------------

	const onSetQ20PABuildingSurfaceAreaUnitInput = (value: string) => {
		handleInputChange(
			setQ20PABuildingSurfaceAreaUnitInput,
			Q20PABuildingSurfaceAreaUnitInput,
			value,
			Q20PABuildingSurfaceAreaUnitInput.name
		)
	}

	useUpdateInputs(Q20PABuildingSurfaceAreaUnitInput)

	// --------------------------------------------------------------------------
	const onSetQ21PABuildingExternalCoveringMaterialsInput = (value: string) => {
		handleInputChange(
			setQ21PABuildingExternalCoveringMaterialsInput,
			Q21PABuildingExternalCoveringMaterialsInput,
			value,
			Q21PABuildingExternalCoveringMaterialsInput.name
		)
	}

	useUpdateInputs(Q21PABuildingExternalCoveringMaterialsInput)

	// --------------------------------------------------------------------------

	const onSetQ22PABuildingRoofMaterialsInput = (value: string) => {
		handleInputChange(
			setQ22PABuildingRoofMaterialsInput,
			Q22PABuildingRoofMaterialsInput,
			value,
			Q22PABuildingRoofMaterialsInput.name
		)
	}

	useUpdateInputs(Q22PABuildingRoofMaterialsInput)

	// --------------------------------------------------------------------------

	const onSetQ23PABuildingStructureMaterialsInput = (value: string) => {
		handleInputChange(
			setQ23PABuildingStructureMaterialsInput,
			Q23PABuildingStructureMaterialsInput,
			value,
			Q23PABuildingStructureMaterialsInput.name
		)
	}

	useUpdateInputs(Q23PABuildingStructureMaterialsInput)

	//////////////////////////////////////////////////////////////////////////////////////////////
	//////////////////////////////////////////////////////////////////////////////////////////////

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs!, dummyOptionalInput))
	}, [dummyOptionalInput])

	////////////////////////////////////////////////////////////////////////////////////////////////
	////////////////////////////////////////////////////////////////////////////////////////////////
	// DOCUMENTS
	////////////////////////////////////////////////////////////////////////////////////////////////
	////////////////////////////////////////////////////////////////////////////////////////////////

	const updateQuestionFiles = (setQuestion, question, files) => {
		setQuestion({
			...question,
			files
		})
		onFixError(UpdateRequestInputsErrorsArray(errors, question.name))
	}

	const onSelectFileFirstQuestion = (files: Set<File>) => {
		updateQuestionFiles(setFirstQuestion, firstQuestion, files)
	}

	const onSelectFileSecondQuestion = (files: Set<File>) => {
		updateQuestionFiles(setSecondQuestion, secondQuestion, files)
	}

	const onSelectFileThirdQuestion = (files: Set<File>) => {
		updateQuestionFiles(setThirdQuestion, thirdQuestion, files)
	}

	const onSelectFileFourthQuestion = (files: Set<File>) => {
		updateQuestionFiles(setFourthQuestion, fourthQuestion, files)
	}

	const onSelectFileFifthQuestion = (files: Set<File>) => {
		updateQuestionFiles(setFifthQuestion, fifthQuestion, files)
	}

	const onSelectFileSixthQuestion = (files: Set<File>) => {
		updateQuestionFiles(setSixthQuestion, sixthQuestion, files)
	}

	const onSelectFileSeventhQuestion = (files: Set<File>) => {
		updateQuestionFiles(setSeventhQuestion, seventhQuestion, files)
	}

	const onSelectFileEighthQuestion = (files: Set<File>) => {
		updateQuestionFiles(setEighthQuestion, eighthQuestion, files)
	}

	const onSelectFileNinthQuestion = (files: Set<File>) => {
		updateQuestionFiles(setNinthQuestion, ninthQuestion, files)
	}

	const onSelectFileTenthQuestion = (files: Set<File>) => {
		updateQuestionFiles(setTenthQuestion, tenthQuestion, files)
	}

	const onSelectFileEleventhQuestion = (files: Set<File>) => {
		updateQuestionFiles(setEleventhQuestion, eleventhQuestion, files)
	}

	const onSelectFileTwelfthQuestion = (files: Set<File>) => {
		updateQuestionFiles(setTwelfthQuestion, twelfthQuestion, files)
	}

	const onSelectFileThirteenthQuestion = (files: Set<File>) => {
		updateQuestionFiles(setThirteenthQuestion, thirteenthQuestion, files)
	}

	const onSelectFileFourteenthQuestion = (files: Set<File>) => {
		updateQuestionFiles(setFourteenthQuestion, fourteenthQuestion, files)
	}

	////////////////////////////////////////////////////////////////////////////////////////////////
	////////////////////////////////////////////////////////////////////////////////////////////////
	// FILES
	////////////////////////////////////////////////////////////////////////////////////////////////
	const onSelectFileSecondPAQuestion = (files: Set<File>) => {
		updateQuestionFiles(setSecondPAQuestion, secondPAQuestion, files)
	}

	const onSelectFileThirdPAQuestion = (files: Set<File>) => {
		updateQuestionFiles(setThirdPAQuestion, thirdPAQuestion, files)
	}

	const onSelectFileFourthPAQuestion = (files: Set<File>) => {
		updateQuestionFiles(setFourthPAQuestion, fourthPAQuestion, files)
	}

	const onSelectFileFifthPAQuestion = (files: Set<File>) => {
		updateQuestionFiles(setFifthPAQuestion, fifthPAQuestion, files)
	}

	const onSelectFileSixthPAQuestion = (files: Set<File>) => {
		updateQuestionFiles(setSixthPAQuestion, sixthPAQuestion, files)
	}

	const onSelectFileSeventhPAQuestion = (files: Set<File>) => {
		updateQuestionFiles(setSeventhPAQuestion, seventhPAQuestion, files)
	}

	const onSelectFileEighthPAQuestion = (files: Set<File>) => {
		updateQuestionFiles(setEighthPAQuestion, eighthPAQuestion, files)
	}

	const onSelectFileNinthPAQuestion = (files: Set<File>) => {
		updateQuestionFiles(setNinthPAQuestion, ninthPAQuestion, files)
	}

	const onSelectFileTenthPAQuestion = (files: Set<File>) => {
		updateQuestionFiles(setTenthPAQuestion, tenthPAQuestion, files)
	}

	const onSelectFileEleventhPAQuestion = (files: Set<File>) => {
		updateQuestionFiles(setEleventhPAQuestion, eleventhPAQuestion, files)
	}

	////////////////////////////////////////////////////////////////////////////////////////////////
	useEffect(() => {
		onSetInputFiles(UpdateRequestInputFilesArray(inputFiles, firstQuestion))
	}, [firstQuestion])

	useEffect(() => {
		onSetInputFiles(UpdateRequestInputFilesArray(inputFiles, secondQuestion))
	}, [secondQuestion])

	useEffect(() => {
		onSetInputFiles(UpdateRequestInputFilesArray(inputFiles, thirdQuestion))
	}, [thirdQuestion])

	useEffect(() => {
		onSetInputFiles(UpdateRequestInputFilesArray(inputFiles, fourthQuestion))
	}, [fourthQuestion])

	useEffect(() => {
		onSetInputFiles(UpdateRequestInputFilesArray(inputFiles, fifthQuestion))
	}, [fifthQuestion])

	useEffect(() => {
		onSetInputFiles(UpdateRequestInputFilesArray(inputFiles, sixthQuestion))
	}, [sixthQuestion])

	useEffect(() => {
		onSetInputFiles(UpdateRequestInputFilesArray(inputFiles, seventhQuestion))
	}, [seventhQuestion])

	useEffect(() => {
		onSetInputFiles(UpdateRequestInputFilesArray(inputFiles, eighthQuestion))
	}, [eighthQuestion])

	useEffect(() => {
		onSetInputFiles(UpdateRequestInputFilesArray(inputFiles, ninthQuestion))
	}, [ninthQuestion])

	useEffect(() => {
		onSetInputFiles(UpdateRequestInputFilesArray(inputFiles, tenthQuestion))
	}, [tenthQuestion])

	useEffect(() => {
		onSetInputFiles(UpdateRequestInputFilesArray(inputFiles, eleventhQuestion))
	}, [eleventhQuestion])

	useEffect(() => {
		onSetInputFiles(UpdateRequestInputFilesArray(inputFiles, twelfthQuestion))
	}, [twelfthQuestion])

	useEffect(() => {
		onSetInputFiles(
			UpdateRequestInputFilesArray(inputFiles, thirteenthQuestion)
		)
	}, [thirteenthQuestion])

	useEffect(() => {
		onSetInputFiles(
			UpdateRequestInputFilesArray(inputFiles, fourteenthQuestion)
		)
	}, [fourteenthQuestion])

	useEffect(() => {
		onSetInputFiles(UpdateRequestInputFilesArray(inputFiles, secondPAQuestion))
	}, [secondPAQuestion])

	useEffect(() => {
		onSetInputFiles(UpdateRequestInputFilesArray(inputFiles, thirdPAQuestion))
	}, [thirdPAQuestion])

	useEffect(() => {
		onSetInputFiles(UpdateRequestInputFilesArray(inputFiles, fourthPAQuestion))
	}, [fourthPAQuestion])

	useEffect(() => {
		onSetInputFiles(UpdateRequestInputFilesArray(inputFiles, fifthPAQuestion))
	}, [fifthPAQuestion])

	useEffect(() => {
		onSetInputFiles(UpdateRequestInputFilesArray(inputFiles, sixthPAQuestion))
	}, [sixthPAQuestion])

	useEffect(() => {
		onSetInputFiles(UpdateRequestInputFilesArray(inputFiles, seventhPAQuestion))
	}, [seventhPAQuestion])

	useEffect(() => {
		onSetInputFiles(UpdateRequestInputFilesArray(inputFiles, eighthPAQuestion))
	}, [eighthPAQuestion])

	useEffect(() => {
		onSetInputFiles(UpdateRequestInputFilesArray(inputFiles, ninthPAQuestion))
	}, [ninthPAQuestion])

	useEffect(() => {
		onSetInputFiles(UpdateRequestInputFilesArray(inputFiles, tenthPAQuestion))
	}, [tenthPAQuestion])

	useEffect(() => {
		onSetInputFiles(
			UpdateRequestInputFilesArray(inputFiles, eleventhPAQuestion)
		)
	}, [eleventhPAQuestion])

	////////////////////////////////////////////////////////////////////////////////////////////////
	// FILES FROM STORE

	// Helper function to set question files
	const setQuestionFiles = (inputName: string, question, setQuestion) => {
		if (!!answers[inputName] && question.files.size == 0) {
			getFilesFromStore(inputName, answers).then((result: Set<File>) => {
				setQuestion({
					...question,
					files: result
				})
			})
		}
	}

	useEffect(() => {
		// check if exist answers (filesInputs) at the store, to intialize the input with the files already present
		// ----------------------------
		// firstInput input
		setQuestionFiles('firstInput', firstQuestion, setFirstQuestion)

		// secondInput input
		setQuestionFiles('secondInput', secondQuestion, setSecondQuestion)

		// thirdInput input
		setQuestionFiles('thirdInput', thirdQuestion, setThirdQuestion)

		// fouthInput input
		setQuestionFiles('fourthInput', fourthQuestion, setFourthQuestion)

		// fifthInput input
		setQuestionFiles('fifthInput', fifthQuestion, setFifthQuestion)

		// sixthQuestion input
		setQuestionFiles('sixthInput', sixthQuestion, setSixthQuestion)

		// seventhQuestion input
		setQuestionFiles('seventhInput', seventhQuestion, setSeventhQuestion)

		// eighthQuestion input
		setQuestionFiles('eighthInput', eighthQuestion, setEighthQuestion)

		// ninthQuestion input
		setQuestionFiles('ninthInput', ninthQuestion, setNinthQuestion)

		// tenthQuestion input
		setQuestionFiles('tenthInput', tenthQuestion, setTenthQuestion)

		// eleventhQuestion input
		setQuestionFiles('eleventhInput', eleventhQuestion, setEleventhQuestion)

		// twelfthQuestion input
		setQuestionFiles('twelfthInput', twelfthQuestion, setTwelfthQuestion)

		// thirteenthQuestion input
		setQuestionFiles(
			'thirteenthInput',
			thirteenthQuestion,
			setThirteenthQuestion
		)

		// fourteenthQuestion input
		setQuestionFiles(
			'fourteenthInput',
			fourteenthQuestion,
			setFourteenthQuestion
		)

		// secondPAQuestion input
		setQuestionFiles('secondPAInput', secondPAQuestion, setSecondPAQuestion)

		// thirdPAQuestion input
		setQuestionFiles('thirdPAInput', thirdPAQuestion, setThirdPAQuestion)

		// fourthPAQuestion input
		setQuestionFiles('fourthPAInput', fourthPAQuestion, setFourthPAQuestion)

		// fifthPAQuestion input
		setQuestionFiles('fifthPAInput', fifthPAQuestion, setFifthPAQuestion)

		// sixthPAQuestion input
		setQuestionFiles('sixthPAInput', sixthPAQuestion, setSixthPAQuestion)

		// seventhPAQuestion input
		setQuestionFiles('seventhPAInput', seventhPAQuestion, setSeventhPAQuestion)

		// eighthPAQuestion input
		setQuestionFiles('eighthPAInput', eighthPAQuestion, setEighthPAQuestion)

		// ninthPAQuestion input
		setQuestionFiles('ninthPAInput', ninthPAQuestion, setNinthPAQuestion)

		// tenthPAQuestion input
		setQuestionFiles('tenthPAInput', tenthPAQuestion, setTenthPAQuestion)

		// eleventhPAQuestion input
		setQuestionFiles(
			'eleventhPAInput',
			eleventhPAQuestion,
			setEleventhPAQuestion
		)

		updateNbrOfParkingSpacesInputsRequired(
			answers?.whatToDo?.toString(),
			answers?.nbrOfParkingSpacesExt,
			answers?.nbrOfParkingSpacesInt,
			answers?.nbrOfParkingSpacesVis,
			answers?.nbrOfBikeSpaces
		)
	}, [])

	const onSetErrorFromFileComponent = (
		hasErrorInComponent: boolean,
		inputName: string
	) => {
		if (hasErrorInComponent) {
			if (!errors.includes(inputName)) {
				onFixError((oldErrors) => [...oldErrors, inputName])
			}

			return
		}

		onFixError(UpdateRequestInputsErrorsArray(errors, inputName))
	}

	////////////////////////////////////////////////////////////////////////////////////////////////

	useEffect(() => {
		// setSteps &&
		// 	setSteps({
		// 		...StepsUrbanism
		// 	})

		onSetSubSteps({
			map: false,
			details_1: true,
			details_2: true,
			details_3: true,
			details_4: true,
			details_5: true,
			details_6: true,
			requiredDocument: true,
			position:
				subSteps?.position && String(subSteps?.position) !== ''
					? subSteps?.position
					: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_1,
			steps: [
				REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_1,
				REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_2,
				REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_3,
				REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_4,
				REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_5,
				REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_6,
				REQUEST_TN_DETAILS_STEPS_NAME.DOCUMENTS
			]
		})

		setStepsList &&
			setStepsList([
				{
					title: formatStrapiText(pageAssets?.page_requestInfo_step1),
					subSteps: []
				},
				{
					title: formatStrapiText(pageAssets?.page_requestInfo_step2),
					subSteps: []
				},
				{
					title: formatStrapiText(pageAssets?.page_requestInfo_step3),
					subSteps: [
						pageAssets?.request_form_type_de_demandes,
						pageAssets?.request_form_request_location,
						pageAssets?.request_form_type_of_use,
						pageAssets?.request_form_building_informations,
						pageAssets?.request_form_information_on_the_work,
						pageAssets?.request_form_branching,
						pageAssets?.request_form_required_documents
					]
				},
				// {
				// 	title: formatStrapiText(
				// 		pageAssets?.page_requestInfo_step_complementary_requests
				// 	),
				// 	subSteps: [pageAssets?.page_requestInfo_step_complementary_requests]
				// },
				{
					title: formatStrapiText(pageAssets?.page_requestInfo_step4),
					subSteps: []
				},
				{
					title: formatStrapiText(pageAssets?.page_requestInfo_step5),
					subSteps: []
				}
			])
	}, [])

	const backToForm = (
		step: number,
		subStep: string,
		currentSubStep: number
	) => {
		if (setCurrentStep && onSetSubSteps && subSteps && onSetCurrentSubStep) {
			setCurrentStep(step)
			onSetSubSteps({
				...subSteps,
				position: subStep as REQUEST_TN_DETAILS_STEPS_NAME
			})
			onSetCurrentSubStep(currentSubStep)
		}
	}

	const getLabelFromList = (list, value) => {
		return list?.find((element) => element.value == value?.toString())?.label
	}

	const handleMeasurementInputChange = (
		value,
		measurementInputOnChange,
		maxCharacters
	) => {
		const cleanedValue = value.replace(/[.,]/g, '')

		if (cleanedValue.length <= maxCharacters) {
			measurementInputOnChange(value)
		}
	}

	const renderMeasurementInput = (
		measurementInput,
		measurementInputOnChange,
		unitInput,
		unitInputOnchange,
		listMeasurementUnit,
		maxCharacters,
		hasThousandsSeparator: boolean = false
	) => {
		return (
			<div className={classes.subContent}>
				{hasThousandsSeparator ? (
					<MaskedThousandsSeparatorInput
						id={measurementInput.name}
						label={measurementInput.label}
						value={measurementInput.value}
						onChange={(value) =>
							handleMeasurementInputChange(
								value,
								measurementInputOnChange,
								maxCharacters
							)
						}
						hasError={errors.includes(measurementInput.name)}
						required={measurementInput.required}
						ref={measurementInput.ref as RefObject<HTMLInputElement>}
						errors={errors}
						setError={onFixError}
						maxCharacters={maxCharacters}
					/>
				) : (
					<TextInput
						id={measurementInput.name}
						type="number"
						label={measurementInput.label}
						value={measurementInput.value ?? ''}
						onChange={(value) =>
							handleMeasurementInputChange(
								value,
								measurementInputOnChange,
								maxCharacters
							)
						}
						hasError={errors?.includes(measurementInput.name)}
						customErrorText={
							measurementInput.value !== ''
								? 'Veuillez entrer un nombre valide.'
								: undefined
						}
						onKeyPress={pageUtils.onKeyPressNumberInput}
						min="0"
						required={measurementInput.required}
						maxCharacters={maxCharacters}
					/>
				)}

				<RadioInput
					name={unitInput.name}
					label={''}
					value={unitInput.value ?? ''}
					list={listMeasurementUnit}
					direction={Direction.horizontal}
					hasError={errors!.includes(unitInput.name)}
					onChange={unitInputOnchange}
					hasBackgroundWrapper={true}
				/>
			</div>
		)
	}

	const questionAnswer = (question: string, answer?: string) => {
		if (answer) {
			return (
				<div>
					<strong>{question}</strong>
					<br />
					<span className={pageUtils.classes.answerURB}>{answer}</span>
				</div>
			)
		}
	}

	const getLabelsFromString = (list, answer?: string): string[] => {
		return list
			.filter((element) => answer?.includes(element.value))
			.map((item) => item.label)
	}

	// a function to render RequestFiles on the DOCUMENT or Summary step
	const renderRequestFiles = (
		type: string,
		category: string | undefined,
		question: RequestInputFiles,
		onSelectFileFunction,
		status: string,
		isSummary?: boolean
	) => {
		if (isSummary == true) {
			return (
				isDocumentToShow(type, category, question.name, status) &&
				question.files.size > 0 && (
					<RequestFiles
						subtitle={question.label}
						name={question.name}
						required={question.required}
						files={question.files}
						hasError={errors.includes(question.name)}
						removeSectionMarginTop
						onSetHasError={onSetErrorFromFileComponent}
						onSetFiles={onSelectFileFunction}
						maxFiles={question?.numberMaxFiles}
						isSummary
					/>
				)
			)
		}

		return (
			isDocumentToShow(type, category, question.name, status, true) && (
				<RequestFiles
					subtitle={question.label}
					name={question.name}
					required={question.required}
					files={question.files}
					hasError={errors.includes(question.name)}
					removeSectionMarginTop
					onSetHasError={onSetErrorFromFileComponent}
					onSetFiles={onSelectFileFunction}
					subSteps={subSteps}
					maxFiles={question?.numberMaxFiles}
					description={question.description}
				/>
			)
		)
	}

	// // a function to render RequestFiles on the SUMMARY step
	// const renderSummaryRequestFiles = (
	// 	type: string,
	// 	category: string | undefined,
	// 	question: RequestInputFiles,
	// 	onSelectFileFunction,
	// 	status: string
	// ) => {
	// 	return (
	// 		isDocumentToShow(type, category, question.name, status) &&
	// 		question.files.size > 0 && (
	// 			<RequestFiles
	// 				subtitle={question.label}
	// 				name={question.name}
	// 				required={question.required}
	// 				files={question.files}
	// 				hasError={errors.includes(question.name)}
	// 				removeSectionMarginTop
	// 				onSetHasError={onSetErrorFromFileComponent}
	// 				onSetFiles={onSelectFileFunction}
	// 				maxFiles={question?.numberMaxFiles}
	// 				isSummary
	// 			/>
	// 		)
	// 	)
	// }

	// show the files on the DOCUMENT step
	// @status : the status of the document (required or optionnal)
	// @isSummary : if the step is the summary step
	const renderAllPNRequestFiles = (status: string, isSummary: boolean) => {
		return (
			<>
				{renderRequestFiles(
					'PN',
					mainUseOfNewBuildingInput.value,
					secondQuestion,
					onSelectFileSecondQuestion,
					status,
					isSummary
				)}
				{/* juste for IA */}
				{renderRequestFiles(
					'PN',
					mainUseOfNewBuildingInput.value,
					twelfthQuestion,
					onSelectFileTwelfthQuestion,
					status,
					isSummary
				)}
				{renderRequestFiles(
					'PN',
					mainUseOfNewBuildingInput.value,
					thirteenthQuestion,
					onSelectFileThirteenthQuestion,
					status,
					isSummary
				)}
				{/* END IA */}
				{renderRequestFiles(
					'PN',
					mainUseOfNewBuildingInput.value,
					thirdQuestion,
					onSelectFileThirdQuestion,
					status,
					isSummary
				)}
				{renderRequestFiles(
					'PN',
					mainUseOfNewBuildingInput.value,
					fourthQuestion,
					onSelectFileFourthQuestion,
					status,
					isSummary
				)}
				{renderRequestFiles(
					'PN',
					mainUseOfNewBuildingInput.value,
					fifthQuestion,
					onSelectFileFifthQuestion,
					status,
					isSummary
				)}
				{renderRequestFiles(
					'PN',
					mainUseOfNewBuildingInput.value,
					sixthQuestion,
					onSelectFileSixthQuestion,
					status,
					isSummary
				)}
				{renderRequestFiles(
					'PN',
					mainUseOfNewBuildingInput.value,
					seventhQuestion,
					onSelectFileSeventhQuestion,
					status,
					isSummary
				)}
				{renderRequestFiles(
					'PN',
					mainUseOfNewBuildingInput.value,
					eighthQuestion,
					onSelectFileEighthQuestion,
					status,
					isSummary
				)}
				{renderRequestFiles(
					'PN',
					mainUseOfNewBuildingInput.value,
					ninthQuestion,
					onSelectFileNinthQuestion,
					status,
					isSummary
				)}
				{renderRequestFiles(
					'PN',
					mainUseOfNewBuildingInput.value,
					tenthQuestion,
					onSelectFileTenthQuestion,
					status,
					isSummary
				)}
				{renderRequestFiles(
					'PN',
					mainUseOfNewBuildingInput.value,
					eleventhQuestion,
					onSelectFileEleventhQuestion,
					status,
					isSummary
				)}
			</>
		)
	}

	// show the files PA on the DOCUMENT step
	// @status : the status of the document (required or optionnal)
	// @isSummary : if the step is the summary step
	const renderAllPARequestFiles = (status: string, isSummary: boolean) => {
		return (
			<>
				{renderRequestFiles(
					'PA',
					Q12PAWhatToBuildOrImproveInput.value,
					secondPAQuestion,
					onSelectFileSecondPAQuestion,
					status,
					isSummary
				)}
				{/* juste for IA */}
				{/* !------------------------- */}

				{renderRequestFiles(
					'PA', // isIA
					Q12PAWhatToBuildOrImproveInput.value,
					twelfthQuestion,
					onSelectFileTwelfthQuestion,
					status,
					isSummary
				)}

				{renderRequestFiles(
					'PA', // isIA
					Q12PAWhatToBuildOrImproveInput.value,
					tenthQuestion,
					onSelectFileTenthQuestion,
					status,
					isSummary
				)}

				{renderRequestFiles(
					'PA', // isIA
					Q12PAWhatToBuildOrImproveInput.value,
					fourthQuestion,
					onSelectFileFourthQuestion,
					status,
					isSummary
				)}

				{renderRequestFiles(
					'PA', // isIA
					Q12PAWhatToBuildOrImproveInput.value,
					fourteenthQuestion,
					onSelectFileFourteenthQuestion,
					status,
					isSummary
				)}
				{/* END IA */}
				{/* !------------------------- */}

				{renderRequestFiles(
					'PA',
					Q12PAWhatToBuildOrImproveInput.value,
					thirdPAQuestion,
					onSelectFileThirdPAQuestion,
					status,
					isSummary
				)}
				{renderRequestFiles(
					'PA',
					Q12PAWhatToBuildOrImproveInput.value,
					fourthPAQuestion,
					onSelectFileFourthPAQuestion,
					status,
					isSummary
				)}
				{renderRequestFiles(
					'PA',
					Q12PAWhatToBuildOrImproveInput.value,
					fifthPAQuestion,
					onSelectFileFifthPAQuestion,
					status,
					isSummary
				)}
				{renderRequestFiles(
					'PA',
					Q12PAWhatToBuildOrImproveInput.value,
					sixthPAQuestion,
					onSelectFileSixthPAQuestion,
					status,
					isSummary
				)}
				{renderRequestFiles(
					'PA',
					Q12PAWhatToBuildOrImproveInput.value,
					seventhPAQuestion,
					onSelectFileSeventhPAQuestion,
					status,
					isSummary
				)}
				{renderRequestFiles(
					'PA',
					Q12PAWhatToBuildOrImproveInput.value,
					eighthPAQuestion,
					onSelectFileEighthPAQuestion,
					status,
					isSummary
				)}
				{renderRequestFiles(
					'PA',
					Q12PAWhatToBuildOrImproveInput.value,
					ninthPAQuestion,
					onSelectFileNinthPAQuestion,
					status,
					isSummary
				)}
				{renderRequestFiles(
					'PA',
					Q12PAWhatToBuildOrImproveInput.value,
					tenthPAQuestion,
					onSelectFileTenthPAQuestion,
					status,
					isSummary
				)}
				{renderRequestFiles(
					'PA',
					Q12PAWhatToBuildOrImproveInput.value,
					eleventhPAQuestion,
					onSelectFileEleventhPAQuestion,
					status,
					isSummary
				)}
			</>
		)
	}

	useEffect(() => {
		{
			console.log('Errors -> ', errors)
			// console.table(inputFiles)
		}
	}, [errors])

	const isAucunChoix = () => {
		if (
			answers?.isTheNewBuilding?.toString() ==
			radioListIsTheNewBuilding[2].value
		) {
			return `(${radioListIsTheNewBuilding[0].label}, ${radioListIsTheNewBuilding[1].label})`
		}
		return ''
	}

	if (isSummaryStep) {
		return (
			<section
				className={joinClasses([
					pageUtils.classes.noMargin,
					pageUtils.classes.collapse,
					pageUtils.classes.summarySubTitles,
					toPrint ? pageUtils.classes.toPrintTitle : ''
				])}
				style={{ padding: '0px' }}
			>
				{subSteps?.details_1 && (
					<>
						<Collapse
							id={1}
							buttonText={pageAssets?.request_form_type_de_demandes}
							isOpen={toPrint}
							key={`request_form_type_de_demandes`}
						>
							<div style={{ position: 'relative' }}>
								{!toPrint && (
									<a
										style={{
											display: toPrint ? 'none' : 'flex'
										}}
										className={joinClasses([
											pageUtils.classes.btn,
											pageUtils.classes.button,
											pageUtils.classes.buttonOutline,
											pageUtils.classes.summaryCollapseButton
										])}
										onClick={() =>
											backToForm(
												steps?.form!,
												REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_1,
												1
											)
										}
									>
										{pageAssets?.label_modify}
									</a>
								)}
								{toPrint && (
									<h4 className={pageUtils.classes.h4}>
										{pageAssets?.request_form_type_de_demandes}
									</h4>
								)}
							</div>

							{/* Question 1 */}
							<div>
								<strong>{whatToDoInput.label}</strong>
								<br />
								<span className={pageUtils.classes.answerURB}>
									<Text
										content={getLabelFromList(
											radioListWhatToDo,
											answers?.whatToDo
										)}
									/>
								</span>
							</div>

							{/* Question 2 */}
							<div>
								<strong>{requestAsInput.label}</strong>
								<br />
								<span className={pageUtils.classes.answerURB}>
									{getLabelFromList(radioListRequestAs, answers?.requestAs)}
								</span>
							</div>

							{answers?.requestAs?.toString() ==
								REQUEST_CONFIGS_KEYS.requestAs.entreprise && (
								<>
									<div>
										<strong>{companyNameInput.label}</strong>
										<br />
										<span className={pageUtils.classes.answerURB}>
											{answers?.companyName}
										</span>
									</div>
									<div>
										<strong>{representativeNameInput.label}</strong>
										<br />
										<span className={pageUtils.classes.answerURB}>
											{answers?.representativeName}
										</span>
									</div>
								</>
							)}

							{/* Question 3: Is Subject to PIIA Approval */}
							<div>
								<strong>{isSubjectToPiiaApprovalInput.label}</strong>
								<br />
								<span className={pageUtils.classes.answerURB}>
									{getLabelFromList(
										radioListYesNo,
										answers?.isSubjectToPiiaApproval
									)}
								</span>
							</div>

							{/* Question 4: Has Been Subject to Approval */}
							{answers?.isSubjectToPiiaApproval?.toString() ==
								radioListYesNo[0].value && (
								<>
									<div>
										<strong>{hasBeenSubjectToApprovalInput.label}</strong>
										<br />
										<span className={pageUtils.classes.answerURB}>
											{getLabelFromList(
												radioListYesNo,
												answers?.hasBeenSubjectToApproval
											)}
										</span>
									</div>

									{answers?.hasBeenSubjectToApproval?.toString() ==
										radioListYesNo[0].value && (
										<>
											{/* Question 4.1: IA Number */}
											<div>
												<strong>{iaNumberInput.label}</strong>
												<br />
												<span className={pageUtils.classes.answerURB}>
													{answers?.iaNumber}
												</span>
											</div>

											{/* Question 4.2: EC Resolution Number */}
											{answers?.ecResolutionNumber && (
												<div>
													<strong>{ecResolutionNumberInput.label}</strong>
													<br />
													<span className={pageUtils.classes.answerURB}>
														{answers?.ecResolutionNumber}
													</span>
												</div>
											)}
										</>
									)}
								</>
							)}

							{/* Question 5: Is Real Estate Project */}
							<div>
								<strong>{isRealEstateProjectInput.label}</strong>
								<br />
								<span className={pageUtils.classes.answerURB}>
									{getLabelFromList(
										radioListYesNo,
										answers?.isRealEstateProject
									)}
								</span>
							</div>

							{/* Question 6: Project Number */}
							{answers?.isRealEstateProject?.toString() ==
								radioListYesNo[0].value && (
								<div>
									<strong>{projectNumberInput.label}</strong>
									<br />
									<span className={pageUtils.classes.answerURB}>
										{answers?.projectNumber}
									</span>
								</div>
							)}
						</Collapse>
					</>
				)}

				{subSteps?.details_2 && (
					<>
						<Collapse
							id={2}
							buttonText={pageAssets?.request_form_request_location}
							isOpen={toPrint}
							key={`request_form_request_location`}
						>
							<div style={{ position: 'relative' }}>
								{!toPrint && (
									<a
										style={{
											display: toPrint ? 'none' : 'flex'
										}}
										className={joinClasses([
											pageUtils.classes.btn,
											pageUtils.classes.button,
											pageUtils.classes.buttonOutline,
											pageUtils.classes.summaryCollapseButton
										])}
										onClick={() =>
											backToForm(
												steps?.form!,
												REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_2,
												2
											)
										}
									>
										{pageAssets?.label_modify}
									</a>
								)}
								{toPrint && (
									<h4 className={pageUtils.classes.h4}>
										{pageAssets?.request_form_request_location}
									</h4>
								)}
							</div>

							{/* Question 7 */}
							{isAddition(whatToDoInput.value) && (
								<div>
									<strong>{isSameLocationInput.label}</strong>
									<br />
									<span className={pageUtils.classes.answerURB}>
										{getLabelFromList(
											radioListYesNo,
											answers?.buildingAddressSameHasApplicant
										)}
									</span>
								</div>
							)}

							{/* Question 8 */}
							{(answers?.buildingAddressSameHasApplicant?.toString() ==
								radioListYesNo[1].value ||
								isNewConstruction(answers?.whatToDo)) && (
								<>
									<div>
										<strong>{pageAssets?.request_form_location}</strong>
										<br />
										<span className={pageUtils.classes.answerURB}>
											{answers?.addressField ? (
												<>
													{answers?.addressField}
													<br />
												</>
											) : (
												''
											)}

											{answers?.addressLotNumber
												? pageAssets?.label_cadastral_number +
												  ': ' +
												  answers?.addressLotNumber
												: ''}
										</span>
									</div>
									{answers?.addressVoiePublic && (
										<div>
											<strong>
												{
													pageAssets?.request_form_addressVoiePublicSection_public_road
												}
											</strong>
											<br />
											<span>
												{answers?.addressCivicNumber
													? answers?.addressCivicNumber + ', '
													: ''}
												{answers?.addressVoiePublic}
											</span>
										</div>
									)}
									{answers?.addressVoiePublic2 && (
										<div>
											<span>
												{answers?.addressCivicNumber2
													? answers?.addressCivicNumber2 + ', '
													: ''}
												{answers?.addressVoiePublic2}
											</span>
										</div>
									)}

									{answers?.addressVoiePublic3 && (
										<div>
											<span>
												{answers?.addressCivicNumber3
													? answers?.addressCivicNumber3 + ', '
													: ''}
												{answers?.addressVoiePublic3}
											</span>
										</div>
									)}

									{answers?.addressAdditionalInfos && (
										<div>
											<span>{answers?.addressAdditionalInfos}</span>
										</div>
									)}
									<br />
								</>
							)}

							{/* Question 9 */}
							<div>
								<strong>{isLocationOwnedInput.label}</strong>
								<br />
								<span className={pageUtils.classes.answerURB}>
									{getLabelFromList(radioListYesNo, answers?.isLocationOwned)}
								</span>
							</div>

							{/* ****** Owner inputs ****** */}
							{answers?.isLocationOwned?.toString() ==
								radioListYesNo[1].value && (
								<>
									{questionAnswer(
										ownerFirstNameInput.label,
										answers?.ownerFirstName
									)}
									{questionAnswer(
										ownerLastNameInput.label,
										answers?.ownerLastName
									)}
									{questionAnswer(ownerEmailInput.label, answers?.ownerEmail)}
									{questionAnswer(
										ownerPhoneNumberInput.label,
										answers?.ownerPhoneNumber
									)}

									<RequestFiles
										subtitle={firstQuestion.label}
										name={firstQuestion.name}
										required={firstQuestion.required}
										files={firstQuestion.files}
										hasError={errors.includes(firstQuestion.name)}
										removeSectionMarginTop
										removePadding
										equivalentMargin
										onSetHasError={onSetErrorFromFileComponent}
										onSetFiles={onSelectFileFirstQuestion}
										maxFiles={firstQuestion.numberFilesRequired}
										isSummary
									>
										<></>
									</RequestFiles>
									<br />
								</>
							)}
						</Collapse>
					</>
				)}

				{subSteps?.details_3 && (
					<>
						<Collapse
							id={3}
							buttonText={pageAssets?.request_form_type_of_use}
							isOpen={toPrint}
							key={`request_form_type_of_use`}
						>
							<div style={{ position: 'relative' }}>
								{!toPrint && (
									<a
										style={{
											display: toPrint ? 'none' : 'flex'
										}}
										className={joinClasses([
											pageUtils.classes.btn,
											pageUtils.classes.button,
											pageUtils.classes.buttonOutline,
											pageUtils.classes.summaryCollapseButton
										])}
										onClick={() =>
											backToForm(
												steps?.form!,
												REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_3,
												3
											)
										}
									>
										{pageAssets?.label_modify}
									</a>
								)}
								{toPrint && (
									<h4 className={pageUtils.classes.h4}>
										{pageAssets?.request_form_type_of_use}
									</h4>
								)}
							</div>

							<div>
								{isNewConstruction(answers?.whatToDo) && (
									<>
										{/* Question 10 */}
										{questionAnswer(
											mainUseOfNewBuildingInput.label,
											getLabelFromList(
												radioListMainUseOfNewBuilding,
												answers?.mainUseOfBuilding
											)
										)}
										{/* Question 11 */}
										{questionAnswer(
											`${
												pageAssets?.request_form_buildingConstructionOrAdditionPermit_isTheNewBuilding
											} ${isAucunChoix()}`,
											getLabelFromList(
												radioListIsTheNewBuilding,
												answers?.isTheNewBuilding
											)
										)}
										{/* Question 12 */}
										{questionAnswer(
											isTheNewBuildingPartOfAPropertyInput.label,
											getLabelFromList(
												radioListYesNo,
												answers?.isTheNewBuildingPartOfAProperty
											)
										)}
									</>
								)}

								{isAddition(answers?.whatToDo) && (
									<>
										{/* Question 10 PA */}
										{questionAnswer(
											Q10PAMainUseOfExistingBuildingInput.label,
											getLabelFromList(
												radioListMainUseOfExistingBuilding,
												answers?.mainUseOfExistingBuilding
											)
										)}

										{/* Question 10.1 PA */}
										{answers?.mainUseOfExistingBuilding?.toString() ==
											REQUEST_CONFIGS_KEYS.mainUseOfExistingBuilding
												.secondOption &&
											questionAnswer(
												Q10_1PAExistingMainBuildingHasHousingInput.label,
												getLabelFromList(
													radioListYesNo,
													answers?.existingMainBuildingHasHousing
												)
											)}

										{/* Question 11 PA */}
										{questionAnswer(
											Q11PAIsTheNewBuildingPartOfAPropertyInput.label,
											getLabelFromList(
												radioListYesNo,
												answers?.existingMainBuildingIsShared
											)
										)}
									</>
								)}
							</div>
						</Collapse>
					</>
				)}
				{subSteps?.details_4 && (
					<>
						<Collapse
							id={4}
							buttonText={pageAssets?.request_form_building_informations}
							isOpen={toPrint}
							key={`request_form_building_informations`}
						>
							<div style={{ position: 'relative' }}>
								{!toPrint && (
									<a
										style={{
											display: toPrint ? 'none' : 'flex'
										}}
										className={joinClasses([
											pageUtils.classes.btn,
											pageUtils.classes.button,
											pageUtils.classes.buttonOutline,
											pageUtils.classes.summaryCollapseButton
										])}
										onClick={() =>
											backToForm(
												steps?.form!,
												REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_4,
												4
											)
										}
									>
										{pageAssets?.label_modify}
									</a>
								)}
								{toPrint && (
									<h4 className={pageUtils.classes.h4}>
										{pageAssets?.request_form_building_informations}
									</h4>
								)}
							</div>

							{isNewConstruction(answers?.whatToDo) && (
								<>
									{/* Question 13 */}
									{questionAnswer(
										structureTypeInput.label,
										getLabelFromList(
											radioListStructureType,
											answers?.structureType
										)
									)}

									<h5 className={pageUtils.classes.h5}>
										{
											pageAssets?.request_form_buildingConstructionOrAdditionPermit_caracteristics
										}
									</h5>

									{/* Question 14 */}
									{shouldDisplayQuestions(answers?.mainUseOfBuilding)?.Q14 && (
										<>
											{questionAnswer(
												Q14BuildingAreaInput.label,
												`${formatNumberWithSpaces(
													answers?.buildingArea?.toString()
												)} ${getLabelFromList(
													radioListMeasurement2Unit,
													answers?.buildingAreaUnit
												)}`
											)}
										</>
									)}
									{/* Question 15 */}
									{shouldDisplayQuestions(answers?.mainUseOfBuilding)?.Q15 && (
										<>
											{questionAnswer(
												Q15TotalGrossFloorAreaInput.label,
												`${formatNumberWithSpaces(
													answers?.totalGrossFloorArea?.toString()
												)} ${getLabelFromList(
													radioListMeasurement2Unit,
													answers?.totalGrossFloorAreaUnit
												)}`
											)}
										</>
									)}

									{/* Question 16 */}
									{shouldDisplayQuestions(answers?.mainUseOfBuilding)
										?.Q16_1 && (
										<>
											{questionAnswer(
												Q16_1IsDeclaredAreaForResidentialUseInput.label,
												getLabelFromList(
													radioListYesNo,
													answers?.isDeclaredAreaForResidentialUse
												)
											)}
											{/* Question 16.2 */}
											{answers?.isDeclaredAreaForResidentialUse?.toString() ==
												radioListYesNo[1].value && (
												<>
													{questionAnswer(
														Q16_2NonResidentialAreasInput.label,
														`${answers?.nonResidentialAreas} ${getLabelFromList(
															radioListMeasurement2Unit,
															answers?.nonResidentialAreasUnit
														)}`
													)}
												</>
											)}
										</>
									)}
									{/* Question 17 */}
									{shouldDisplayQuestions(answers?.mainUseOfBuilding)?.Q17 && (
										<>
											{questionAnswer(
												Q17NumberOfFloorsInput.label,
												answers?.numberOfFloors?.toString()
											)}
										</>
									)}
									{/* Question 18 */}
									{shouldDisplayQuestions(answers?.mainUseOfBuilding)?.Q18 && (
										<>
											{questionAnswer(
												Q18NumberOfResidentialUnitsInput.label,
												answers?.numberOfResidentialUnits?.toString()
											)}
										</>
									)}

									{/* Garage et stationnement */}
									<h5 className={pageUtils.classes.h5}>
										{
											pageAssets?.request_form_buildingConstructionOrAdditionPermit_garage_parking
										}
									</h5>

									{/* Question 19 */}
									{questionAnswer(
										Q19HasGarageInput.label,
										getLabelFromList(radioListYesNo, answers?.hasGarage)
									)}

									{/* Question 19.1 */}
									{answers?.hasGarage?.toString() ==
										radioListYesNo[0].value && (
										<>
											{questionAnswer(
												Q19_1GarageLocationInput.label,
												getLabelFromList(
													radioListGarageLocation,
													answers?.garageLocation
												)
											)}
										</>
									)}

									{/* Question 20 */}
									{answers?.nbrOfParkingSpacesExt !== undefined &&
										answers?.nbrOfParkingSpacesExt?.toString() != '' && (
											<div>
												<strong>
													{parse(
														formatHtmlForStrapiText(
															Q20NbrOfParkingSpacesExtInput.label
														)
													)}
												</strong>
												<span className={pageUtils.classes.answerURB}>
													{answers?.nbrOfParkingSpacesExt}
												</span>
											</div>
										)}

									{/* Question 21 */}
									{answers?.nbrOfParkingSpacesInt !== undefined &&
										answers?.nbrOfParkingSpacesInt?.toString() != '' && (
											<div>
												<strong>
													{parse(
														formatHtmlForStrapiText(
															Q21NbrOfParkingSpacesIntInput.label
														)
													)}
												</strong>
												<span className={pageUtils.classes.answerURB}>
													{answers?.nbrOfParkingSpacesInt}
												</span>
											</div>
										)}

									{/* Question 22 */}
									{answers?.nbrOfParkingSpacesVis !== undefined &&
										answers?.nbrOfParkingSpacesVis?.toString() != '' && (
											<div>
												<strong>
													{parse(
														formatHtmlForStrapiText(
															Q22NbrOfParkingSpacesVisInput.label
														)
													)}
												</strong>
												<span className={pageUtils.classes.answerURB}>
													{answers?.nbrOfParkingSpacesVis}
												</span>
											</div>
										)}

									{/* Question 23 */}
									{answers?.nbrOfBikeSpaces !== undefined &&
										answers?.nbrOfBikeSpaces?.toString() != '' && (
											<div>
												<strong>
													{parse(
														formatHtmlForStrapiText(
															Q23NbrOfBikeSpacesInput.label
														)
													)}
												</strong>
												<span className={pageUtils.classes.answerURB}>
													{answers?.nbrOfBikeSpaces}
												</span>
											</div>
										)}
								</>
							)}

							{isAddition(answers?.whatToDo) && (
								<>
									{/* Question 12 PA */}
									{questionAnswer(
										Q12PAWhatToBuildOrImproveInput.label,
										getLabelFromList(
											radioListWhatToBuildOrImprove,
											answers?.whatToBuildOrImprove
										)?.replace(/\*\*/g, '')
									)}

									{answers?.whatToBuildOrImprove &&
										answers?.whatToBuildOrImprove?.toString() !=
											REQUEST_CONFIGS_KEYS.whatToBuildOrImprove.fifthOption &&
										(answers?.constructionType ||
											answers?.constructionTypeOther ||
											answers?.foundationType ||
											answers?.placeOnTheTerrain) && (
											<h5 className={pageUtils.classes.h5}>
												{radioListWhatToBuildOrImprove
													.find(
														(option) =>
															option.value == answers?.whatToBuildOrImprove
													)
													?.label?.replace(/\*\*/g, '')}
											</h5>
										)}

									{/* Question 13 PA */}
									{shouldDisplayPAQuestion(
										answers?.whatToBuildOrImprove,
										'Q13'
									) && (
										<>
											{questionAnswer(
												Q13PAConstructionTypeInput.label,
												getLabelFromList(
													radioListConstructionType,
													answers?.constructionType
												)
											)}
										</>
									)}

									{/* Question 14 PA */}
									{shouldDisplayPAQuestion(
										answers?.whatToBuildOrImprove,
										'Q14'
									) && (
										<>
											{questionAnswer(
												Q14PAConstructionTypeOtherInput.label,
												answers?.constructionTypeOther
											)}
										</>
									)}

									{/* Question 15 PA */}
									{shouldDisplayPAQuestion(
										answers?.whatToBuildOrImprove,
										'Q15'
									) && (
										<>
											{questionAnswer(
												Q15PAFoundationTypeInput.label,
												getLabelFromList(
													radioListFoundationType,
													answers?.foundationType
												)
											)}
										</>
									)}

									{/* Question 16 PA */}
									{shouldDisplayPAQuestion(
										answers?.whatToBuildOrImprove,
										'Q16'
									) && (
										<>
											{questionAnswer(
												Q16PAPlaceOnTheTerrainInput.label,
												getLabelFromList(
													radioListPlaceOnTheTerrain,
													answers?.placeOnTheTerrain
												)
											)}
										</>
									)}

									{answers?.whatToBuildOrImprove &&
										answers?.whatToBuildOrImprove?.toString() !=
											REQUEST_CONFIGS_KEYS.whatToBuildOrImprove.fifthOption &&
										(answers?.buildingWidth !== undefined ||
											answers?.buildingLength !== undefined ||
											answers?.buildingHeight !== undefined ||
											answers?.buildingSurfaceArea !== undefined) && (
											<h6 className={pageUtils.classes.h6}>
												{
													pageAssets.request_form_buildingConstructionOrAdditionPermit_dimensions
												}
											</h6>
										)}

									{/* Question 17 PA */}
									{shouldDisplayPAQuestion(
										answers?.whatToBuildOrImprove,
										'Q17'
									) &&
										answers?.buildingWidth !== undefined && (
											<>
												{questionAnswer(
													Q17PABuildingWidthInput.label,
													`${answers?.buildingWidth} ${getLabelFromList(
														radioListMeasurementUnit,
														answers?.buildingWidthUnit
													)}`
												)}
											</>
										)}

									{/* Question 18 PA */}
									{shouldDisplayPAQuestion(
										answers?.whatToBuildOrImprove,
										'Q18'
									) &&
										answers?.buildingLength !== undefined && (
											<>
												{questionAnswer(
													Q18PABuildingLengthInput.label,
													`${answers?.buildingLength} ${getLabelFromList(
														radioListMeasurementUnit,
														answers?.buildingLengthUnit
													)}`
												)}
											</>
										)}

									{/* Question 19 PA */}
									{shouldDisplayPAQuestion(
										answers?.whatToBuildOrImprove,
										'Q19'
									) &&
										answers?.buildingHeight !== undefined && (
											<>
												{questionAnswer(
													Q19PABuildingHeightInput.label,
													`${answers?.buildingHeight} ${getLabelFromList(
														radioListMeasurementUnit,
														answers?.buildingHeightUnit
													)}`
												)}
											</>
										)}

									{/* Question 20 PA */}
									{shouldDisplayPAQuestion(
										answers?.whatToBuildOrImprove,
										'Q20'
									) &&
										answers?.buildingSurfaceArea !== undefined && (
											<>
												{questionAnswer(
													Q20PABuildingSurfaceAreaInput.label,
													`${answers?.buildingSurfaceArea} ${getLabelFromList(
														radioListMeasurement2Unit,
														answers?.buildingSurfaceAreaUnit
													)}`
												)}
											</>
										)}

									{answers?.whatToBuildOrImprove &&
										![
											REQUEST_CONFIGS_KEYS.whatToBuildOrImprove.fifthOption,
											REQUEST_CONFIGS_KEYS.whatToBuildOrImprove.fourthOption
										].includes(answers?.whatToBuildOrImprove?.toString()) &&
										(answers?.buildingExternalCoveringMaterials ||
											answers?.buildingRoofMaterials ||
											answers?.buildingStructureMaterials) && (
											<h6 className={pageUtils.classes.h6}>
												{
													pageAssets.request_form_buildingConstructionOrAdditionPermit_materials
												}
											</h6>
										)}

									{/* Question 21 PA */}
									{shouldDisplayPAQuestion(
										answers?.whatToBuildOrImprove,
										'Q21'
									) && (
										<>
											{questionAnswer(
												Q21PABuildingExternalCoveringMaterialsInput.label,
												answers?.buildingExternalCoveringMaterials
											)}
										</>
									)}

									{/* Question 22 PA */}
									{shouldDisplayPAQuestion(
										answers?.whatToBuildOrImprove,
										'Q22'
									) && (
										<>
											{questionAnswer(
												Q22PABuildingRoofMaterialsInput.label,
												answers?.buildingRoofMaterials
											)}
										</>
									)}

									{/* Question 23 PA */}
									{shouldDisplayPAQuestion(
										answers?.whatToBuildOrImprove,
										'Q23'
									) && (
										<>
											{questionAnswer(
												Q23PABuildingStructureMaterialsInput.label,
												answers?.buildingStructureMaterials
											)}
										</>
									)}
								</>
							)}
						</Collapse>
					</>
				)}

				{subSteps?.details_5 && (
					<>
						<Collapse
							id={5}
							buttonText={pageAssets?.request_form_information_on_the_work}
							isOpen={toPrint}
							key={`request_form_information_on_the_work`}
						>
							<div style={{ position: 'relative' }}>
								{!toPrint && (
									<a
										style={{
											display: toPrint ? 'none' : 'flex'
										}}
										className={joinClasses([
											pageUtils.classes.btn,
											pageUtils.classes.button,
											pageUtils.classes.buttonOutline,
											pageUtils.classes.summaryCollapseButton
										])}
										onClick={() =>
											backToForm(
												steps?.form!,
												REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_5,
												5
											)
										}
									>
										{pageAssets?.label_modify}
									</a>
								)}

								{toPrint && (
									<h4 className={pageUtils.classes.h4}>
										{pageAssets?.request_form_information_on_the_work}
									</h4>
								)}
							</div>

							{/* Question 24 */}
							<div>
								<strong>{Q24IsWorkDueToDisasterInput.label}</strong>
								<br />
								<span className={pageUtils.classes.answerURB}>
									{getLabelFromList(
										radioListYesNo,
										answers?.isWorkDueToDisaster
									)}
								</span>
							</div>

							{answers?.isWorkDueToDisaster?.toString() ==
								radioListYesNo[0].value && (
								<>
									<div>
										<strong>
											{Q24_1WorkDueToDisasterPrecisionInput.label}
										</strong>
										<br />
										<span className={pageUtils.classes.answerURB}>
											{answers?.workDueToDisasterPrecision}
										</span>
									</div>
								</>
							)}

							{/* Question 25 */}
							<div>
								<strong>{Q25ApproximateCostOfWorkInput.label}</strong>
								<br />
								<span className={pageUtils.classes.answerURB}>
									{formatNumberWithSpaces(
										answers?.approximateCostOfWork?.toString()
									)}{' '}
									$
								</span>
							</div>

							{/* Question 26 */}
							{isNewConstruction(answers?.whatToDo) && (
								<div>
									<strong>{Q26TotalProjectValueInput.label}</strong>
									<br />
									<span className={pageUtils.classes.answerURB}>
										{formatNumberWithSpaces(
											answers?.totalProjectValue?.toString()
										)}{' '}
										$
									</span>
								</div>
							)}

							{/* Question 27 */}
							{questionAnswer(
								Q27IsTreeCuttingRequiredInput.label,
								getLabelFromList(radioListYesNo, answers?.isTreeCuttingRequired)
							)}

							{/* Question 28 */}
							{questionAnswer(
								Q28AdditionalInfoInput.label,
								answers?.additionalInfoQ28
							)}

							{/* Dates des travaux */}
							{(answers?.workStartDate || answers?.workEndDate) && (
								<h5 className={pageUtils.classes.h5}>
									{
										pageAssets?.request_form_buildingConstructionOrAdditionPermit_work_dates
									}
								</h5>
							)}

							{answers?.workStartDate && (
								<div>
									<strong>{Q29StartDateInput.label}</strong>
									<br />
									<span className={pageUtils.classes.answerURB}>
										{pageUtils.getLocaleDate(answers?.workStartDate!)}
									</span>
								</div>
							)}

							{answers?.workEndDate && (
								<div>
									<strong>{Q30EndDateInput.label}</strong>
									<br />
									<span className={pageUtils.classes.answerURB}>
										{pageUtils.getLocaleDate(answers?.workEndDate!)}
									</span>
								</div>
							)}

							<h5 className={pageUtils.classes.h5}>
								{
									pageAssets?.request_form_buildingConstructionOrAdditionPermit_entrepreneur_executing_work
								}
							</h5>

							{/* Question 31 */}
							{questionAnswer(
								Q31WhoWillBeInChargeOfWorkInput.label,
								getLabelFromList(
									radioListWhoWillBeInChargeOfWork,
									answers?.whoWillBeInChargeOfWork
								)
							)}

							{[
								radioListWhoWillBeInChargeOfWork[1].value,
								radioListWhoWillBeInChargeOfWork[2].value
							].includes(answers?.whoWillBeInChargeOfWork?.toString()!) &&
								questionAnswer(
									Q31_1EntrepreneurUnknownInput.label,
									getLabelFromList(
										radioListYesNo,
										answers?.entrepreneurUnknown1?.toString() || String(false)
									)
								)}

							{questionAnswer(
								Q32CompanyNameExecutingWorkInput.label,
								answers?.companyNameExecutingWork
							)}

							{questionAnswer(
								Q33CompanyNEQNumberInput.label,
								answers?.companyNEQNumber
							)}

							{questionAnswer(
								Q34CompanyRBQNumberInput.label,
								answers?.companyRBQNumber
							)}

							{(answers?.companyRepresentativeName ||
								answers?.companyRepresentativePhoneNumber ||
								answers?.companyRepresentativeEmail) && (
								<h6 className={pageUtils.classes.h6}>
									{
										pageAssets?.request_form_buildingConstructionOrAdditionPermit_representative_or_contact_person
									}
								</h6>
							)}

							{questionAnswer(
								Q35CompanyRepresentativeNameInput.label,
								answers?.companyRepresentativeName
							)}

							{questionAnswer(
								Q36CompanyRepresentativePhoneNumberInput.label,
								answers?.companyRepresentativePhoneNumber
							)}

							{questionAnswer(
								Q37CompanyRepresentativeEmailInput.label,
								answers?.companyRepresentativeEmail
							)}

							{(answers?.companyAddress ||
								answers?.companyCity ||
								answers?.companyPostalCode) && (
								<h6 className={pageUtils.classes.h6}>
									{
										pageAssets?.request_form_buildingConstructionOrAdditionPermit_company_address
									}
								</h6>
							)}

							{questionAnswer(
								Q38CompanyAddressInput.label,
								answers?.companyAddress
							)}

							{questionAnswer(Q39CompanyCityInput.label, answers?.companyCity)}

							{questionAnswer(
								Q40CompanyPostalCodeInput.label,
								answers?.companyPostalCode
							)}
						</Collapse>
					</>
				)}

				{subSteps?.details_6 && (
					<>
						<Collapse
							id={6}
							buttonText={pageAssets?.request_form_branching_aqueduc_egouts}
							isOpen={toPrint}
							key={`request_form_branching_aqueduc_egouts`}
						>
							<div style={{ position: 'relative' }}>
								{!toPrint && (
									<a
										style={{
											display: toPrint ? 'none' : 'flex'
										}}
										className={joinClasses([
											pageUtils.classes.btn,
											pageUtils.classes.button,
											pageUtils.classes.buttonOutline,
											pageUtils.classes.summaryCollapseButton
										])}
										onClick={() =>
											backToForm(
												steps?.form!,
												REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_6,
												6
											)
										}
									>
										{pageAssets?.label_modify}
									</a>
								)}
								{toPrint && (
									<h4 className={pageUtils.classes.h4}>
										{pageAssets?.request_form_branching_aqueduc_egouts}
									</h4>
								)}
							</div>

							{questionAnswer(
								Q41IsWaterAndSewerConnectionRequiredInput.label,
								getLabelFromList(
									radioListYesNo,
									answers?.isWaterAndSewerConnectionRequired?.toString() ||
										String(false)
								)
							)}

							{answers?.isWaterAndSewerConnectionRequired?.toString() ==
								'true' && (
								<>
									{answers?.isPropertyConnectedToMunicipalConduits && (
										<div>
											<strong>
												{Q41IsPropertyConnectedToMunicipalConduitsInput.label}
											</strong>
											<br />
											<span className={pageUtils.classes.answerURB}>
												<>
													{getLabelsFromString(
														radioListIsPropertyConnectedToMunicipalConduits,
														answers?.isPropertyConnectedToMunicipalConduits
													).map((answerLabel, index) => {
														return (
															<p
																className={pageUtils.classes.answerURB}
																key={`${index}-isPropertyConnectedToMunicipalConduits`}
															>
																{answerLabel}
															</p>
														)
													})}
												</>
											</span>
										</div>
									)}

									{questionAnswer(
										Q42AdditionalInfoInput.label,
										answers?.additionalInfoQ42
									)}

									{answers?.plannedWork && (
										<div>
											<strong>{Q43PlannedWorkInput.label}</strong>
											<br />
											<span className={pageUtils.classes.answerURB}>
												<>
													{getLabelsFromString(
														listPlannedWork,
														answers?.plannedWork
													).map((answerLabel, index) => {
														return (
															<p
																className={pageUtils.classes.answerURB}
																key={`${index}-plannedWork`}
															>
																{answerLabel}
															</p>
														)
													})}
												</>
											</span>
										</div>
									)}

									{questionAnswer(
										Q44AdditionalInfoInput.label,
										answers?.additionalInfoQ44
									)}

									{isEntrepreneurFieldsRequired(answers?.plannedWork) && (
										<>
											<h5 className={pageUtils.classes.h5}>
												{
													pageAssets?.request_form_buildingConstructionOrAdditionPermit_entrepreneur_branchement_aqueduc_egouts_ou_ingenieur_civil
												}
											</h5>

											{questionAnswer(
												Q45EntrepreneurUnknownInput.label,
												getLabelFromList(
													radioListYesNo,
													answers?.entrepreneurUnknown?.toString() ||
														String(false)
												)
											)}

											{questionAnswer(
												Q46EntrepreneurNameInput.label,
												answers?.entrepreneurName
											)}

											{questionAnswer(
												Q47EntrepreneurRBQNumberInput.label,
												answers?.entrepreneurRBQNumber
											)}

											{(answers?.entrepreneurRepresentativeName ||
												answers?.entrepreneurRepresentativePhoneNumber ||
												answers?.entrepreneurRepresentativeEmail) && (
												<h6
													className={pageUtils.classes.h6}
													style={{ marginTop: '20px' }}
												>
													{
														pageAssets?.request_form_buildingConstructionOrAdditionPermit_representative_or_contact_person
													}
												</h6>
											)}

											{questionAnswer(
												Q48EntrepreneurRepresentativeNameInput.label,
												answers?.entrepreneurRepresentativeName
											)}

											{questionAnswer(
												Q49EntrepreneurRepresentativePhoneNumberInput.label,
												answers?.entrepreneurRepresentativePhoneNumber
											)}

											{questionAnswer(
												Q50EntrepreneurRepresentativeEmailInput.label,
												answers?.entrepreneurRepresentativeEmail
											)}

											{(answers?.entrepreneurRepresentativeAddress ||
												answers?.entrepreneurRepresentativeCity ||
												answers?.entrepreneurRepresentativePostalCode) && (
												<h6
													className={pageUtils.classes.h6}
													style={{ marginTop: '20px' }}
												>
													{
														pageAssets?.request_form_buildingConstructionOrAdditionPermit_company_address
													}
												</h6>
											)}

											{questionAnswer(
												Q51EntrepreneurRepresentativeAddressInput.label,
												answers?.entrepreneurRepresentativeAddress
											)}

											{questionAnswer(
												Q52EntrepreneurRepresentativeCityInput.label,
												answers?.entrepreneurRepresentativeCity
											)}

											{questionAnswer(
												Q53EntrepreneurRepresentativePostalCodeInput.label,
												answers?.entrepreneurRepresentativePostalCode
											)}
										</>
									)}
								</>
							)}
						</Collapse>
					</>
				)}

				{subSteps?.requiredDocument && (
					<>
						<Collapse
							id={7}
							buttonText={pageAssets?.request_form_required_documents}
							isOpen={toPrint}
							key={`request_form_required_documents`}
						>
							<div style={{ position: 'relative' }}>
								{!toPrint && (
									<a
										style={{
											display: toPrint ? 'none' : 'flex'
										}}
										className={joinClasses([
											pageUtils.classes.btn,
											pageUtils.classes.button,
											pageUtils.classes.buttonOutline,
											pageUtils.classes.summaryCollapseButton
										])}
										onClick={() =>
											backToForm(
												steps?.form!,
												REQUEST_TN_DETAILS_STEPS_NAME.DOCUMENTS,
												7
											)
										}
									>
										{pageAssets?.label_modify}
									</a>
								)}
								{toPrint && (
									<h4 className={pageUtils.classes.h4}>
										{pageAssets?.request_form_required_documents}
									</h4>
								)}
							</div>

							{answers?.whatToDo?.toString() ==
								REQUEST_CONFIGS_KEYS
									.whatToDoBuildingConstructionOrAdditionPermit
									.request_new_construction && (
								<>
									{/* true stands for isSummary */}
									{renderAllPNRequestFiles('required', true)}
									{renderAllPNRequestFiles('optional', true)}
								</>
							)}

							{answers?.whatToDo?.toString() ==
								REQUEST_CONFIGS_KEYS
									.whatToDoBuildingConstructionOrAdditionPermit
									.request_addition && (
								<>
									{/* true stands for isSummary */}
									{renderAllPARequestFiles('required', true)}
									{renderAllPARequestFiles('optional', true)}
								</>
							)}
						</Collapse>
					</>
				)}
			</section>
		)
	}

	return (
		<>
			{subSteps?.position === REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_1 && (
				<ReactCollapse
					isOpened={
						subSteps !== undefined &&
						subSteps.position === REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_1
					}
				>
					<section>
						<h4 className={pageUtils.classes.h4}>
							{pageAssets?.request_form_type_de_demandes}
						</h4>

						{/* <IMaskInput
							id={Q25ApproximateCostOfWorkInput.name}
							mask={Number}
							scale={2}
							thousandsSeparator=" "
							radix="."
							mapToRadix={['.']}
							value={Q25ApproximateCostOfWorkInput.value ?? ''}
							onAccept={(value) => onSetQ25ApproximateCostOfWorkInput(value)}
							unmask={true} // true|false|'typed'
							inputRef={(el) => {
								if (el) {
									;(el as HTMLInputElement).setSelectionRange(
										el.value.length,
										el.value.length
									)
								}
							}}
							className={
								errors?.includes(Q25ApproximateCostOfWorkInput.name)
									? 'error'
									: ''
							}
						/> */}

						{/* Question 1 */}
						<RadioInput
							name={whatToDoInput.name}
							label={whatToDoInput.label}
							value={whatToDoInput.value}
							list={radioListWhatToDo}
							direction={Direction.vertical}
							hasError={errors!.includes(whatToDoInput.name)}
							onChange={onSetWhatToDoInput}
							ref={whatToDoInput.ref as RefObject<HTMLInputElement>}
							required={whatToDoInput.required}
							hasBackgroundWrapper={true}
						/>

						{/* Question 2 */}
						<RadioInput
							name={requestAsInput.name}
							label={requestAsInput.label}
							value={requestAsInput.value}
							list={radioListRequestAs}
							direction={Direction.vertical}
							hasError={errors!.includes(requestAsInput.name)}
							onChange={onSetRequestAsInput}
							ref={requestAsInput.ref as RefObject<HTMLInputElement>}
							required={requestAsInput.required}
							hasBackgroundWrapper={true}
						/>

						{requestAsInput.value ==
							REQUEST_CONFIGS_KEYS.requestAs.entreprise && (
							<>
								{/* Question 2.1 */}
								<TextInput
									id={companyNameInput.name}
									label={companyNameInput.label}
									value={companyNameInput.value}
									onChange={onSetCompanyNameInput}
									hasError={errors?.includes(companyNameInput.name)}
									required={companyNameInput.required}
									ref={companyNameInput.ref as RefObject<HTMLInputElement>}
									maxCharacters={100}
								/>

								{/* Question 2.2 */}
								<TextInput
									id={representativeNameInput.name}
									label={representativeNameInput.label}
									value={representativeNameInput.value}
									onChange={onSetRepresentativeNameInput}
									hasError={errors?.includes(representativeNameInput.name)}
									required={representativeNameInput.required}
									ref={
										representativeNameInput.ref as RefObject<HTMLInputElement>
									}
									maxCharacters={100}
								/>
							</>
						)}

						{/* Architectural implementation and integration plan (PIIA) */}
						<h5 className={pageUtils.classes.h5}>
							{
								pageAssets?.request_form_buildingConstructionOrAdditionPermit_piia_title
							}
						</h5>

						{/* Question 3 */}
						<RadioInput
							name={isSubjectToPiiaApprovalInput.name}
							label={isSubjectToPiiaApprovalInput.label}
							value={isSubjectToPiiaApprovalInput.value}
							list={radioListYesNo}
							direction={Direction.vertical}
							hasError={errors!.includes(isSubjectToPiiaApprovalInput.name)}
							onChange={onSetIsSubjectToPiiaApprovalInput}
							ref={
								isSubjectToPiiaApprovalInput.ref as RefObject<HTMLInputElement>
							}
							required={isSubjectToPiiaApprovalInput.required}
							hasBackgroundWrapper={true}
							classWrapper={classes.reducedMarginBottom}
							description={
								pageAssets?.request_form_buildingConstructionOrAdditionPermit_isSubjectToPiiaApproval_description
							}
						/>

						{isSubjectToPiiaApprovalInput.value == radioListYesNo[0].value && (
							<>
								{/* Question 4 */}
								<RadioInput
									name={hasBeenSubjectToApprovalInput.name}
									label={hasBeenSubjectToApprovalInput.label}
									value={hasBeenSubjectToApprovalInput.value}
									list={radioListYesNo}
									direction={Direction.vertical}
									hasError={errors!.includes(
										hasBeenSubjectToApprovalInput.name
									)}
									onChange={onSetHasBeenSubjectToApprovalInput}
									ref={
										hasBeenSubjectToApprovalInput.ref as RefObject<HTMLInputElement>
									}
									required={hasBeenSubjectToApprovalInput.required}
									hasBackgroundWrapper={true}
									classWrapper={classes.reducedMarginBottom}
								/>
								{hasBeenSubjectToApprovalInput.value ==
									radioListYesNo[0].value && (
									<>
										<MaskedTextInput
											id={iaNumberInput.name}
											label={iaNumberInput.label}
											value={iaNumberInput.value}
											onChange={onSetIaNumberInput}
											hasError={errors.includes(iaNumberInput.name)}
											required={iaNumberInput.required}
											ref={iaNumberInput.ref as RefObject<HTMLInputElement>}
											max={12}
											min={12}
											mask="IA-0000-0000"
											placeholder="IA-AAAA-XXXX"
											errors={errors}
											setError={onFixError}
											schema={/^IA-\d{4}-\d{4}$/}
										/>

										<MaskedTextInput
											id={ecResolutionNumberInput.name}
											label={ecResolutionNumberInput.label}
											value={ecResolutionNumberInput.value}
											onChange={onSetEcResolutionNumberInput}
											hasError={errors.includes(ecResolutionNumberInput.name)}
											required={ecResolutionNumberInput.required}
											ref={
												ecResolutionNumberInput.ref as RefObject<HTMLInputElement>
											}
											max={16}
											min={16}
											mask="CE-00000000-0000"
											placeholder="CE-AAAAMMJJ-XXXX"
											errors={errors}
											setError={onFixError}
											schema={/^CE-\d{8}-\d{4}$/}
										/>
									</>
								)}
							</>
						)}

						{/* Support for promoters */}
						<h5 className={pageUtils.classes.h5}>
							{
								pageAssets?.request_form_buildingConstructionOrAdditionPermit_supportForPromoters_title
							}
						</h5>

						{/* Question 5 */}
						<RadioInput
							name={isRealEstateProjectInput.name}
							label={isRealEstateProjectInput.label}
							value={isRealEstateProjectInput.value}
							list={radioListYesNo}
							direction={Direction.vertical}
							hasError={errors!.includes(isRealEstateProjectInput.name)}
							onChange={onSetIsRealEstateProjectInput}
							ref={isRealEstateProjectInput.ref as RefObject<HTMLInputElement>}
							required={isRealEstateProjectInput.required}
							hasBackgroundWrapper={true}
							classWrapper={classes.reducedMarginBottom}
							description={isRealEstateProjectInput.description}
						/>

						{isRealEstateProjectInput.value == radioListYesNo[0].value && (
							<>
								<MaskedTextInput
									id={projectNumberInput.name}
									label={projectNumberInput.label}
									value={projectNumberInput.value}
									onChange={onSetProjectNumberInput}
									hasError={errors.includes(projectNumberInput.name)}
									required={projectNumberInput.required}
									ref={projectNumberInput.ref as RefObject<HTMLInputElement>}
									max={12}
									min={12}
									mask="PD-0000-0000"
									placeholder="PD-AAAA-XXXX"
									errors={errors}
									setError={onFixError}
									schema={/^PD-\d{4}-\d{4}$/}
								/>
							</>
						)}
					</section>
				</ReactCollapse>
			)}

			{subSteps?.position === REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_2 && (
				<ReactCollapse
					isOpened={
						subSteps !== undefined &&
						subSteps.position === REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_2
					}
				>
					<section>
						<h4 className={pageUtils.classes.h4}>
							{pageAssets?.request_form_request_location}
						</h4>

						{isAddition(whatToDoInput.value) && (
							<div className={classes.adresseBlock}>
								<div className={pageUtils.classes.fieldsContainer}>
									<div>
										<strong>{pageAssets?.label_address}</strong>
										<br />
										{authUser?.profile?.address.address}
										<br />
										{authUser?.profile?.address.city}
										{authUser?.profile?.address.state
											? ` (${authUser?.profile?.address.state}) `
											: ' '}
										{authUser?.profile?.address.postalCode}
										<br />
										{authUser?.profile?.address.apartment
											? `${formatStrapiText(
													pageAssets?.label_apartment_office
											  )} ${authUser?.profile?.address.apartment}`
											: ''}
									</div>

									{buildingBatchNumberInput.value && (
										<div>
											<strong>{pageAssets?.lot_number_label}</strong>
											<br />
											{buildingBatchNumberInput.value}
										</div>
									)}
								</div>
							</div>
						)}

						{/* Question 7 */}
						{isAddition(whatToDoInput.value) && (
							<RadioInput
								name={isSameLocationInput.name}
								label={isSameLocationInput.label}
								value={isSameLocationInput.value}
								list={radioListYesNo}
								direction={Direction.vertical}
								hasError={errors!.includes(isSameLocationInput.name)}
								onChange={onSetIsSameLocationInput}
								ref={isSameLocationInput.ref as RefObject<HTMLInputElement>}
								required={isSameLocationInput.required}
								hasBackgroundWrapper={true}
								classWrapper={classes.reducedMarginBottom}
							/>
						)}

						{/* Question 8 */}
						{showAdresseBlock == true && (
							<>
								<AddressVoiePublicSection
									label={pageAssets?.request_form_location}
									errors={errors}
									inputs={inputs}
									onFixError={onFixError}
									onSetInputs={onSetInputs}
									isConcernedInfosRequired={
										isSameLocationInput.value == radioListYesNo[1].value ||
										isNewConstruction(whatToDoInput.value)
									}
									showButtonUserLocation={false}
									displayAddressDetails={true}
									requiredFields={[
										'addressOption',
										'addressField',
										'addressLotNumber'
									]}
									subStep={REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_2}
									addressOptions={
										whatToDoInput.value ===
										REQUEST_CONFIGS_KEYS
											.whatToDoBuildingConstructionOrAdditionPermit
											.request_new_construction
											? radioListAddressOption.slice(0, 1)
											: radioListAddressOption
									}
								/>

								{/* If your construction is new and you do not yet have a valid address, please enter a lot number instead. */}
								{/* <Notification
									text={
										pageAssets?.request_form_if_not_valid_address_notification_label
									}
									type="info"
									hasHtml
								/> */}
								<br />
							</>
						)}

						{isSameLocationInput.value == radioListYesNo[0].value &&
							isAddressOutsideLaval && (
								<Notification
									text={
										pageAssets?.request_form_isAddressOutsideLavalMessageShow_notification_label
									}
									type="warning"
									hasHtml
								/>
							)}

						{/* Question 9 */}
						<RadioInput
							name={isLocationOwnedInput.name}
							label={isLocationOwnedInput.label}
							value={isLocationOwnedInput.value}
							list={radioListYesNo}
							direction={Direction.vertical}
							hasError={errors!.includes(isLocationOwnedInput.name)}
							onChange={onSetIsLocationOwnedInput}
							ref={isLocationOwnedInput.ref as RefObject<HTMLInputElement>}
							required={isLocationOwnedInput.required}
							hasBackgroundWrapper={true}
							classWrapper={classes.reducedMarginBottom}
						/>

						{/* ****** Owner inputs ****** */}
						{isLocationOwnedInput.value == radioListYesNo[1].value && (
							<>
								<Notification
									text={
										pageAssets?.request_form_ownerPowerOfAttorney_notification
									}
									type="info"
									hasHtml
								/>
								<div
									className={joinClasses([
										pageUtils.classes.fieldsContainer,
										classes.greyBackgroundBlock
									])}
								>
									<TextInput
										id={ownerFirstNameInput.name}
										label={ownerFirstNameInput.label}
										value={ownerFirstNameInput.value}
										onChange={onSetOwnerFirstNameInput}
										hasError={errors.includes(ownerFirstNameInput.name)}
										required={ownerFirstNameInput.required}
										ref={ownerFirstNameInput.ref as RefObject<HTMLInputElement>}
										classWrapper={pageUtils?.classes.textFieldContainer}
										maxCharacters={50}
									/>
									<TextInput
										id={ownerLastNameInput.name}
										label={ownerLastNameInput.label}
										value={ownerLastNameInput.value}
										onChange={onSetOwnerLastNameInput}
										hasError={errors.includes(ownerLastNameInput.name)}
										required={ownerLastNameInput.required}
										ref={ownerLastNameInput.ref as RefObject<HTMLInputElement>}
										classWrapper={pageUtils?.classes.textFieldContainer}
										maxCharacters={50}
									/>

									<TextInput
										id={ownerEmailInput.name}
										label={ownerEmailInput.label}
										value={ownerEmailInput.value}
										onChange={onSetOwnerEmailInput}
										hasError={errors?.includes(ownerEmailInput.name)}
										type="email"
										onSetError={onFixError}
										schema={formUtils.MASKS.email}
										schemaErrorText={pageAssets?.label_emailErrorText}
										required={ownerEmailInput.required}
										ref={ownerEmailInput.ref as RefObject<HTMLInputElement>}
										maxCharacters={100}
									/>
									<MaskedTextInput
										id={ownerPhoneNumberInput.name}
										label={ownerPhoneNumberInput.label}
										value={ownerPhoneNumberInput.value}
										onChange={onSetOwnerPhoneNumberInput}
										hasError={errors?.includes(ownerPhoneNumberInput.name)}
										type="tel"
										min={10}
										max={14}
										mask={formUtils.MASKS.phoneNumber}
										placeholder={formUtils.MASKS.phoneNumber}
										errors={errors ? errors : []}
										setError={onFixError}
										schema={PHONE_NUMBER_REGEX}
										required={ownerPhoneNumberInput.required}
										ref={
											ownerPhoneNumberInput.ref as RefObject<HTMLInputElement>
										}
									/>
								</div>

								<RequestFiles
									subtitle={firstQuestion.label}
									name={firstQuestion.name}
									required
									files={firstQuestion.files}
									hasError={errors.includes(firstQuestion.name)}
									removeSectionMarginTop
									removePadding
									equivalentMargin
									onSetHasError={onSetErrorFromFileComponent}
									onSetFiles={onSelectFileFirstQuestion}
									subSteps={subSteps}
									maxFiles={firstQuestion.numberMaxFiles}
									isNewDesign
								></RequestFiles>
								<br />
							</>
						)}
					</section>
				</ReactCollapse>
			)}

			{subSteps?.position === REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_3 && (
				<ReactCollapse
					isOpened={
						subSteps !== undefined &&
						subSteps.position === REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_3
					}
				>
					<section>
						<h4 className={pageUtils.classes.h4}>
							{pageAssets?.request_form_type_of_use}
						</h4>

						{/* New construction */}
						{isNewConstruction(whatToDoInput.value) && (
							<>
								{/* Question 10 */}
								<RadioInput
									name={mainUseOfNewBuildingInput.name}
									label={mainUseOfNewBuildingInput.label}
									value={mainUseOfNewBuildingInput.value}
									list={radioListMainUseOfNewBuilding}
									direction={Direction.vertical}
									hasError={errors!.includes(mainUseOfNewBuildingInput.name)}
									onChange={onSetMainUseOfNewBuildingInput}
									ref={
										mainUseOfNewBuildingInput.ref as RefObject<HTMLInputElement>
									}
									required={mainUseOfNewBuildingInput.required}
									hasBackgroundWrapper={true}
								/>

								{/* Question 11 */}
								<RadioInput
									name={isTheNewBuildingInput.name}
									label={isTheNewBuildingInput.label}
									value={isTheNewBuildingInput.value}
									list={radioListIsTheNewBuilding}
									direction={Direction.vertical}
									hasError={errors!.includes(isTheNewBuildingInput.name)}
									onChange={onSetIsTheNewBuildingInput}
									ref={isTheNewBuildingInput.ref as RefObject<HTMLInputElement>}
									required={isTheNewBuildingInput.required}
									hasBackgroundWrapper={true}
								/>

								{/* Question 12 */}
								<RadioInput
									name={isTheNewBuildingPartOfAPropertyInput.name}
									label={isTheNewBuildingPartOfAPropertyInput.label}
									value={isTheNewBuildingPartOfAPropertyInput.value}
									list={radioListYesNo}
									direction={Direction.vertical}
									hasError={errors!.includes(
										isTheNewBuildingPartOfAPropertyInput.name
									)}
									onChange={onSetIsTheNewBuildingPartOfAPropertyInput}
									ref={
										isTheNewBuildingPartOfAPropertyInput.ref as RefObject<HTMLInputElement>
									}
									required={isTheNewBuildingPartOfAPropertyInput.required}
									hasBackgroundWrapper={true}
								/>
							</>
						)}

						{/* Addition */}
						{isAddition(whatToDoInput.value) && (
							<>
								{/* Question 10 */}
								<RadioInput
									name={Q10PAMainUseOfExistingBuildingInput.name}
									label={Q10PAMainUseOfExistingBuildingInput.label}
									value={Q10PAMainUseOfExistingBuildingInput.value}
									list={radioListMainUseOfExistingBuilding}
									direction={Direction.vertical}
									hasError={errors!.includes(
										Q10PAMainUseOfExistingBuildingInput.name
									)}
									onChange={onSetQ10PAMainUseOfExistingBuildingInput}
									ref={
										Q10PAMainUseOfExistingBuildingInput.ref as RefObject<HTMLInputElement>
									}
									required={Q10PAMainUseOfExistingBuildingInput.required}
									hasBackgroundWrapper={true}
								/>

								{/* Question 10.1 */}
								{Q10PAMainUseOfExistingBuildingInput.value ==
									REQUEST_CONFIGS_KEYS.mainUseOfExistingBuilding
										.secondOption && (
									<RadioInput
										name={Q10_1PAExistingMainBuildingHasHousingInput.name}
										label={Q10_1PAExistingMainBuildingHasHousingInput.label}
										value={Q10_1PAExistingMainBuildingHasHousingInput.value}
										list={radioListYesNo}
										direction={Direction.vertical}
										hasError={errors!.includes(
											Q10_1PAExistingMainBuildingHasHousingInput.name
										)}
										onChange={onSetQ10_1PAExistingMainBuildingHasHousingInput}
										ref={
											Q10_1PAExistingMainBuildingHasHousingInput.ref as RefObject<HTMLInputElement>
										}
										required={
											Q10_1PAExistingMainBuildingHasHousingInput.required
										}
										hasBackgroundWrapper={true}
									/>
								)}

								{/* Question 11 */}
								<RadioInput
									name={Q11PAIsTheNewBuildingPartOfAPropertyInput.name}
									label={Q11PAIsTheNewBuildingPartOfAPropertyInput.label}
									value={Q11PAIsTheNewBuildingPartOfAPropertyInput.value}
									list={radioListYesNo}
									direction={Direction.vertical}
									hasError={errors!.includes(
										Q11PAIsTheNewBuildingPartOfAPropertyInput.name
									)}
									onChange={onSetQ11PAIsTheNewBuildingPartOfAPropertyInput}
									ref={
										Q11PAIsTheNewBuildingPartOfAPropertyInput.ref as RefObject<HTMLInputElement>
									}
									required={Q11PAIsTheNewBuildingPartOfAPropertyInput.required}
									hasBackgroundWrapper={true}
								/>
							</>
						)}
					</section>
				</ReactCollapse>
			)}

			{subSteps?.position === REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_4 && (
				<ReactCollapse
					isOpened={
						subSteps !== undefined &&
						subSteps.position === REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_4
					}
				>
					<section>
						<h4 className={pageUtils.classes.h4}>
							{pageAssets?.request_form_building_informations}
						</h4>

						{/* New construction part PN */}
						{isNewConstruction(whatToDoInput.value) && (
							<>
								{/* Question 13 */}
								<RadioInput
									name={structureTypeInput.name}
									label={structureTypeInput.label}
									value={structureTypeInput.value}
									list={radioListStructureType}
									direction={Direction.vertical}
									hasError={errors!.includes(structureTypeInput.name)}
									onChange={onSetStructureTypeInput}
									ref={structureTypeInput.ref as RefObject<HTMLInputElement>}
									required={structureTypeInput.required}
									hasBackgroundWrapper={true}
								/>

								<h5 className={pageUtils.classes.h5}>
									{
										pageAssets?.request_form_buildingConstructionOrAdditionPermit_caracteristics
									}
								</h5>

								<div className={pageUtils.classes.fieldsContainer}>
									{/* Question 14 */}
									{shouldDisplayQuestions(mainUseOfNewBuildingInput.value)
										?.Q14 && (
										<>
											{renderMeasurementInput(
												Q14BuildingAreaInput,
												onSetQ14BuildingAreaInput,
												Q14BuildingAreaUnitInput,
												onSetQ14BuildingAreaUnitInput,
												radioListMeasurement2Unit,
												12,
												true
											)}
										</>
									)}

									{/* Question 15 */}
									{shouldDisplayQuestions(mainUseOfNewBuildingInput.value)
										?.Q15 && (
										<>
											{renderMeasurementInput(
												Q15TotalGrossFloorAreaInput,
												onSetQ15TotalGrossFloorAreaInput,
												Q15TotalGrossFloorAreaUnitInput,
												onSetQ15TotalGrossFloorAreaUnitInput,
												radioListMeasurement2Unit,
												12,
												true
											)}
										</>
									)}
								</div>

								{shouldDisplayQuestions(mainUseOfNewBuildingInput.value)
									?.Q16_1 && (
									<>
										<RadioInput
											name={Q16_1IsDeclaredAreaForResidentialUseInput.name}
											label={Q16_1IsDeclaredAreaForResidentialUseInput.label}
											value={Q16_1IsDeclaredAreaForResidentialUseInput.value}
											list={radioListYesNo}
											direction={Direction.vertical}
											hasError={errors!.includes(
												Q16_1IsDeclaredAreaForResidentialUseInput.name
											)}
											required={
												Q16_1IsDeclaredAreaForResidentialUseInput.required
											}
											ref={
												Q16_1IsDeclaredAreaForResidentialUseInput.ref as RefObject<HTMLInputElement>
											}
											hasBackgroundWrapper={true}
											onChange={onSetQ16_1IsDeclaredAreaForResidentialUseInput}
											classWrapper={classes.reducedMarginBottom}
										/>

										{Q16_1IsDeclaredAreaForResidentialUseInput.value ==
											radioListYesNo[1].value && (
											<>
												{/* <div className={pageUtils.classes.fieldsContainer}> */}
												{renderMeasurementInput(
													Q16_2NonResidentialAreasInput,
													onSetQ16_2NonResidentialAreasInput,
													Q16_2NonResidentialAreasUnitInput,
													onSetQ16_2NonResidentialAreasUnitInput,
													radioListMeasurement2Unit,
													10
												)}

												{/* </div> */}
											</>
										)}
									</>
								)}

								<div className={pageUtils.classes.fieldsContainer}>
									{shouldDisplayQuestions(mainUseOfNewBuildingInput.value)
										?.Q17 && (
										<>
											<TextInput
												id={Q17NumberOfFloorsInput.name}
												label={Q17NumberOfFloorsInput.label}
												value={Q17NumberOfFloorsInput.value}
												onChange={onSetQ17NumberOfFloorsInput}
												hasError={errors!.includes(Q17NumberOfFloorsInput.name)}
												required={Q17NumberOfFloorsInput.required}
												ref={
													Q17NumberOfFloorsInput.ref as RefObject<HTMLInputElement>
												}
												maxCharacters={3}
												type="number"
												min="0"
												onKeyPress={pageUtils.onKeyPressIntegerInput}
											/>
										</>
									)}

									{shouldDisplayQuestions(mainUseOfNewBuildingInput.value)
										?.Q18 && (
										<>
											<TextInput
												id={Q18NumberOfResidentialUnitsInput.name}
												label={Q18NumberOfResidentialUnitsInput.label}
												value={Q18NumberOfResidentialUnitsInput.value}
												onChange={onSetQ18NumberOfResidentialUnitsInput}
												hasError={errors!.includes(
													Q18NumberOfResidentialUnitsInput.name
												)}
												required={Q18NumberOfResidentialUnitsInput.required}
												ref={
													Q18NumberOfResidentialUnitsInput.ref as RefObject<HTMLInputElement>
												}
												maxCharacters={5}
												type="number"
												min="0"
												onKeyPress={pageUtils.onKeyPressIntegerInput}
											/>
										</>
									)}
								</div>

								{/* Garage et stationnement */}
								<h5 className={pageUtils.classes.h5}>
									{
										pageAssets?.request_form_buildingConstructionOrAdditionPermit_garage_parking
									}
								</h5>

								<RadioInput
									name={Q19HasGarageInput.name}
									label={Q19HasGarageInput.label}
									value={Q19HasGarageInput.value}
									list={radioListYesNo}
									direction={Direction.vertical}
									hasError={errors!.includes(Q19HasGarageInput.name)}
									required={Q19HasGarageInput.required}
									ref={Q19HasGarageInput.ref as RefObject<HTMLInputElement>}
									hasBackgroundWrapper={true}
									onChange={onSetQ19HasGarageInput}
									classWrapper={classes.reducedMarginBottom}
								/>

								{Q19HasGarageInput.value == radioListYesNo[0].value && (
									<>
										<RadioInput
											name={Q19_1GarageLocationInput.name}
											label={Q19_1GarageLocationInput.label}
											value={Q19_1GarageLocationInput.value}
											list={radioListGarageLocation}
											direction={Direction.vertical}
											hasError={errors!.includes(Q19_1GarageLocationInput.name)}
											required={Q19_1GarageLocationInput.required}
											ref={
												Q19_1GarageLocationInput.ref as RefObject<HTMLInputElement>
											}
											hasBackgroundWrapper={true}
											onChange={onSetQ19_1GarageLocationInput}
											// classWrapper={classes.reducedMarginBottom}
										/>
									</>
								)}

								<br />
								{/* Veuillez saisir au moins une valeur parmi les 4 champs suivants. */}
								<Text
									content={
										pageAssets.request_form_buildingConstructionOrAdditionPermit_garage_parking_note
									}
								/>

								<br />

								<div className={pageUtils.classes.fieldsContainer}>
									<TextInput
										id={Q20NbrOfParkingSpacesExtInput.name}
										label={Q20NbrOfParkingSpacesExtInput.label}
										value={Q20NbrOfParkingSpacesExtInput.value}
										onChange={onSetQ20NbrOfParkingSpacesExtInput}
										hasError={errors!.includes(
											Q20NbrOfParkingSpacesExtInput.name
										)}
										required={Q20NbrOfParkingSpacesExtInput.required}
										ref={
											Q20NbrOfParkingSpacesExtInput.ref as RefObject<HTMLInputElement>
										}
										maxCharacters={4}
										type="number"
										hasHtmlLabel
										onKeyPress={pageUtils.onKeyPressIntegerInput}
										min="0"
									/>

									<TextInput
										id={Q21NbrOfParkingSpacesIntInput.name}
										label={Q21NbrOfParkingSpacesIntInput.label}
										value={Q21NbrOfParkingSpacesIntInput.value}
										onChange={onSetQ21NbrOfParkingSpacesIntInput}
										hasError={errors!.includes(
											Q21NbrOfParkingSpacesIntInput.name
										)}
										required={Q21NbrOfParkingSpacesIntInput.required}
										ref={
											Q21NbrOfParkingSpacesIntInput.ref as RefObject<HTMLInputElement>
										}
										maxCharacters={4}
										type="number"
										hasHtmlLabel
										onKeyPress={pageUtils.onKeyPressIntegerInput}
										min="0"
									/>

									<TextInput
										id={Q22NbrOfParkingSpacesVisInput.name}
										label={Q22NbrOfParkingSpacesVisInput.label}
										value={Q22NbrOfParkingSpacesVisInput.value}
										onChange={onSetQ22NbrOfParkingSpacesVisInput}
										hasError={errors!.includes(
											Q22NbrOfParkingSpacesVisInput.name
										)}
										required={Q22NbrOfParkingSpacesVisInput.required}
										ref={
											Q22NbrOfParkingSpacesVisInput.ref as RefObject<HTMLInputElement>
										}
										maxCharacters={4}
										type="number"
										hasHtmlLabel
										onKeyPress={pageUtils.onKeyPressIntegerInput}
										min="0"
									/>

									<TextInput
										id={Q23NbrOfBikeSpacesInput.name}
										label={Q23NbrOfBikeSpacesInput.label}
										value={Q23NbrOfBikeSpacesInput.value}
										onChange={onSetQ23NbrOfBikeSpacesInput}
										hasError={errors!.includes(Q23NbrOfBikeSpacesInput.name)}
										required={Q23NbrOfBikeSpacesInput.required}
										ref={
											Q23NbrOfBikeSpacesInput.ref as RefObject<HTMLInputElement>
										}
										maxCharacters={4}
										type="number"
										hasHtmlLabel
										onKeyPress={pageUtils.onKeyPressIntegerInput}
										min="0"
									/>
								</div>
							</>
						)}

						{/* Addition part PA */}
						{isAddition(whatToDoInput.value) && (
							<>
								{/* Question 12 PA */}
								<RadioInput
									name={Q12PAWhatToBuildOrImproveInput.name}
									label={Q12PAWhatToBuildOrImproveInput.label}
									value={Q12PAWhatToBuildOrImproveInput.value}
									list={getOptionsWhatToBuildOrImprove(
										Q10PAMainUseOfExistingBuildingInput.value,
										Q10_1PAExistingMainBuildingHasHousingInput.value
									)}
									direction={Direction.vertical}
									hasError={errors!.includes(
										Q12PAWhatToBuildOrImproveInput.name
									)}
									onChange={onSetQ12PAWhatToBuildOrImproveInput}
									ref={
										Q12PAWhatToBuildOrImproveInput.ref as RefObject<HTMLInputElement>
									}
									required={Q12PAWhatToBuildOrImproveInput.required}
									hasBackgroundWrapper={true}
								/>

								{Q12PAWhatToBuildOrImproveInput.value &&
									Q12PAWhatToBuildOrImproveInput.value !=
										REQUEST_CONFIGS_KEYS.whatToBuildOrImprove.fifthOption && (
										<h5 className={pageUtils.classes.h5}>
											{radioListWhatToBuildOrImprove
												.find(
													(option) =>
														option.value == Q12PAWhatToBuildOrImproveInput.value
												)
												?.label?.replace(/\*\*/g, '')}
										</h5>
									)}

								{/* Question 13 PA */}
								{shouldDisplayPAQuestion(
									Q12PAWhatToBuildOrImproveInput.value,
									'Q13'
								) && (
									<SelectInput
										id={Q13PAConstructionTypeInput.name}
										label={Q13PAConstructionTypeInput.label}
										value={Q13PAConstructionTypeInput.value}
										list={radioListConstructionType}
										hasError={errors!.includes(Q13PAConstructionTypeInput.name)}
										onChange={onSetQ13PAConstructionTypeInput}
										ref={
											Q13PAConstructionTypeInput.ref as RefObject<HTMLSelectElement>
										}
										required={Q13PAConstructionTypeInput.required}
									/>
								)}

								{/* Question 14 PA */}
								{shouldDisplayPAQuestion(
									Q12PAWhatToBuildOrImproveInput.value,
									'Q14'
								) && (
									<TextInput
										id={Q14PAConstructionTypeOtherInput.name}
										label={Q14PAConstructionTypeOtherInput.label}
										value={Q14PAConstructionTypeOtherInput.value}
										onChange={onSetQ14PAConstructionTypeOtherInput}
										hasError={errors!.includes(
											Q14PAConstructionTypeOtherInput.name
										)}
										required={Q14PAConstructionTypeOtherInput.required}
										ref={
											Q14PAConstructionTypeOtherInput.ref as RefObject<HTMLInputElement>
										}
									/>
								)}

								{/* Question 15 PA */}
								{shouldDisplayPAQuestion(
									Q12PAWhatToBuildOrImproveInput.value,
									'Q15'
								) && (
									<SelectInput
										id={Q15PAFoundationTypeInput.name}
										label={Q15PAFoundationTypeInput.label}
										value={Q15PAFoundationTypeInput.value}
										list={radioListFoundationType}
										hasError={errors!.includes(Q15PAFoundationTypeInput.name)}
										onChange={onSetQ15PAFoundationTypeInput}
										ref={
											Q15PAFoundationTypeInput.ref as RefObject<HTMLSelectElement>
										}
										required={Q15PAFoundationTypeInput.required}
									/>
								)}

								{/* Question 16 PA */}
								{shouldDisplayPAQuestion(
									Q12PAWhatToBuildOrImproveInput.value,
									'Q16'
								) && (
									<SelectInput
										id={Q16PAPlaceOnTheTerrainInput.name}
										label={Q16PAPlaceOnTheTerrainInput.label}
										value={Q16PAPlaceOnTheTerrainInput.value}
										list={radioListPlaceOnTheTerrain}
										hasError={errors!.includes(
											Q16PAPlaceOnTheTerrainInput.name
										)}
										onChange={onSetQ16PAPlaceOnTheTerrainInput}
										ref={
											Q16PAPlaceOnTheTerrainInput.ref as RefObject<HTMLSelectElement>
										}
										required={Q16PAPlaceOnTheTerrainInput.required}
									/>
								)}

								{Q12PAWhatToBuildOrImproveInput.value &&
									Q12PAWhatToBuildOrImproveInput.value !=
										REQUEST_CONFIGS_KEYS.whatToBuildOrImprove.fifthOption && (
										<h6
											className={pageUtils.classes.h6}
											style={{ marginTop: '20px' }}
										>
											{
												pageAssets.request_form_buildingConstructionOrAdditionPermit_dimensions
											}
										</h6>
									)}
								<div className={pageUtils.classes.fieldsContainer}>
									{/* Question 17 PA */}
									{shouldDisplayPAQuestion(
										Q12PAWhatToBuildOrImproveInput.value,
										'Q17'
									) &&
										renderMeasurementInput(
											Q17PABuildingWidthInput,
											onSetQ17PABuildingWidthInput,
											Q17PABuildingWidthUnitInput,
											onSetQ17PABuildingWidthUnitInput,
											radioListMeasurementUnit,
											10
										)}

									{/* Question 18 PA */}
									{shouldDisplayPAQuestion(
										Q12PAWhatToBuildOrImproveInput.value,
										'Q18'
									) &&
										renderMeasurementInput(
											Q18PABuildingLengthInput,
											onSetQ18PABuildingLengthInput,
											Q18PABuildingLengthUnitInput,
											onSetQ18PABuildingLengthUnitInput,
											radioListMeasurementUnit,
											10
										)}

									{/* Question 19 PA */}
									{shouldDisplayPAQuestion(
										Q12PAWhatToBuildOrImproveInput.value,
										'Q19'
									) &&
										renderMeasurementInput(
											Q19PABuildingHeightInput,
											onSetQ19PABuildingHeightInput,
											Q19PABuildingHeightUnitInput,
											onSetQ19PABuildingHeightUnitInput,
											radioListMeasurementUnit,
											10
										)}

									{/* Question 20 PA */}
									{shouldDisplayPAQuestion(
										Q12PAWhatToBuildOrImproveInput.value,
										'Q20'
									) &&
										renderMeasurementInput(
											Q20PABuildingSurfaceAreaInput,
											onSetQ20PABuildingSurfaceAreaInput,
											Q20PABuildingSurfaceAreaUnitInput,
											onSetQ20PABuildingSurfaceAreaUnitInput,
											radioListMeasurement2Unit,
											10
										)}
								</div>
								{Q12PAWhatToBuildOrImproveInput.value &&
									![
										REQUEST_CONFIGS_KEYS.whatToBuildOrImprove.fifthOption,
										REQUEST_CONFIGS_KEYS.whatToBuildOrImprove.fourthOption
									].includes(Q12PAWhatToBuildOrImproveInput.value) && (
										<h6
											className={pageUtils.classes.h6}
											style={{ marginTop: '20px' }}
										>
											{
												pageAssets.request_form_buildingConstructionOrAdditionPermit_materials
											}
										</h6>
									)}

								{/* Question 21 PA */}
								{shouldDisplayPAQuestion(
									Q12PAWhatToBuildOrImproveInput.value,
									'Q21'
								) && (
									<TextInput
										id={Q21PABuildingExternalCoveringMaterialsInput.name}
										label={Q21PABuildingExternalCoveringMaterialsInput.label}
										value={Q21PABuildingExternalCoveringMaterialsInput.value}
										onChange={onSetQ21PABuildingExternalCoveringMaterialsInput}
										hasError={errors!.includes(
											Q21PABuildingExternalCoveringMaterialsInput.name
										)}
										required={
											Q21PABuildingExternalCoveringMaterialsInput.required
										}
										ref={
											Q14PAConstructionTypeOtherInput.ref as RefObject<HTMLInputElement>
										}
									/>
								)}

								{/* Question 22 PA */}
								{shouldDisplayPAQuestion(
									Q12PAWhatToBuildOrImproveInput.value,
									'Q22'
								) && (
									<TextInput
										id={Q22PABuildingRoofMaterialsInput.name}
										label={Q22PABuildingRoofMaterialsInput.label}
										value={Q22PABuildingRoofMaterialsInput.value}
										onChange={onSetQ22PABuildingRoofMaterialsInput}
										hasError={errors!.includes(
											Q22PABuildingRoofMaterialsInput.name
										)}
										required={Q22PABuildingRoofMaterialsInput.required}
										ref={
											Q22PABuildingRoofMaterialsInput.ref as RefObject<HTMLInputElement>
										}
									/>
								)}

								{/* Question 23 PA */}
								{shouldDisplayPAQuestion(
									Q12PAWhatToBuildOrImproveInput.value,
									'Q23'
								) && (
									<TextInput
										id={Q23PABuildingStructureMaterialsInput.name}
										label={Q23PABuildingStructureMaterialsInput.label}
										value={Q23PABuildingStructureMaterialsInput.value}
										onChange={onSetQ23PABuildingStructureMaterialsInput}
										hasError={errors!.includes(
											Q23PABuildingStructureMaterialsInput.name
										)}
										required={Q23PABuildingStructureMaterialsInput.required}
										ref={
											Q23PABuildingStructureMaterialsInput.ref as RefObject<HTMLInputElement>
										}
									/>
								)}
							</>
						)}
					</section>
				</ReactCollapse>
			)}

			{subSteps?.position === REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_5 && (
				<ReactCollapse
					isOpened={
						subSteps !== undefined &&
						subSteps.position === REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_5
					}
				>
					<section>
						<h4 className={pageUtils.classes.h4}>
							{pageAssets?.request_form_information_on_the_work}
						</h4>

						<RadioInput
							name={Q24IsWorkDueToDisasterInput.name}
							label={Q24IsWorkDueToDisasterInput.label}
							value={Q24IsWorkDueToDisasterInput.value}
							list={radioListYesNo}
							direction={Direction.vertical}
							hasError={errors!.includes(Q24IsWorkDueToDisasterInput.name)}
							required={Q24IsWorkDueToDisasterInput.required}
							ref={
								Q24IsWorkDueToDisasterInput.ref as RefObject<HTMLInputElement>
							}
							hasBackgroundWrapper={true}
							onChange={onSetQ24IsWorkDueToDisasterInput}
							classWrapper={classes.reducedMarginBottom}
						/>

						{Q24IsWorkDueToDisasterInput.value == radioListYesNo[0].value && (
							<>
								<TextInput
									id={Q24_1WorkDueToDisasterPrecisionInput.name}
									label={Q24_1WorkDueToDisasterPrecisionInput.label}
									value={Q24_1WorkDueToDisasterPrecisionInput.value}
									onChange={onSetQ24_1WorkDueToDisasterPrecisionInput}
									hasError={errors!.includes(
										Q24_1WorkDueToDisasterPrecisionInput.name
									)}
									required={Q24_1WorkDueToDisasterPrecisionInput.required}
									ref={
										Q24_1WorkDueToDisasterPrecisionInput.ref as RefObject<HTMLInputElement>
									}
									maxCharacters={100}
								/>
							</>
						)}

						{/* <TextInput
							id={Q25ApproximateCostOfWorkInput.name}
							label={Q25ApproximateCostOfWorkInput.label}
							value={Q25ApproximateCostOfWorkInput.value}
							onChange={onSetQ25ApproximateCostOfWorkInput}
							hasError={errors!.includes(Q25ApproximateCostOfWorkInput.name)}
							required={Q25ApproximateCostOfWorkInput.required}
							ref={
								Q25ApproximateCostOfWorkInput.ref as RefObject<HTMLInputElement>
							}
							maxCharacters={50}
							type="number"
							isMoneyInput
						/> */}

						<MaskedThousandsSeparatorInput
							id={Q25ApproximateCostOfWorkInput.name}
							label={Q25ApproximateCostOfWorkInput.label}
							value={Q25ApproximateCostOfWorkInput.value}
							onChange={onSetQ25ApproximateCostOfWorkInput}
							hasError={errors.includes(Q25ApproximateCostOfWorkInput.name)}
							required={Q25ApproximateCostOfWorkInput.required}
							ref={
								Q25ApproximateCostOfWorkInput.ref as RefObject<HTMLInputElement>
							}
							errors={errors}
							setError={onFixError}
							displayCurrency
						/>

						{isNewConstruction(answers?.whatToDo) && (
							// <TextInput
							// 	id={Q26TotalProjectValueInput.name}
							// 	label={Q26TotalProjectValueInput.label}
							// 	value={Q26TotalProjectValueInput.value}
							// 	onChange={onSetQ26TotalProjectValueInput}
							// 	hasError={errors!.includes(Q26TotalProjectValueInput.name)}
							// 	required={Q26TotalProjectValueInput.required}
							// 	ref={
							// 		Q26TotalProjectValueInput.ref as RefObject<HTMLInputElement>
							// 	}
							// 	maxCharacters={50}
							// 	type="number"
							// 	isMoneyInput
							// />
							<MaskedThousandsSeparatorInput
								id={Q26TotalProjectValueInput.name}
								label={Q26TotalProjectValueInput.label}
								value={Q26TotalProjectValueInput.value}
								onChange={onSetQ26TotalProjectValueInput}
								hasError={errors.includes(Q26TotalProjectValueInput.name)}
								required={Q26TotalProjectValueInput.required}
								ref={
									Q26TotalProjectValueInput.ref as RefObject<HTMLInputElement>
								}
								errors={errors}
								setError={onFixError}
								displayCurrency
							/>
						)}

						<RadioInput
							name={Q27IsTreeCuttingRequiredInput.name}
							label={Q27IsTreeCuttingRequiredInput.label}
							value={Q27IsTreeCuttingRequiredInput.value}
							list={radioListYesNo}
							direction={Direction.vertical}
							hasError={errors!.includes(Q27IsTreeCuttingRequiredInput.name)}
							required={Q27IsTreeCuttingRequiredInput.required}
							ref={
								Q27IsTreeCuttingRequiredInput.ref as RefObject<HTMLInputElement>
							}
							hasBackgroundWrapper={true}
							onChange={onSetQ27IsTreeCuttingRequiredInput}
							description={Q27IsTreeCuttingRequiredInput.description}
						/>

						<TextAreaInput
							id={Q28AdditionalInfoInput.name}
							label={Q28AdditionalInfoInput.label}
							value={Q28AdditionalInfoInput.value}
							onChange={onSetQ28AdditionalInfoInput}
							hasError={errors!.includes(Q28AdditionalInfoInput.name)}
							required={Q28AdditionalInfoInput.required}
							ref={Q28AdditionalInfoInput.ref as RefObject<HTMLTextAreaElement>}
							maxCharacters={500}
							hasDescriptionLabel={Q28AdditionalInfoInput.description}
							displayDescriptionTop
							descriptionAlignment="left"
							rows={4}
						/>

						{/* Dates des travaux */}
						<h5 className={pageUtils.classes.h5}>
							{
								pageAssets?.request_form_buildingConstructionOrAdditionPermit_work_dates
							}
						</h5>

						<div className={pageUtils.classes.fieldsContainer}>
							<DateFilterInput
								id={Q29StartDateInput.name}
								label={Q29StartDateInput.label}
								value={Q29StartDateInput.value}
								onChange={onSetQ29StartDateInput}
								hasError={errors!.includes(Q29StartDateInput.name)}
								required={Q29StartDateInput.required}
								dateFormat="dd/MM/yyyy"
								minDate={new Date()}
							/>

							<DateFilterInput
								id={Q30EndDateInput.name}
								label={Q30EndDateInput.label}
								value={Q30EndDateInput.value}
								onChange={onSetQ30EndDateInput}
								hasError={errors!.includes(Q30EndDateInput.name)}
								required={Q30EndDateInput.required}
								dateFormat="dd/MM/yyyy"
								minDate={new Date(Q29StartDateInput.value + 'T12:00:00')}
								disabled={Q29StartDateInput.value == ''}
							/>
						</div>

						{/* Entrepreneur exécutant les travaux de construction. */}
						<h5 className={pageUtils.classes.h5}>
							{
								pageAssets?.request_form_buildingConstructionOrAdditionPermit_entrepreneur_executing_work
							}
						</h5>

						<RadioInput
							name={Q31WhoWillBeInChargeOfWorkInput.name}
							label={Q31WhoWillBeInChargeOfWorkInput.label}
							value={Q31WhoWillBeInChargeOfWorkInput.value}
							list={
								requestAsInput.value ==
								radioListWhoWillBeInChargeOfWork[0].value
									? radioListWhoWillBeInChargeOfWork
									: radioListWhoWillBeInChargeOfWork.slice(1)
							}
							direction={Direction.vertical}
							hasError={errors!.includes(Q31WhoWillBeInChargeOfWorkInput.name)}
							required={Q31WhoWillBeInChargeOfWorkInput.required}
							ref={
								Q31WhoWillBeInChargeOfWorkInput.ref as RefObject<HTMLInputElement>
							}
							hasBackgroundWrapper={true}
							onChange={onSetQ31WhoWillBeInChargeOfWorkInput}
						/>

						{/* <br /> */}
						{[
							radioListWhoWillBeInChargeOfWork[1].value,
							radioListWhoWillBeInChargeOfWork[2].value
						].includes(Q31WhoWillBeInChargeOfWorkInput.value) && (
							<div className={classes.checkbox2Container}>
								<span
									className={classes.checkboxWrapper}
									style={{ marginTop: '10px' }}
								>
									<input
										onClick={(e: any) =>
											onSetQ31_1EntrepreneurUnknownInput(e.target.checked)
										}
										value={Q31_1EntrepreneurUnknownInput.value?.toString()}
										className={classes.checkbox2}
										checked={
											Q31_1EntrepreneurUnknownInput.value.toString() == 'true'
										}
										type="checkbox"
										name={Q31_1EntrepreneurUnknownInput.name}
										id={Q31_1EntrepreneurUnknownInput.labelKey}
									/>
									<label
										className={classes.checkbox2Label}
										htmlFor={Q31_1EntrepreneurUnknownInput.labelKey}
									>
										{Q31_1EntrepreneurUnknownInput.label}
									</label>
								</span>
							</div>
						)}

						<TextInput
							id={Q32CompanyNameExecutingWorkInput.name}
							label={Q32CompanyNameExecutingWorkInput.label}
							value={Q32CompanyNameExecutingWorkInput.value}
							onChange={onSetQ32CompanyNameExecutingWorkInput}
							hasError={errors!.includes(Q32CompanyNameExecutingWorkInput.name)}
							required={Q32CompanyNameExecutingWorkInput.required}
							ref={
								Q32CompanyNameExecutingWorkInput.ref as RefObject<HTMLInputElement>
							}
							maxCharacters={50}
						/>
						<div className={pageUtils.classes.fieldsContainer}>
							<TextInput
								id={Q33CompanyNEQNumberInput.name}
								label={Q33CompanyNEQNumberInput.label}
								value={Q33CompanyNEQNumberInput.value}
								onChange={onSetQ33CompanyNEQNumberInput}
								hasError={errors!.includes(Q33CompanyNEQNumberInput.name)}
								required={Q33CompanyNEQNumberInput.required}
								ref={
									Q33CompanyNEQNumberInput.ref as RefObject<HTMLInputElement>
								}
								maxCharacters={50}
							/>

							<TextInput
								id={Q34CompanyRBQNumberInput.name}
								label={Q34CompanyRBQNumberInput.label}
								value={Q34CompanyRBQNumberInput.value}
								onChange={onSetQ34CompanyRBQNumberInput}
								hasError={errors!.includes(Q34CompanyRBQNumberInput.name)}
								required={Q34CompanyRBQNumberInput.required}
								ref={
									Q34CompanyRBQNumberInput.ref as RefObject<HTMLInputElement>
								}
								maxCharacters={50}
							/>
						</div>

						{/* Représentant(e) ou personne contact */}
						<h6 className={pageUtils.classes.h6} style={{ marginTop: '20px' }}>
							{
								pageAssets?.request_form_buildingConstructionOrAdditionPermit_representative_or_contact_person
							}
						</h6>

						<TextInput
							id={Q35CompanyRepresentativeNameInput.name}
							label={Q35CompanyRepresentativeNameInput.label}
							value={Q35CompanyRepresentativeNameInput.value}
							onChange={onSetQ35CompanyRepresentativeNameInput}
							hasError={errors!.includes(
								Q35CompanyRepresentativeNameInput.name
							)}
							required={Q35CompanyRepresentativeNameInput.required}
							ref={
								Q35CompanyRepresentativeNameInput.ref as RefObject<HTMLInputElement>
							}
							maxCharacters={100}
						/>

						<div className={pageUtils.classes.fieldsContainer}>
							<MaskedTextInput
								id={Q36CompanyRepresentativePhoneNumberInput.name}
								label={Q36CompanyRepresentativePhoneNumberInput.label}
								value={Q36CompanyRepresentativePhoneNumberInput.value}
								onChange={onSetQ36CompanyRepresentativePhoneNumberInput}
								hasError={errors?.includes(
									Q36CompanyRepresentativePhoneNumberInput.name
								)}
								type="tel"
								min={10}
								max={14}
								mask={formUtils.MASKS.phoneNumber}
								placeholder={formUtils.MASKS.phoneNumber}
								errors={errors ? errors : []}
								setError={onFixError}
								schema={PHONE_NUMBER_REGEX}
								required={Q36CompanyRepresentativePhoneNumberInput.required}
								ref={
									Q36CompanyRepresentativePhoneNumberInput.ref as RefObject<HTMLInputElement>
								}
							/>

							<TextInput
								id={Q37CompanyRepresentativeEmailInput.name}
								label={Q37CompanyRepresentativeEmailInput.label}
								value={Q37CompanyRepresentativeEmailInput.value}
								onChange={onSetQ37CompanyRepresentativeEmailInput}
								hasError={errors!.includes(
									Q37CompanyRepresentativeEmailInput.name
								)}
								required={Q37CompanyRepresentativeEmailInput.required}
								ref={
									Q37CompanyRepresentativeEmailInput.ref as RefObject<HTMLInputElement>
								}
								maxCharacters={100}
								type="email"
								schema={formUtils.MASKS.email}
								schemaErrorText={pageAssets.label_emailErrorText}
								onSetError={onFixError}
							/>
						</div>

						{/* Adresse de l'entreprise */}
						<h6 className={pageUtils.classes.h6} style={{ marginTop: '20px' }}>
							{
								pageAssets?.request_form_buildingConstructionOrAdditionPermit_company_address
							}
						</h6>

						<TextInput
							id={Q38CompanyAddressInput.name}
							label={Q38CompanyAddressInput.label}
							value={Q38CompanyAddressInput.value}
							onChange={onSetQ38CompanyAddressInput}
							hasError={errors!.includes(Q38CompanyAddressInput.name)}
							required={Q38CompanyAddressInput.required}
							ref={Q38CompanyAddressInput.ref as RefObject<HTMLInputElement>}
							maxCharacters={50}
						/>

						<div className={pageUtils.classes.fieldsContainer}>
							<TextInput
								id={Q39CompanyCityInput.name}
								label={Q39CompanyCityInput.label}
								value={Q39CompanyCityInput.value}
								onChange={onSetQ39CompanyCityInput}
								hasError={errors!.includes(Q39CompanyCityInput.name)}
								required={Q39CompanyCityInput.required}
								ref={Q39CompanyCityInput.ref as RefObject<HTMLInputElement>}
								maxCharacters={50}
							/>

							<MaskedTextInput
								id={Q40CompanyPostalCodeInput.name}
								label={Q40CompanyPostalCodeInput.label}
								value={Q40CompanyPostalCodeInput.value}
								onChange={onSetQ40CompanyPostalCodeInput}
								hasError={errors!.includes(Q40CompanyPostalCodeInput.name)}
								required={Q40CompanyPostalCodeInput.required}
								ref={
									Q40CompanyPostalCodeInput.ref as RefObject<HTMLInputElement>
								}
								max={7}
								min={7}
								mask={formUtils.MASKS.postalCode}
								placeholder="A1A 1A1"
								errors={errors}
								setError={onFixError}
								schema={POSTAL_CODE_REGEX}
							/>
						</div>
					</section>
				</ReactCollapse>
			)}

			{subSteps?.position === REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_6 && (
				<ReactCollapse
					isOpened={
						subSteps !== undefined &&
						subSteps.position === REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_6
					}
				>
					<section className={pageUtils.classes.sectionNoPadding}>
						<h4 className={pageUtils.classes.h4}>
							{/* Travaux de branchement d'aqueduc et d'égouts */}
							{pageAssets?.request_form_branching_aqueduc_egouts}
						</h4>

						<RadioInput
							name={Q41IsWaterAndSewerConnectionRequiredInput.name}
							label={Q41IsWaterAndSewerConnectionRequiredInput.label}
							value={Q41IsWaterAndSewerConnectionRequiredInput.value}
							list={radioListYesNo}
							direction={Direction.vertical}
							hasError={errors.includes(
								Q41IsWaterAndSewerConnectionRequiredInput.name
							)}
							required={Q41IsWaterAndSewerConnectionRequiredInput.required}
							ref={
								Q41IsWaterAndSewerConnectionRequiredInput.ref as RefObject<HTMLInputElement>
							}
							hasBackgroundWrapper={true}
							onChange={onSetQ41IsWaterAndSewerConnectionRequiredInput}
						/>

						{Q41IsWaterAndSewerConnectionRequiredInput?.value?.toString() ==
							radioListYesNo[0].value && (
							<>
								<CheckboxInput
									name={Q41IsPropertyConnectedToMunicipalConduitsInput.name}
									label={Q41IsPropertyConnectedToMunicipalConduitsInput.label}
									description={
										Q41IsPropertyConnectedToMunicipalConduitsInput.description
									}
									value={`${Q41IsPropertyConnectedToMunicipalConduitsInput.value}`}
									list={radioListIsPropertyConnectedToMunicipalConduits}
									onChange={onSetQ41IsPropertyConnectedToMunicipalConduitsInput}
									required={
										Q41IsPropertyConnectedToMunicipalConduitsInput.required
									}
									hasError={errors.includes(
										Q41IsPropertyConnectedToMunicipalConduitsInput.name
									)}
									ref={
										Q41IsPropertyConnectedToMunicipalConduitsInput.ref as RefObject<HTMLInputElement>
									}
									errors={errors}
									shouldNotDivide
									hasBackgroundWrapper
									onFixError={onFixError}
									newCheckValue
									disabledValues={disabledMunicipalConduits}
								/>

								<TextAreaInput
									id={Q42AdditionalInfoInput.name}
									label={Q42AdditionalInfoInput.label}
									value={Q42AdditionalInfoInput.value}
									onChange={onSetQ42AdditionalInfoInput}
									hasError={errors.includes(Q42AdditionalInfoInput.name)}
									required={Q42AdditionalInfoInput.required}
									ref={
										Q42AdditionalInfoInput.ref as RefObject<HTMLTextAreaElement>
									}
									maxCharacters={500}
									rows={4}
								/>

								<CheckboxInput
									name={Q43PlannedWorkInput.name}
									label={Q43PlannedWorkInput.label}
									description={Q43PlannedWorkInput.description}
									value={`${Q43PlannedWorkInput.value}`}
									list={listPlannedWork}
									onChange={onSetQ43PlannedWorkInput}
									required={Q43PlannedWorkInput.required}
									hasError={errors.includes(Q43PlannedWorkInput.name)}
									ref={Q43PlannedWorkInput.ref as RefObject<HTMLInputElement>}
									errors={errors}
									shouldNotDivide
									hasBackgroundWrapper
									onFixError={onFixError}
									newCheckValue
								/>

								{Q43PlannedWorkInput.value.includes(
									listPlannedWork.slice(-1)[0].value
								) && (
									<TextAreaInput
										id={Q44AdditionalInfoInput.name}
										label={Q44AdditionalInfoInput.label}
										value={Q44AdditionalInfoInput.value}
										onChange={onSetQ44AdditionalInfoInput}
										hasError={errors.includes(Q44AdditionalInfoInput.name)}
										required={Q44AdditionalInfoInput.required}
										ref={
											Q44AdditionalInfoInput.ref as RefObject<HTMLTextAreaElement>
										}
										maxCharacters={500}
										rows={4}
									/>
								)}

								{isEntrepreneurFieldsRequired(Q43PlannedWorkInput.value) && (
									<>
										{/* Entrepreneur (branchement d'aqueduc et d'égout ou génie civil) */}
										<h5 className={pageUtils.classes.h5}>
											{
												pageAssets?.request_form_buildingConstructionOrAdditionPermit_entrepreneur_branchement_aqueduc_egouts_ou_ingenieur_civil
											}
										</h5>

										<br />
										<div className={classes.checkbox2Container}>
											<span
												className={classes.checkboxWrapper}
												style={{ marginTop: '10px' }}
											>
												<input
													onClick={(e: any) =>
														onSetQ45EntrepreneurUnknownInput(e.target.checked)
													}
													value={Q45EntrepreneurUnknownInput.value?.toString()}
													className={classes.checkbox2}
													checked={
														Q45EntrepreneurUnknownInput.value.toString() ==
														'true'
													}
													type="checkbox"
													name={Q45EntrepreneurUnknownInput.name}
													id={Q45EntrepreneurUnknownInput.labelKey}
												/>
												<label
													className={classes.checkbox2Label}
													htmlFor={Q45EntrepreneurUnknownInput.labelKey}
												>
													{Q45EntrepreneurUnknownInput.label}
												</label>
											</span>
										</div>

										<TextInput
											id={Q46EntrepreneurNameInput.name}
											label={Q46EntrepreneurNameInput.label}
											value={Q46EntrepreneurNameInput.value}
											onChange={onSetQ46EntrepreneurNameInput}
											hasError={errors.includes(Q46EntrepreneurNameInput.name)}
											required={Q46EntrepreneurNameInput.required}
											ref={
												Q46EntrepreneurNameInput.ref as RefObject<HTMLInputElement>
											}
											maxCharacters={50}
										/>

										<TextInput
											id={Q47EntrepreneurRBQNumberInput.name}
											label={Q47EntrepreneurRBQNumberInput.label}
											value={Q47EntrepreneurRBQNumberInput.value}
											onChange={onSetQ47EntrepreneurRBQNumberInput}
											hasError={errors.includes(
												Q47EntrepreneurRBQNumberInput.name
											)}
											required={Q47EntrepreneurRBQNumberInput.required}
											ref={
												Q47EntrepreneurRBQNumberInput.ref as RefObject<HTMLInputElement>
											}
											maxCharacters={50}
										/>

										{/* Représentant(e) ou personne contact. */}
										<h6
											className={pageUtils.classes.h6}
											style={{ marginTop: '20px' }}
										>
											{
												pageAssets?.request_form_buildingConstructionOrAdditionPermit_representative_or_contact_person
											}
										</h6>

										<TextInput
											id={Q48EntrepreneurRepresentativeNameInput.name}
											label={Q48EntrepreneurRepresentativeNameInput.label}
											value={Q48EntrepreneurRepresentativeNameInput.value}
											onChange={onSetQ48EntrepreneurRepresentativeNameInput}
											hasError={errors.includes(
												Q48EntrepreneurRepresentativeNameInput.name
											)}
											required={Q48EntrepreneurRepresentativeNameInput.required}
											ref={
												Q48EntrepreneurRepresentativeNameInput.ref as RefObject<HTMLInputElement>
											}
											maxCharacters={50}
										/>

										<div className={pageUtils.classes.fieldsContainer}>
											<MaskedTextInput
												id={Q49EntrepreneurRepresentativePhoneNumberInput.name}
												label={
													Q49EntrepreneurRepresentativePhoneNumberInput.label
												}
												value={
													Q49EntrepreneurRepresentativePhoneNumberInput.value
												}
												onChange={
													onSetQ49EntrepreneurRepresentativePhoneNumberInput
												}
												hasError={errors?.includes(
													Q49EntrepreneurRepresentativePhoneNumberInput.name
												)}
												type="tel"
												min={10}
												max={14}
												mask={formUtils.MASKS.phoneNumber}
												placeholder={formUtils.MASKS.phoneNumber}
												errors={errors ? errors : []}
												setError={onFixError}
												schema={PHONE_NUMBER_REGEX}
												required={
													Q49EntrepreneurRepresentativePhoneNumberInput.required
												}
												ref={
													Q49EntrepreneurRepresentativePhoneNumberInput.ref as RefObject<HTMLInputElement>
												}
											/>

											<TextInput
												id={Q50EntrepreneurRepresentativeEmailInput.name}
												label={Q50EntrepreneurRepresentativeEmailInput.label}
												value={Q50EntrepreneurRepresentativeEmailInput.value}
												onChange={onSetQ50EntrepreneurRepresentativeEmailInput}
												hasError={errors.includes(
													Q50EntrepreneurRepresentativeEmailInput.name
												)}
												required={
													Q50EntrepreneurRepresentativeEmailInput.required
												}
												ref={
													Q50EntrepreneurRepresentativeEmailInput.ref as RefObject<HTMLInputElement>
												}
												schema={formUtils.MASKS.email}
												schemaErrorText={pageAssets.label_emailErrorText}
												maxCharacters={50}
												type="email"
												onSetError={onFixError}
											/>
										</div>

										{/* Adresse de l'entreprise */}
										<h6
											className={pageUtils.classes.h6}
											style={{ marginTop: '20px' }}
										>
											{
												pageAssets?.request_form_buildingConstructionOrAdditionPermit_company_address
											}
										</h6>

										<TextInput
											id={Q51EntrepreneurRepresentativeAddressInput.name}
											label={Q51EntrepreneurRepresentativeAddressInput.label}
											value={Q51EntrepreneurRepresentativeAddressInput.value}
											onChange={onSetQ51EntrepreneurRepresentativeAddressInput}
											hasError={errors.includes(
												Q51EntrepreneurRepresentativeAddressInput.name
											)}
											required={
												Q51EntrepreneurRepresentativeAddressInput.required
											}
											ref={
												Q51EntrepreneurRepresentativeAddressInput.ref as RefObject<HTMLInputElement>
											}
											maxCharacters={50}
										/>

										<div className={pageUtils.classes.fieldsContainer}>
											<TextInput
												id={Q52EntrepreneurRepresentativeCityInput.name}
												label={Q52EntrepreneurRepresentativeCityInput.label}
												value={Q52EntrepreneurRepresentativeCityInput.value}
												onChange={onSetQ52EntrepreneurRepresentativeCityInput}
												hasError={errors.includes(
													Q52EntrepreneurRepresentativeCityInput.name
												)}
												required={
													Q52EntrepreneurRepresentativeCityInput.required
												}
												ref={
													Q52EntrepreneurRepresentativeCityInput.ref as RefObject<HTMLInputElement>
												}
												maxCharacters={50}
											/>

											<MaskedTextInput
												id={Q53EntrepreneurRepresentativePostalCodeInput.name}
												label={
													Q53EntrepreneurRepresentativePostalCodeInput.label
												}
												value={
													Q53EntrepreneurRepresentativePostalCodeInput.value
												}
												onChange={
													onSetQ53EntrepreneurRepresentativePostalCodeInput
												}
												hasError={errors.includes(
													Q53EntrepreneurRepresentativePostalCodeInput.name
												)}
												required={
													Q53EntrepreneurRepresentativePostalCodeInput.required
												}
												ref={
													Q53EntrepreneurRepresentativePostalCodeInput.ref as RefObject<HTMLInputElement>
												}
												max={7}
												min={7}
												mask={formUtils.MASKS.postalCode}
												placeholder="A1A 1A1"
												errors={errors}
												setError={onFixError}
												schema={POSTAL_CODE_REGEX}
											/>
										</div>
									</>
								)}
							</>
						)}
					</section>
				</ReactCollapse>
			)}

			{subSteps?.position === REQUEST_TN_DETAILS_STEPS_NAME.DOCUMENTS && (
				<ReactCollapse
					isOpened={
						subSteps !== undefined &&
						subSteps.position === REQUEST_TN_DETAILS_STEPS_NAME.DOCUMENTS
					}
				>
					<section className={pageUtils.classes.sectionNoPadding}>
						<h4 className={pageUtils.classes.h4}>
							{pageAssets?.request_form_required_documents}
						</h4>

						<span className={pageUtils.classes.fileInfo}>
							{pageAssets?.page_requestInfo_inputFileDesc}
						</span>

						{whatToDoInput?.value ==
							REQUEST_CONFIGS_KEYS.whatToDoBuildingConstructionOrAdditionPermit
								.request_new_construction && (
							<>
								{renderAllPNRequestFiles('required', false)}
								{renderAllPNRequestFiles('optional', false)}
							</>
						)}

						{whatToDoInput?.value ==
							REQUEST_CONFIGS_KEYS.whatToDoBuildingConstructionOrAdditionPermit
								.request_addition && (
							<>
								{renderAllPARequestFiles('required', false)}
								{renderAllPARequestFiles('optional', false)}
							</>
						)}
					</section>
				</ReactCollapse>
			)}
		</>
	)
}

export default BuildingConstructionOrAdditionPermitForm
